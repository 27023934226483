import React from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';

import './AgentModal.css';

class AgentModal extends React.Component {
    constructor(props) {
        super(props);
        let currIds = (props.schedules ? props.schedules.map(x => x.id) : []);
        this.state = {
            key: 0,
            filter: "",
            name: props.name ? props.name : "",
            email: props.email ? props.email : "",
            phoneNo: props.phoneNo ? props.phoneNo : "",
            mobileNo: props.mobileNo ? props.mobileNo : "",
            agentScheduleIds: currIds,
            allSchedules: []
        };
        this.selectedIds = currIds;
    }

    componentDidMount() {
        this.loadSchedules();
    }

    render() {
        const columns = [
            { name: "Name", selector: row => row.name, sortable: true },
        ];

        const selectCriteria = row => this.state.agentScheduleIds.includes(row.id);

        const schedules = this.state.allSchedules
            .filter(x => this.selectedIds.includes(x.id) || x.name.toLowerCase().includes(this.state.filter))
            .sort((a, b) => this.selectedIds.includes(a.id) ? -1 : 0);

        const allButtonText = (this.state.agentScheduleIds.length > 0 ? "Clear All" : "Select All");
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>Agent Details</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.onSubmit}>
                    <Modal.Body>
                        <Form.Group controlId="agent-modal-name" as={Row} className="form-grp">
                            <Form.Label as={Col} sm={3}>Name:</Form.Label>
                            <Col sm={9}>
                                <Form.Control required type='text' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId="agent-modal-email" as={Row} className="form-grp">
                            <Form.Label as={Col} sm={3}>Email:</Form.Label>
                            <Col>
                                <Form.Control type='text' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId="agent-modal-phone-no" as={Row} className="form-grp">
                            <Form.Label as={Col} sm={3}>Phone No:</Form.Label>
                            <Col>
                                <Form.Control type='phone' value={this.state.phoneNo} onChange={(e) => this.setState({ phoneNo: e.target.value })} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId="agent-modal-mobile-no" as={Row} className="form-grp">
                            <Form.Label as={Col} sm={3}>Mobile No:</Form.Label>
                            <Col>
                                <Form.Control required type='phone' value={this.state.mobileNo} onChange={(e) => this.setState({ mobileNo: e.target.value })} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId="agent-modal-schedules" className="form-grp">
                            <Form.Label as={Row} sm={3}>
                                <Col sm={3}>Schedules:</Col>
                                <Col sm={4}>
                                    <Form.Control type="text" placeholder="Filter..." onChange={(e) => this.setState({ filter: e.target.value.toLowerCase() })} />
                                </Col>
                                <Col sm={4}>
                                    <Button size="sm" onClick={this.onAllButtonClick}>{allButtonText}</Button>
                                </Col>
                            </Form.Label>
                            <DataTable
                                key={this.state.key}
                                className='schedule-table'
                                columns={columns}
                                data={schedules}
                                striped
                                dense
                                selectableRows
                                noTableHead
                                selectableRowsHighlight
                                pagination
                                onSelectedRowsChange={this.onSelectedSchedulesChange}
                                selectableRowSelected={selectCriteria}
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button id='add-schedule-submit-btn' type='submit'>Save</Button>
                        <Button onClick={this.onCancel} className="btn-dark">Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    loadSchedules() {
        fetch("/admin/schedule", {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                res.json().then((json) => {
                    this.setState({ allSchedules: json });
                });
            } else {
                console.error("ERROR-TEXT: " + res.statusText);
            }
        }).catch(err => {
            console.error(err.message + ": loading schedules.");
        });
    }

    onSelectedSchedulesChange = (state) => {
        let objIds = state.selectedRows.map(x => x.id);
        objIds.sort((a, b) => a - b);
        this.selectedIds = objIds;
    }

    onAllButtonClick = (e) => {
        console.log("selectedIds: " + this.selectedIds.length);
        if (this.selectedIds.length === 0) {
            this.selectedIds = this.state.allSchedules.map(x => x.id);
        } else {
            this.selectedIds = [];
        }
        console.log("new selectedIds: " + this.selectedIds.length);
        this.setState({ agentScheduleIds: this.selectedIds.map(x => x) });
    }

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.checkValidity() === false) {
            return;
        }
        let schedules = this.selectedIds.map(x => {
            return { id: x };
        });
        if (this.props.onSubmit) {
            this.props.onSubmit({
                name: this.state.name,
                email: this.state.email,
                phoneNo: this.state.phoneNo,
                mobileNo: this.state.mobileNo,
                schedules: schedules
            });
        }
    }

    onCancel = (e) => {
        if (this.props.onCancel) {
            this.props.onCancel(e);
        }
    }

}

export default AgentModal;
