import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './SeatNoChangeModal.css';



class SeatNoChangeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seatNo: this.props.seatNo
        };
    }

    render() {
        return (
            <Modal show={this.props.show} className='seatno-change-modal' >
                <Modal.Header>
                    <Modal.Title>Change Seat Number</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.onSubmit}>
                    <Modal.Body>
                        <Form.Group as={Row} controlId='seatno-change-number'>
                            <Form.Label as={Col} sm={6}>Seat Number:</Form.Label>
                            <Col sm={6}>
                                <Form.Control
                                    type="number"
                                    requred="true"
                                    value={(this.state.seatNo === null) ? "" : this.state.seatNo}
                                    onChange={(e) => this.setState({ seatNo: e.target.value })} />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='success' onClick={(e) => this.props.onSeatNoChange(this.state.seatNo)}>Update</Button>
                        <Button variant='dark' onClick={this.props.onCancel}>Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default SeatNoChangeModal;