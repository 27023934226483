import React from 'react';
import ReservationsView from './components/ReservationsView';
import Schedules from './components/Schedules';

import './Reservation.css';

const STAGES = {
    OwnerSchedule: 0,
    Reservations: 1
};


class Reservation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: STAGES.OwnerSchedule,
            travelDate: new Date(),
            busNoFilter: "",
            schedules: [],      // Schedules that pertain to this user
            schedule: null      // A specific Schedule the user has selected (if any)
        };
    }

    componentDidMount() {
        this.loadSchedules();
    }

    render() {
        switch (this.state.stage) {
            default:
                var schedules;
                if(this.state.schedules) {
                    schedules = this.state.schedules.filter(x => x.bus.regNo.includes(this.state.busNoFilter));
                } else {
                    schedules = [];
                }
                return (
                    <Schedules
                        busNoFilter={this.state.busNoFilter}
                        onBusNoFilterChange={(e) => this.setState({ busNoFilter: e.target.value })}
                        travelDate={this.state.travelDate}
                        onTravelDateChange={(e) => this.setState({ travelDate: e }, this.loadSchedules)}
                        schedules={schedules}
                        onScheduleClick={(id) => this.onScheduleClick(id)}
                    />
                );

            case STAGES.Reservations:
                return (
                    <ReservationsView
                        name={this.state.schedule.name}
                        schedule={this.state.schedule}
                        travelDate={this.state.travelDate}
                        onClick={(e) => this.setState({stage: STAGES.OwnerSchedule})}
                    />
                );
        }
    }

    loadSchedules() {
        let dateStr = this.state.travelDate.getFullYear() + "-" + (this.state.travelDate.getMonth() + 1) + "-" + this.state.travelDate.getDate();
        var responseClone;
        fetch("/reservations/schedule?travelDate=" + dateStr, {
            headers: { 'Content-Type': 'application/json' },
        }).then(response => {
            responseClone = response.clone();
            if (response.ok) {
                return response.json();
            } else {
                console.error("response.ok is FALSE.");
                responseClone.text().then(function (bodyText) {
                });
            }
        }).then(json => {
            this.setState({
                schedules: json
            });
        }, rejectionReason => {
            responseClone.text().then(bodyText => {
                window.document.write(bodyText);
            });
        }).catch(err => {
            console.error(err);
            alert("Data Load ERROR 2L:" + err);
        });
    }

    onScheduleClick = (id) => {
        let results = this.state.schedules.filter(x => x.id === id);
        if (results.length > 0) {
            this.setState({
                schedule: results[0],
                stage: STAGES.Reservations
            });
        } else {
            console.err("Schedule ID " + id + " was selected by user.  But it is not in the list.");
        }
    }

}

export default Reservation;