import React from 'react';
import './Seating.css';

export const SEATTYPE = {
    space: "space",
    door: "door",
    driver: "driver",
    seatuna: "seatuna",
    seatava: "seatava",
    seatocc: "seatocc",
    seatsel: "seatsel"
};

// var layout = [
//     [{"type":"door","content":null},{"type":"space","content":null},{"type":"space","content":null},{"type":"space","content":null},{"type":"driver","content":null}],
//     [{"type":"seatava","content":"1"},{"type":"seatava","content":"2"},{"type":"space","content":null},{"type":"space","content":null},{"type":"space","content":null}],
//     [{"type":"seatava","content":"3"},{"type":"seatava","content":"4"},{"type":"space","content":null},{"type":"seatava","content":"5"},{"type":"seatava","content":"6"}],
//     [{"type":"seatava","content":"7"},{"type":"seatava","content":"8"},{"type":"space","content":null},{"type":"seatava","content":"9"},{"type":"seatava","content":"10"}],
//     [{"type":"seatava","content":"11"},{"type":"seatava","content":"12"},{"type":"space","content":null},{"type":"seatava","content":"13"},{"type":"seatava","content":"14"}],
//     [{"type":"seatava","content":"15"},{"type":"seatava","content":"16"},{"type":"space","content":null},{"type":"seatava","content":"17"},{"type":"seatava","content":"18"}],
//     [{"type":"seatava","content":"19"},{"type":"seatava","content":"20"},{"type":"space","content":null},{"type":"seatava","content":"21"},{"type":"seatava","content":"22"}],
//     [{"type":"seatava","content":"23"},{"type":"seatava","content":"24"},{"type":"space","content":null},{"type":"seatava","content":"25"},{"type":"seatava","content":"26"}],
//     [{"type":"seatava","content":"27"},{"type":"seatava","content":"28"},{"type":"space","content":null},{"type":"seatava","content":"29"},{"type":"seatava","content":"30"}],
//     [{"type":"seatava","content":"31"},{"type":"seatava","content":"32"},{"type":"space","content":null},{"type":"seatava","content":"33"},{"type":"seatava","content":"34"}],
//     [{"type":"seatava","content":"35"},{"type":"seatava","content":"36"},{"type":"space","content":null},{"type":"seatava","content":"37"},{"type":"seatava","content":"38"}],
//     [{"type":"seatava","content":"39"},{"type":"seatava","content":"40"},{"type":"space","content":null},{"type":"seatava","content":"41"},{"type":"seatava","content":"42"}],
//     [{"type":"seatava","content":"43"},{"type":"seatava","content":"44"},{"type":"seatava","content":"45"},{"type":"seatava","content":"46"},{"type":"seatava","content":"47"}]
//    ];


class Legend extends React.Component {
    render() {
        return (
                <div className='seating-legend row'>
                    <div className='col-md-4 col-4'>
                        <img src="images/seatava.png" alt="Available" />
                        <div>Available</div>
                    </div>
                    <div className='col-md-4 col-4'>
                        <img src="images/seatsel.png" alt="Selected" />
                        <div>Selected</div>
                    </div>
                    <div className='col-md-4 col-4'>
                        <img src="images/seatuna.png" alt="Unavailable" />
                        <div>Unavailable</div>
                    </div>
                </div>
        );
    }
}



class Bus extends React.Component {
    render() {
        return (
            <div className='seating-bus'>
                <table>
                    <tbody>
                        {
                            this.props.layout.map((x, index) =>
                                <tr key={index}>
                                    {x.map(y =>
                                        <td key={y.id}>
                                            <div
                                                 id={y.id}
                                                className={"seat-block " + y.type}
                                                onClick={(e) => this.props.onSeatClick(y.id, y.content)}>
                                                {y.content}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}


/**
 * Properties:
 *  layout      - seat layout as a two diminsional array of {id, type, content} objects.
 *  onSeatClick - callback when a seat is clicked.
 */
export default class Seating extends React.Component {

    onSeatClick = (id, seat) => {
        if (this.props.onClick) {
            this.props.onClick(id);
        }
        if (seat && this.props.onSeatClick) {
            this.props.onSeatClick(seat);
        }
    }

    render() {
        return (
            <div>
                <div className='seating-component'>
                    {(this.props.nolegend ? <></> : <center><Legend /></center>)}
                    <Bus onSeatClick={this.onSeatClick} layout={this.props.layout} />
                </div>
            </div>
        );
    }
}

