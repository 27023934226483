import React from 'react';

import './AboutUs.css';

function AboutUs() {
    return (<div className='contact-us-container'>
        <h1>ABOUT US</h1>
        <p>
            <strong>EasyTravel.lk</strong> was developed with the idea of providing the public with efficient and accurate
            information about public transport in Sri Lanka. The solution is built and maintained by a team with practical 
            experience in the digital transport sphere.
        </p>
        <p>
            With the shift of people moving to the digital space for accurate information as well as to perform
            transactions, we identified a huge void in the availability of this information in the public transport space
            in the country. With the intention of filling this gap it is our pleasure to introduce www.easytravel.lk Sri
            Lanka’s digital transport platform.
        </p>
        <p>
            Presently, passengers face a lot of difficulty identifying accurate bus fares as these fares aren’t
            transparent which leads to many conflicts between them and the conductors. Therefore, we have
            introduced a bus fare feature through which passengers can easily log into the easytravel platform and
            get accurate bus fare information. We believe that this feature will not only benefit the passengers
            immensely but also help the conductors greatly as well.
        </p>
        <p>
            Another vital piece of information missing in public transport is the timetable of busses. Through
            easytravel passengers will be able to access the timetable of busses which will greatly convenience the
            passengers as they will be able to better plan their journey with this information. easytravel intends on
            providing the most accurate information and keep updating the timetables as often as required.
            We at easytravel went one step further and enhanced our platform to allow passengers to reserve their
            bus seats prior to arriving for their journey. With the highest number of private busses on board our
            platform we are able to provide our users a seamless journey from start to end.
        </p>
        <p>
            The team at easytravel with the support of its AI functions intends on further improving the user journey
            in order to provide a greater convenience to our valuable customers.
        </p>
    </div>);
}


export default AboutUs;