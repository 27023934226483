import './SeatLayouts.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import LayoutModal from './LayoutModal';
import Seating from '../../components/Seating';


/**
 * SeatLayout class helps the user manage Seat Layouts in the system.
 */
class SeatLayouts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            keyModal: 0,
            layouts: [],
            layout: null

        };
    }

    componentDidMount() {
        this.getLayouts();
    }

    getLayouts() {
        fetch("/admin/layout",
            { headers: { 'Content-Type': 'application/json' } }
        ).then((res) => res.json()
        ).then((json) => {
            let layouts = json;
            layouts.forEach(x => {
                x.layout = JSON.parse(x.layout);
            });
            this.setState({
                layouts: layouts
            });
        }).catch(err => {
            console.error(err.message + ": loading layouts.");
        });
    }

    onAddLayoutClicked = (e) => {
        this.setState({ layout: null, showModal: true, keyModal: this.state.keyModal + 1 });
    }

    onEditLayout = (id) => {
        let layout = this.state.layouts.filter(x => x.id === id);
        if (layout.length > 0) {
            this.setState({ layout: layout[0], showModal: true, keyModal: this.state.keyModal + 1 });
        }
    }

    onCloseAddLayout = (layout) => {
        this.setState({ showModal: false });
        this.getLayouts();
    }

    render() {
        return (
            <div className='admin-container'>
                <div className='container-title'>Layouts</div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Button id='add-layout-btn' style={{ float: "right" }} onClick={this.onAddLayoutClicked} > Add Layout </Button>
                    </div>
                    {this.state.layouts.map(x =>
                        <div className='col-md-4 layout-entry' key={x.id}>
                            <h3>{x.name}</h3>
                            <Seating
                                key={x.id}
                                layout={x.layout}
                                nolegend
                                onClick={(seat) => this.onEditLayout(x.id)}
                            />
                            {/* <Layout key={x.id} layout={x.layout} onSeatClick={(e, id) => this.onEditLayout(x.id)} /> */}
                        </div>)}
                </div>
                <LayoutModal key={this.state.keyModal} show={this.state.showModal} onClose={this.onCloseAddLayout} layout={this.state.layout} />
            </div>
        );
    }
}

export default SeatLayouts;