import React from 'react';
import './ContactUs.css';

function ContactUs() {
    return (
        <div className='contact-container'>
            <div className='contact'>
                <div className='row phone-no'>
                    <div className='col-md-5'>Call us:</div>
                    <div className='col-md-7 info'>
                        0777 577 577        <br />
                        0777 440 055
                    </div>
                </div>
                <div className='row email'>
                    <div className='col-md-5'>Write to us:</div>
                    <div className='col-md-7 info'>info@easytravel.lk</div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;