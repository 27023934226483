import './Legend.css'
import React from 'react';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { PencilSquare } from 'react-bootstrap-icons';
import { SEATTYPE } from '../../components/Seating';


class Legend extends React.Component {

    typeSelect = (e) => {
        if (this.props.onTypeSelect) {
            this.props.onTypeSelect(e.target.value);
        }
    }

    render() {
        return (
            <div className='row' style={{textAlign: "left"}}>
                <div className='col-md-12 legend-title'>Legend</div>

                <div className='col-md-3'><img src='images/driver.png' alt='driver' /></div>
                <div className='col-md-9'>
                    <input
                        id="driver_legend"
                        type="radio"
                        checked={this.props.selectedType === "driver"}
                        name="type"
                        value="driver"
                        onChange={this.typeSelect} />
                    &nbsp; <b>Driver</b>
                </div>

                <div className='col-md-3'><img src='images/seatava.png' alt='unavailable' /></div>
                <div className='col-md-9'>
                    <input
                        id="seat_legend"
                        type="radio"
                        checked={this.props.selectedType === SEATTYPE.seatava}
                        name="type"
                        value={SEATTYPE.seatava}
                        onChange={this.typeSelect} />
                    &nbsp; <b>Seat</b>
                </div>

                <div className='col-md-3'><img src='images/space.png' alt='space' /></div>
                <div className='col-md-9'>
                    <input
                        id="space_legend"
                        type="radio"
                        checked={this.props.selectedType === "space"}
                        name="type"
                        value="space"
                        onChange={this.typeSelect} />
                    &nbsp; <b>Space</b>
                </div>

                <div className='col-md-3'><PencilSquare size={35} /></div>
                <div className='col-md-9'>
                    <input
                        id="edit_legend"
                        type="radio"
                        checked={this.props.selectedType === "edit"}
                        name="type"
                        value="edit"
                        onChange={this.typeSelect} />
                    &nbsp; <b>Edit</b>
                </div>
            </div>
        );
    }
}


export default Legend;