import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DashCircleFill, PlusCircleFill } from 'react-bootstrap-icons';
import Seating from '../../components/Seating';
import { SEATTYPE } from '../../components/Seating';

import './ScheduleModal.css';

function toLocaleDate(x) {
    if (x === null || x === "") return null;
    return new Date(x.toString().split(" ").slice(0, 4).join(" ") + " 05:30:00 GMT+0530");
}

// TODO: Use AlertBox instead of JavaScript alert message box.


/**
 * 
 */
class ScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        let c = 0;
        if (props.schedule) {
            try {
                if (typeof props.schedule.bus.seatLayout.layout === "string") {
                    props.schedule.bus.seatLayout.layout = JSON.parse(props.schedule.bus.seatLayout.layout);
                }
            } catch (e) { }
        }
        this.state = {
            show: props.show,
            focusOn: undefined,
            buses: [],
            routes: [],
            layoutKey: 0,
            name: props.schedule ? props.schedule.name : "",
            route: props.schedule ? props.schedule.route : null,
            scheduleFares: props.schedule ? props.schedule.scheduleFares : [],
            direction: props.schedule ? props.schedule.direction : "",
            reservationSMS: props.schedule ? props.schedule.reservationSMS : false,
            fullFare: props.schedule ? props.schedule.fullFare : "",
            bus: props.schedule ? props.schedule.bus : null,
            avaseats: props.schedule ? props.schedule.seats.sort((a, b) => a - b) : [],
            startDate: props.schedule ? new Date(props.schedule.startDate) : null,
            endDate: props.schedule && props.schedule.endDate ? new Date(props.schedule.endDate) : null,
            runDays: props.schedule ? props.schedule.runDays : 0,
            skipDays: props.schedule ? props.schedule.skipDays : 0,
            runTimes: props.schedule ? props.schedule.times.map(x => { return { id: (c++), time: x.substring(0, 5) }; }) : [],
            fareTable: [],
            fareFrom: "",
            fareTo: "",
            fareValue: ""
        };
    }

    componentDidMount() {
        if (this.state.show) {
            fetch("/admin/bus", {
                headers: { 'Content-Type': 'application/json' }
            }).then((res) => res.json()).then((json) => {
                json = json.map(x => { x.seatLayout.layout = JSON.parse(x.seatLayout.layout); return x; });
                this.setState({ buses: json });
            });
            fetch("/admin/route", {
                headers: { 'Content-Type': 'application/json' }
            }).then((res) => res.json()).then((json) => {
                this.setState({ routes: json });
            });
            fetch("/admin/fare", {
                headers: { 'Content-Type': 'application/json' }
            }).then((res) => res.json()).then((json) => {
                this.setState({ fareTable: json });
            });
        }
    }

    render() {
        const sections = this.state.route ? this.state.route.sections.slice() : [];
        if (this.state.direction === "DOWN") {
            sections.reverse();
        }

        let layout
            = (this.state.bus) ?
                this.state.bus.seatLayout.layout.map(x => x.map(y => {
                    if (this.state.avaseats.includes(y.content)) {
                        y.type = SEATTYPE.seatuna;
                    } else if (y.type === SEATTYPE.seatava || y.type === SEATTYPE.seatuna) {
                        y.type = SEATTYPE.seatava;
                    }
                    return y;
                })) : [];
        return (
            <Modal show={this.state.show} key={this.state.key}>
                <Modal.Header>
                    <Modal.Title>ADD A SCHEDULE</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Form.Group controlId='schedule-modal-name' as={Row}>
                            <Form.Label column sm="2">Name:</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    id='schedule-name-input'
                                    type='text'
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    placeholder='Schedule name ...' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} style={{ marginTop: "20px" }} >
                            <Form.Label column sm="2">Route:</Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    id='schedule-route-select-id'
                                    className='schedule-route-select-div'
                                    clearButton
                                    placeholder='Select a route...'
                                    onChange={this.onRouteChange}
                                    selected={this.state.route ? [this.state.route] : []}
                                    options={this.state.routes}
                                    labelKey={r => { return r.code + " | " + r.nameEn + " (" + r.serviceType + ")"; }}
                                />
                            </Col>
                        </Form.Group>

                        {/* Schedule Specific Fares */}

                        <Form.Group controlId='schedule-modal-fares' as={Row} style={{ marginTop: "20px" }}>
                            <Form.Label>
                                Fares: <i className='note'>(NOTE: If fares are not given here NTC Fare Table will be used.)</i>
                            </Form.Label>
                            {
                                this.state.scheduleFares.map(x => {
                                    return (
                                        <Row key={x.id}>
                                            <Col sm="5">
                                                <Form.Control type='text' readOnly value={x.from.nameEn} />
                                            </Col>
                                            <Col sm="5">
                                                <Form.Control type='text' readOnly value={x.to.nameEn} />
                                            </Col>
                                            <Col sm='2'>
                                                <Form.Control type='text' readOnly value={x.fare} />
                                                <DashCircleFill className='op_btn' onClick={(e) => this.onRemoveFare(x.id)} />
                                            </Col>
                                        </Row>

                                    )
                                })
                            }

                            <Row>
                                <Col sm="5">
                                    <Typeahead
                                        id='new-from-section'
                                        clearButton
                                        autoFocus={this.state.focusOn === 'new-from-section'}
                                        placeholder='Select "form" section'
                                        options={sections}
                                        selected={this.state.fareFrom ? [this.state.fareFrom] : []}
                                        labelKey={r => r.section.nameEn}
                                        onChange={(sels) => {
                                            let newSec = (sels.length > 0) ? sels[0] : "";
                                            if (newSec !== "" && this.state.fareTo !== "") {
                                                let ntcOffset = Math.abs(newSec.ntcIndex - this.state.fareTo.ntcIndex);
                                                let fare = "";
                                                if (this.state.route.serviceType === "NORMAL") {
                                                    fare = this.state.fareTable[ntcOffset].normal;
                                                } else if (this.state.route.serviceType === "SEMI_LUXURY") {
                                                    fare = this.state.fareTable[ntcOffset].semiLuxury;
                                                } else if (this.state.route.serviceType === "LUXURY") {
                                                    fare = this.state.fareTable[ntcOffset].luxury;
                                                }
                                                this.setState({ fareFrom: newSec, fareValue: fare });
                                            } else {
                                                this.setState({ fareFrom: newSec, fareValue: "" });
                                            }
                                        }}
                                    />
                                </Col>
                                <Col sm="5">
                                    <Typeahead
                                        id='new-to-section'
                                        placeholder='Select "to" section'
                                        clearButton
                                        autoFocus={this.state.focusOn === 'new-to-section'}
                                        options={sections}
                                        selected={this.state.fareTo ? [this.state.fareTo] : []}
                                        labelKey={r => r.section.nameEn}
                                        onChange={(sels) => {
                                            let newSec = (sels.length > 0) ? sels[0] : "";
                                            if (newSec !== "" && this.state.fareFrom !== "") {
                                                let ntcOffset = Math.abs(newSec.ntcIndex - this.state.fareFrom.ntcIndex);
                                                let fare = "";
                                                if (this.state.route.serviceType === "NORMAL") {
                                                    fare = this.state.fareTable[ntcOffset].normal;
                                                } else if (this.state.route.serviceType === "SEMI_LUXURY") {
                                                    fare = this.state.fareTable[ntcOffset].semiLuxury;
                                                } else if (this.state.route.serviceType === "LUXURY") {
                                                    fare = this.state.fareTable[ntcOffset].luxury;
                                                }
                                                this.setState({ fareTo: newSec, fareValue: fare });
                                            } else {
                                                this.setState({ fareTo: newSec, fareValue: "" });
                                            }
                                        }}
                                    />
                                </Col>
                                <Col sm='2'>
                                    <Form.Control type='number' className='value-field' placeholder='Fare' value={this.state.fareValue} onChange={(e) => this.setState({ fareValue: e.target.value })} />
                                    <PlusCircleFill className="op_btn" onClick={(e) => this.onAddFare()} />
                                </Col>

                            </Row>
                        </Form.Group>

                        {/*  */}

                        <Row style={{ marginTop: "20px" }}>
                            <Form.Group controlId='schedule-modal-direction' as={Col} sm="6">
                                <Form.Label>Direction:</Form.Label>
                                <Row>
                                    <Col sm="3">
                                        <Form.Check
                                            id='schedule-direction-up-check'
                                            name='direction' type='radio'
                                            label='UP' checked={this.state.direction === 'UP'}
                                            onChange={(e) => this.setState({
                                                direction: "UP",
                                                scheduleFares: [],
                                                fareFrom: "",
                                                fareTo: "",
                                                fareValue: ""
                                            })}
                                        />
                                    </Col>
                                    <Col sm="3">
                                        <Form.Check
                                            id='schedule-direction-down-check'
                                            name='direction'
                                            type='radio'
                                            label='DOWN'
                                            checked={this.state.direction === 'DOWN'}
                                            onChange={(e) => this.setState({
                                                direction: "DOWN",
                                                scheduleFares: [],
                                                fareFrom: "",
                                                fareTo: "",
                                                fareValue: ""
                                            })}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId='schedule-modal-send-reservation-sms-flag' as={Col} sm="3">
                                <center>
                                    <Form.Label>Reservation SMS:</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        checked={this.state.reservationSMS}
                                        onChange={(e) => this.setState({reservationSMS : e.target.checked})}
                                    />
                                </center>
                            </Form.Group>
                            <Form.Group controlId='schedule-modal-full-fare' as={Col} sm="3">
                                <Form.Label>Full Fare:</Form.Label>
                                <Form.Control
                                    id='schedule-full-fare-input'
                                    type='text'
                                    value={this.state.fullFare}
                                    onChange={(e) => this.setState({ fullFare: e.target.value })} />
                            </Form.Group>
                        </Row>
                        <Form.Group controlId='schedule-modal-bus' as={Row} style={{ marginTop: "20px" }}>
                            <Form.Label column sm="2">Bus:</Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    id='schedule-bus-select'
                                    clearButton={true}
                                    placeholder='Select a bus...'
                                    selected={this.state.bus ? [this.state.bus] : []}
                                    onChange={this.onBusChange}
                                    options={this.state.buses}
                                    labelKey={b => { return b.regNo + " | " + b.name; }} />
                            </Col>

                        </Form.Group>

                        <Form.Group controlId='schedule-modal-layout' as={Row} style={{ marginTop: "20px" }}>
                            <Form.Label column sm="2">Layout:</Form.Label>
                            <Col sm="12">
                                <Seating
                                    id='schedule-available-seats-select'
                                    key={this.state.layoutKey}
                                    layout={layout}
                                    onSeatClick={this.onSeatClick} />
                            </Col>
                        </Form.Group>


                        <div className='row' style={{ marginTop: "20px" }}>
                            <Form.Group controlId='schedule-modal-start-date' className='col-md-4'>
                                <Form.Label>Start Date:</Form.Label>
                                <DatePicker
                                    id='schedule-start-date-picker'
                                    locale="en"
                                    className='form-control'
                                    dateFormat="yyyy/MM/dd"
                                    selected={this.state.startDate}
                                    onChange={(x) => this.setState({ startDate: toLocaleDate(x) })} />
                            </Form.Group>
                            <Form.Group controlId='schedule-modal-end-date' className='col-md-4'>
                                <Form.Label>End Date:</Form.Label>
                                <DatePicker
                                    id='schedule-end-date-picker'
                                    locale="en"
                                    className='form-control'
                                    dateFormat="yyyy/MM/dd"
                                    selected={this.state.endDate}
                                    onChange={(x) => this.setState({ endDate: toLocaleDate(x) })} />
                            </Form.Group>

                            <Form.Group controlId='schedule-modal-run-days' className='col-md-2'>
                                <Form.Label>Run_Days:</Form.Label>
                                <Form.Control
                                    id='schedule-run-days-picker'
                                    type='number'
                                    value={this.state.runDays}
                                    onChange={
                                        (e) => {
                                            var runTimes = [];
                                            for (var i = 0; i < e.target.value; i++) {
                                                if (i < this.state.runTimes.length) {
                                                    runTimes.push({ id: i, time: this.state.runTimes[i].time });
                                                } else {
                                                    runTimes.push({ id: i, time: "00:00" });
                                                }
                                            }
                                            this.setState({ runDays: e.target.value, runTimes: runTimes });
                                        }
                                    } />
                            </Form.Group>
                            <Form.Group controlId='schedule-modal-skip-days' className='col-md-2'>
                                <Form.Label>Skip_Days:</Form.Label>
                                <Form.Control
                                    id='schedule-skip-days-picker'
                                    type='number'
                                    value={this.state.skipDays}
                                    onChange={(e) => this.setState({ skipDays: e.target.value })} />
                            </Form.Group>
                        </div>

                        <div className="row">
                        </div>

                        <Form.Group controlId='schedule-modal-start-times' >
                            <Form.Label className='col-md-12'>Start Times:</Form.Label>
                            <div className='row'>
                                {
                                    this.state.runTimes.map(x =>
                                        <div className='col-md-3' style={{ margin: "4px 0px" }} key={x.id}>
                                            <div style={{ fontSize: "0.6em" }}>{x.id + 1}.</div>
                                            <Form.Control className='time-entry' label="X" type='text'
                                                id={"time_entry_" + x.id}
                                                key={x.id}
                                                value={x.time}
                                                onChange={(e) => {
                                                    var runTimes = this.state.runTimes;
                                                    runTimes[x.id].time = e.target.value;
                                                    this.setState({ runTimes: runTimes });
                                                }} />
                                        </div>)
                                } </div>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            id='add-schedule-submit-btn'
                            variant="success"
                            type="button"
                            onClick={this.onSubmit}>Submit</Button>&nbsp;
                        <Button
                            variant="dark"
                            type="button"
                            onClick={this.onCancel}>Cancel</Button>

                    </Modal.Footer>
                </Form>
            </Modal >
        );
    }

    onRouteChange = (selections) => {
        if (selections.length > 0) {
            this.setState({
                route: selections[0],
                fullFare: selections[0].fare,
                scheduleFares: [],
                fareFrom: "",
                fareTo: "",
                fareValue: ""
            });
        } else {
            this.setState({
                route: null,
                fullFare: ""
            });
        }
    }


    onBusChange = (selected_buses) => {
        if (selected_buses.length > 0) {
            if (typeof selected_buses[0].seatLayout.layout === "string") {
                selected_buses[0].seatLayout.layout = JSON.parse(selected_buses[0].seatLayout.layout);
            }
            this.setState({
                layoutKey: this.state.layoutKey + 1,
                bus: selected_buses[0],
                avaseats: []
            });
        } else {
            this.setState({
                bus: null
            });
        }
    }


    onSubmit = () => {
        if (!this.state.bus) {
            alert("A Bus has not been selected.");
            return;
        }
        if (!this.state.route) {
            alert("A Routes has not been selected.");
            return;
        }
        if (this.state.direction === "") {
            alert("A Direction has not been selected.");
            return;
        }
        if (this.state.startDate === null) {
            alert("A Start Date has not been provided.");
            return;
        }
        if (this.state.runDays === "" || this.state.runDays === "0") {
            alert("RunDays has not been provided.");
            return;
        }
        const startDate = this.state.startDate.toISOString().split("T")[0];
        const endDate = (this.state.endDate === null) ? null : this.state.endDate.toISOString().split("T")[0];
        var times = this.state.runTimes.map(x => x.time + ":00");
        let scheFares = this.state.scheduleFares.map(x => { return ({ from: { id: x.from.id }, to: { id: x.to.id }, fare: x.fare }) });
        const data = {
            id: this.props.schedule ? this.props.schedule.id : null,
            name: this.state.name,
            route: { id: this.state.route.id },
            scheduleFares: scheFares,
            direction: this.state.direction,
            reservationSMS: this.state.reservationSMS,
            fullFare: this.state.fullFare,
            bus: { id: this.state.bus.id },
            seats: this.state.avaseats,
            startDate: startDate,
            endDate: endDate,
            runDays: this.state.runDays,
            skipDays: (this.state.skipDays === "") ? 0 : this.state.skipDays,
            times: times
        };
        fetch("/admin/schedule", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then((res) => {
            if (res.ok) {
                if (this.props.onSuccess !== undefined) {
                    this.props.onSuccess();
                }
            } else {
                res.json().then(json => alert(json.message));
            }
        });
        this.setState({ show: false });
    }

    onCancel = () => {
        this.setState({ show: false });
    }

    /**
     * Every time a seat is clicked, we toggle the seat from available-seat to unavailable-seat
     * and vise versa.
     * @param {Seat that was clicked by the user.} seat 
     */
    onSeatClick = (seat) => {
        if (this.state.bus) {
            let avaseats = this.state.avaseats.splice(0);
            if (avaseats.includes(seat)) {
                const indexOf = avaseats.indexOf(seat);
                avaseats.splice(indexOf, 1);
            } else {
                avaseats.push(seat);
            }
            avaseats.sort((a, b) => a - b);
            this.setState({
                avaseats: avaseats,
                layoutKey: (this.state.layoutKey + 1)
            });
        }
    }

    onAddFare() {
        if (this.state.fareFrom === "" || this.state.fareTo === "" || this.state.fareValue === "") {
            alert("Incomplete!");
            return;
        }
        if (this.state.route) {
            if (this.state.direction === "UP") {
                if (this.state.route.sections.indexOf(this.state.fareFrom) >= this.state.route.sections.indexOf(this.state.fareTo)) {
                    alert("Direction Mismatch!");
                    return;
                }
            } else {
                if (this.state.route.sections.indexOf(this.state.fareFrom) <= this.state.route.sections.indexOf(this.state.fareTo)) {
                    alert("Direction Mismatch!");
                    return;
                }
            }
        }
        let fares = this.state.scheduleFares;
        let entry = { id: fares.length, from: this.state.fareFrom.section, to: this.state.fareTo.section, fare: this.state.fareValue };
        fares.push(entry);
        this.setState({ scheduleFares: fares, fareFrom: "", fareTo: "", fareValue: "" });
    }

    onRemoveFare(id) {
        let fares = this.state.scheduleFares.filter(x => x.id !== id);
        this.setState({ scheduleFares: fares });
    }


}


export default ScheduleModal;