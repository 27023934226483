import React from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';

import Seating, { SEATTYPE } from '../../components/Seating';


import './ReservationsView.css';

/**
 * name       -
 * schedule   - 
 * travelDate -  
 */
class ReservationsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 0,
            layout: this.props.schedule ? JSON.parse(this.props.schedule.bus.seatLayout.layout) : [],
            unavailable: [],        // all the seats that have already been reserved, booked etc.
            reservations: [],       // reservations on current Schedule & TravelDate
            historyMsg: null,
            reservation: null,      // currently selected reservation
            seats: [],              // currently selected seats
            mobile: "",
            nic: "",
            firstName: "",
            lastName: ""
        }
    }

    render() {
        let layout = this.state.layout.map(x => x.map(y => ({ ...y }))); // clone the 'layout' 

        layout.map(x => x.map(y => {
            if (this.state.unavailable.includes(y.content)) {
                y.type = SEATTYPE.seatuna;
            }
            return y;
        }));
        layout = layout.map(x => x.map(y => {
            if (this.state.seats.includes(y.content)) {
                y.type = SEATTYPE.seatsel;
            }
            return y;
        }));

        return (
            <div className='reservations-container'>
                <div>
                    <div><button className='btn btn-warning btn-back' onClick={this.props.onClick}><ArrowLeftCircleFill /> Back</button></div>
                    <div className='container-title'>{this.props.schedule.bus.regNo} &nbsp; {this.props.schedule.bus.name}</div>
                </div>
                <div className='row'>
                    {/* Seats Layout View */}
                    <div className='col-md-4 vpane'>
                        <Seating
                            key={this.state.key}
                            layout={layout}
                            onSeatClick={this.onSeatClick}
                        />
                    </div>

                    {/* Reservation Details Pane */}
                    <div className='col-md-4 vpane'>
                        <ReservationInfo
                            onSubmit={this.onSubmit}
                            seats={this.state.seats}
                            mobile={this.state.mobile}
                            onMobileChange={this.onMobileChange}
                            message={this.state.historyMsg}
                            nic={this.state.nic}
                            onNicChange={(e) => this.setState({ nic: e.target.value })}
                            firstName={this.state.firstName}
                            onFirstNameChange={(e) => this.setState({ firstName: e.target.value })}
                            lastName={this.state.lastName}
                            onLastNameChange={(e) => this.setState({ lastName: e.target.value })}
                            buttonText="SAVE"
                        />
                    </div>

                    {/* Reservations List */}
                    <div className='col-md-4 vpane'>
                        <ReservationList
                            key={this.state.key}
                            reservations={this.state.reservations}
                            reservation={this.state.reservation ? this.state.reservation.id : -1}
                            onClick={this.onReservationClick}
                        />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.reloadReservations();
        setTimeout(() => {
            this.refresh();
        }, 5000);
    }

    reloadReservations() {
        let dateStr = this.props.travelDate.getFullYear() + "-" + (this.props.travelDate.getMonth() + 1) + "-" + this.props.travelDate.getDate();
        fetch("/reservations/reservations?scheduleId=" + this.props.schedule.id + "&travelDate=" + dateStr, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Reservations Load error...");
            }
        }).then(reservations => {
            fetch("/booking/getunavailable?scheduleId=" + this.props.schedule.id + "&travelDate=" + dateStr, {
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error("Reservations Load error...");
                }
            }).then(unavailable => {
                this.setState({
                    unavailable: unavailable,
                    reservations: reservations
                });
            }).catch(err => {
                console.error("RESERVATIONS LOADING ERROR." + err);
            });

            this.setState({
                reservations: reservations
            });
        }).catch(err => {
            console.error("RESERVATIONS LOADING ERROR." + err);
        });
    }

    onSeatClick = (seat) => {
        if (this.state.unavailable.includes(seat)) {
            if (!this.state.reservation || !this.state.reservation.seats.includes(seat)) {
                return;
            }
        }
        // escape here if the type of reservation is not what is allowed to be changed.
        if (this.state.reservation && this.state.reservation.type !== 'OWNER' && this.state.reservation.type !== 'AGENT') {
            return;
        }
        let s = [...this.state.seats];
        if (s.includes(seat)) {
            s.splice(s.indexOf(seat), 1);
        } else {
            s.push(seat);
        }
        s = s.sort((a, b) => a - b);
        this.setState({
            key: this.state.key + 1,
            seats: s
        });
    }

    is_mobile_valid(mobile) {
        let conditioned = mobile.replace(/[^\d]/g, "");
        if (conditioned.length !== 10 || conditioned.substring(0, 2) !== "07")
            return null;
        const regex = /07\d{8}/g;
        if (!regex.test(conditioned)) {
            return null;
        }
        return conditioned;
    }

    get_mobile_history(mobile) {
        mobile = this.is_mobile_valid(mobile);
        if (mobile !== null) {
            fetch("/reservations/history", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: mobile
            }).then((res) => {
                if (res.ok) {
                    console.log("mobile_history OK");
                    res.json().then((json) => {
                        if(json.good > 0) {
                            this.setState({ historyMsg: json.good + "  previous reservations found." });
                        } else {
                            this.setState({ historyMsg: "No previous reservations." });
                        }
                    });
                } else {
                    console.log("mobile_history Error");
                    this.setState({ historyMsg: null });
                }
            }).catch(err => {
                console.error("Catch Error: " + err);
                this.setState({ historyMsg: null });
            });
        } else {
            this.setState({ historyMsg: null });
        }
    }

    onMobileChange = (e) => {
        let new_mobile = e.target.value;
        this.setState(
            { mobile: new_mobile },
            this.get_mobile_history(new_mobile)
        );
    }

    onReservationClick = (id) => {
        let res = (id === -1) ? [] : this.state.reservations.filter(x => x.id === id);
        if (res.length === 0 || res[0].type !== "OWNER") {
            this.setState({
                reservation: null,
                seats: [],
                mobile: "",
                nic: "",
                firstName: "",
                lastName: ""
            });
        }
        if (res.length > 0) {
            this.setState({
                reservation: res[0],
                seats: [...res[0].seats],
                mobile: res[0].mobile,
                nic: res[0].nic,
                firstName: res[0].firstName,
                lastName: res[0].lastName
            });
        }
    }

    onSubmit = (e) => {
        let mobile = this.is_mobile_valid(this.state.mobile);
        if (mobile === null) {
            alert("Mobile Number is invalid.");
            return;
        }
        if(this.state.seats.length === 0) {
            alert("Select one or more seats.");
            return;
        }
        let data = JSON.stringify({
            id: this.state.reservation ? this.state.reservation.id : -1,
            seats: this.state.seats,
            mobile: mobile,
            nic: this.state.nic,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            type: this.state.reservation ? this.state.reservation.type : "OWNER",
            schedule: { id: this.props.schedule.id },
            travelDate: this.props.travelDate
        });
        fetch("/reservations/reservation", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: data
        }).then((res) => {
            if (res.ok) {
                this.setState({
                    historyMsg: null,
                    reservation: null,
                    seats: [],
                    mobile: "",
                    nic: "",
                    firstName: "",
                    lastName: ""
                }, this.reloadReservations());
            } else {
                alert(res.headers.get("message"));
            }
        }).catch(err => {
            alert(err.message + ": saving reservation.");
        });
    }
}


export default ReservationsView;


function ReservationInfo(props) {
    return (
        <div className='info-form-card'>
            <Alert>Please mark <b>seat(s)</b> to be reserved in the <b>Seating Layout</b> and provide <b>contact details</b> below.<br /></Alert>

            <Form onSubmit={(e) => { props.onSubmit(); e.preventDefault(); }}>

                <Form.Group controlId='customerSeats' as={Row} className='selected-seat-list'>
                    <Col sm="3"><Form.Label>Seats:</Form.Label></Col>
                    <Col>{props.seats.map(x => <div key={x} className='seat'>{x}</div>)}</Col>
                </Form.Group>

                <Form.Group controlId='mobileNo'>
                    <Row>
                        <Col sm="3"><Form.Label>Mobile No:</Form.Label></Col>
                        <Col><Form.Control as='input' type='phone' value={props.mobile} required onChange={props.onMobileChange} placeholder="required - '07' plus 8 digits" /></Col>
                    </Row>
                    <Row>
                        <Col sm="3"></Col>
                        <Col><div className='history-message'>{props.message}</div></Col>
                    </Row>
                </Form.Group>

                <Form.Group controlId='customerNic' as={Row} >
                    <Col sm="3"><Form.Label>NIC/PP/DL:</Form.Label></Col>
                    <Col><Form.Control as='input' type='text' value={props.nic} onChange={props.onNicChange} /></Col>
                </Form.Group>

                <Form.Group controlId='customerFirstName' as={Row} >
                    <Col sm="3"><Form.Label>First Name:</Form.Label></Col>
                    <Col>
                        <Form.Control as='input' type='text' value={props.firstName} onChange={props.onFirstNameChange} />
                    </Col>
                </Form.Group>

                <Form.Group controlId='customerLastName' as={Row} >
                    <Col sm="3"><Form.Label>Last Name:</Form.Label></Col>
                    <Col>
                        <Form.Control as='input' type='text' value={props.lastName} onChange={props.onLastNameChange} />
                    </Col>
                </Form.Group>

                <Form.Group controlId='submitButton' as={Row} >
                    <Col sm="3">&nbsp;</Col>
                    <Col><center><Button type='submit' id='purchase-button'>{props.buttonText}</Button></center></Col>
                </Form.Group>
            </Form>
        </div>
    );
}


function ReservationList(props) {
    props.reservations.sort((a, b) => {
        if (a.type === b.type) {
            return b.id - a.id;
        } else if (a.type === "OWNER" && b.type === "AGENT") {
            return -1;
        } else if (a.type === "OWNER" && b.type === "CUSTOMER") {
            return -1;
        } else if (a.type === "AGNET" && b.type === "OWNER") {
            return 1;
        } else if (a.type === "AGNET" && b.type === "CUSTOMER") {
            return -1;
        }
        return 1;
    });
    return (
        <div id="reservations-list">
            <div key={-1} className={"reservation-item " + (props.reservation === -1 ? "focused" : "")} onClick={(e) => props.onClick(-1)}>NEW RESERVATION</div>
            {props.reservations.map(x =>
                <div key={x.id + "_" + x.type} className={"reservation-item " + x.type + " " + (props.reservation === x.id ? "focused" : "")} onClick={(e) => props.onClick(x.id)}>
                    <div className='row'>
                        <div className='col-6'>Tel: &nbsp;&nbsp;&nbsp; {x.mobile}</div>
                        <div className='col-6'>By: <b>{x.user}</b></div>
                    </div>
                    <div className='col-12'>Seats: {x.seats.join(", ")}</div>
                </div>
            )
            }
        </div>
    );
}
