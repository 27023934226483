import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { KeyFill, PencilSquare, List, CheckCircleFill, EyeSlashFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import UserModal from './UserModal';
import PasswordModal from './PasswordModal';
import AlertBox from '../../components/AlertBox';

import './Users.css';

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalKey: 0,
            alertError: false,
            alertTitle: "",
            alertMessage: "",
            showAlert: false,
            showUserModal: false,
            showPasswordModal: false,
            users: [],
            user: undefined
        };
    }

    componentDidMount() {
        this.loadUsers();
    }

    render() {
        const columns = [
            { name: 'Name', selector: row => row.name, sortable: true, width: "200px" },
            { name: "Username", selector: row => row.username, sortable: true },
            { name: "ROLES", selector: row => row.roles, sortable: true },
            { name: "Locked", selector: row => { return row.locked ? <CheckCircleFill /> : <></> }, sortable: true, center: true, width: '120px' },
            {
                name: "Ops", width: "80px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "left", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu className='user-list-item-menu' variant='light'>
                                <Dropdown.Item href="#" onClick={(e) => this.onChangeMenu(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={(e) => this.onPasswordMenu(row)}><KeyFill /> Password </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onLockMenu(row)}><EyeSlashFill /> {row.locked ? "Unlock" : "Lock"} </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    );
                }
            }
        ];

        return (
            <div className='admin-container'>
                <div className='container-title'>USERS</div>
                <div className='row container-controls-bar'>
                    <div className='col-md-9' />
                    <div className='col-md-3'>
                        <Button id='add-user-btn' style={{ float: "right" }} onClick={this.onAddUserClicked}>Add User</Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        className='data-table'
                        columns={columns}
                        data={this.state.users}
                        pagination
                        striped
                    />
                </div>
                <UserModal
                    key={this.state.modalKey}
                    show={this.state.showUserModal}
                    user={this.state.user}
                    onHide={(e) => this.setState({ showUserModal: false, user: undefined })}
                    onCancel={(e) => this.setState({ showUserModal: false, user: undefined })}
                    onSubmit={this.onUserModalSubmit}
                />
                <PasswordModal
                    key={this.state.modalKey + 1}
                    show={this.state.showPasswordModal}
                    user={this.state.user}
                    onCancel={(e) => this.setState({ modalKey: this.state.modalKey + 1, showPasswordModal: false })}
                    onSubmit={this.onPasswordModalSubmit}
                />
                <AlertBox
                    key={this.state.modalKey + 2}
                    error={this.state.alertError}
                    show={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    onHide={(e) => this.setState({ showAlert: false, modalKey: this.state.modalKey + 1 })}
                />
            </div>
        );
    }

    onAddUserClicked = () => {
        this.setState({
            showUserModal: true,
            user: undefined
        });
    }

    onChangeMenu = (user) => {
        this.setState({
            modalKey: this.state.modalKey + 1,
            showUserModal: true,
            user: user
        });
    }

    onUserModalSubmit = (user) => {
        this.setState({
            showUserModal: false
        }, this.saveUser(user));
    }

    loadUsers() {
        fetch("/admin/users", {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                res.json().then(json => {
                    this.setState({
                        users: json
                    });
                });
            } else {
                res.text().then(text => alert("ERROR: " + text));
            }
        }).catch(err => {
            console.error(err.message + ": loading users.");
        });
    }

    saveUser = (user) => {
        fetch("/admin/user", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        }).then((res) => {
            if (res.ok) {
                this.loadUsers();
            } else {
                res.text().then(text => alert("ERROR: " + text));
            }
        }).catch(err => {
            console.error(err.message + ": loading users.");
        });
    }

    onLockMenu = (user) => {
        user.locked = !user.locked;
        this.saveUser(user);
    }

    onPasswordMenu = (user) => {
        this.setState({
            modalKey: this.state.modalKey + 1,
            showPasswordModal: true,
            user: user
        });
    }

    onPasswordModalSubmit = (user, password) => {
        this.setState({
            modalKey: this.state.modalKey + 1,
            showPasswordModal: false
        });
        fetch("/admin/set_password", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user: { id: user.id },
                password: password
            })
        }).then((res) => {
            if (res.ok) {
                this.setState({
                    modalKey: this.state.modalKey + 1,
                    alertError: false,
                    alertTitle: "Set Password",
                    alertMessage: "Successful!",
                    showAlert: true
                });
            } else {
                res.text().then(text => this.setState({
                    modalKey: this.state.modalKey + 1,
                    alertError: true,
                    alertTitle: "Set Password",
                    alertMessage: "Failed!  " + text,
                    showAlert: true
                }));
            }
        }).catch(err => {
            this.setState({
                modalKey: this.state.modalKey + 1,
                alertError: true,
                alertTitle: "Set Password Error",
                alertMessage: err.message,
                showAlert: true
            });
        });
    }
}


export default Users;
