import React from 'react';
import './Sections.css';
import Button from 'react-bootstrap/Button';

// TODO: Use AlertBox instead of JavaScript alert message box.

class Sections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
            selection: 1,
            lang: "En"
        };
    }

    componentDidMount() {
        this.getSections();
    }

    getSections() {
        fetch(
            "/admin/section/",
            { headers: { 'Content-Type': 'application/json' } }
        ).then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                sections: json
            });
        }).catch(err => {
            console.error(err.message + ": loading sections.");
        })
    }

    onSectionClick(id) {
        this.setState({ selection: id });
    }

    onKeydown = (e) => {
        if (e.keyCode === 13) {
            var found = false;
            this.state.sections.forEach(x => {
                if (found) {
                    this.setState({ selection: x.id });
                    found = false;
                    return;
                }
                if (x.id === this.state.selection) {
                    found = true;
                }
            });
        }
    }

    onChange = (e) => {
        const sections = this.state.sections.map(x => {
            if (x.id === this.state.selection) {
                if (this.state.lang === 'En') {
                    x.nameEn = e.target.value.toUpperCase();
                } else if (this.state.lang === 'Sn') {
                    x.nameSn = e.target.value;
                } else if (this.state.lang === 'Ta') {
                    x.nameTa = e.target.value;
                }
            }
            return x;
        });
        this.setState({ sections: sections });
    }


    onSaveSections = (e) => {
        fetch("/admin/section", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.sections)
        }).then((res) => {
            if (res.ok) {
                alert("Successful.")
                this.getSections();
            } else {
                res.json().then(json => alert(json.message));
            }
        }).catch(err => {
            console.error(err.message + ": loading sections.");
        });

    }

    render() {
        return (
            <div className='sections-container'>
                <div className='container-title'>Sections</div>
                <div id='save-div'>
                    <Button id='sections-save-btn' variant="primary" onClick={this.onSaveSections}>Save Changes</Button>
                </div>
                <div className='sections-table'>
                    <div className='row heading'>
                        <div className='col-4 col-header'>English</div>
                        <div className='col-4 col-header'>Sinhala</div>
                        <div className='col-4 col-header'>Tamil</div>
                    </div>
                    {this.state.sections.map(x => {
                        if (x.id === this.state.selection) {
                            return (
                                <div className='row section' key={x.id}>
                                    <div className='col-4' >
                                        <input
                                            type='text'
                                            value={x.nameEn ? x.nameEn : ""}
                                            onChange={this.onChange}
                                            onKeyDown={this.onKeydown}
                                            autoFocus={this.state.lang === "En"}
                                            onFocus={(e) => this.setState({ lang: "En" })} />
                                    </div>
                                    <div className='col-4' >
                                        <input
                                            type='text'
                                            value={x.nameSn ? x.nameSn : ""}
                                            onChange={this.onChange}
                                            onKeyDown={this.onKeydown}
                                            autoFocus={this.state.lang === "Sn"}
                                            onFocus={(e) => this.setState({ lang: "Sn" })} />
                                    </div>
                                    <div className='col-4' >
                                        <input
                                            type='text'
                                            value={x.nameTa ? x.nameTa : ""}
                                            onChange={this.onChange}
                                            onKeyDown={this.onKeydown}
                                            autoFocus={this.state.lang === "Ta"}
                                            onFocus={(e) => this.setState({ lang: "Ta" })} />
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className='row section' key={x.id} title={x.nameEn} onClick={(e) => this.onSectionClick(x.id)}>
                                    <div className='col-4' title='nameEn'>{x.nameEn}</div>
                                    <div className='col-4' title='nameSn'>{x.nameSn}</div>
                                    <div className='col-4' titel='nameTa'>{x.nameTa}</div>
                                </div>
                            );

                        }
                    })}
                </div>
            </div>
        );
    }
}

export default Sections;