import React from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';

import './Banner.css';

function Banner() {
    return (
        <div className='navbar navbar-expand-md' >
            <div className='logo-div'>
                <img src='images/logo.png' height='70' alt='Logo' />
            </div>
            <div className='menu-div'>
                <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" title='expand-menu'>
                    <ThreeDotsVertical />
                </button>
                <div className='dropdown-menu collapse navbar-collapse navbar-nav' id='navbarCollapse'>
                    <a href="/" className='dropdown-item' style={{ fontSize: "1.2em", fontWeight: "800"}}>BOOK NOW</a>
                    {/* <a href="/timetables" className='dropdown-item' style={{ fontSize: "1.2em", fontWeight: "800"}}>TIMETABLE</a> */}
                    <a href="/fares" className='dropdown-item' style={{ fontSize: "1.2em", fontWeight: "800"}}>BUS FARE</a>
                    <a href="/reservations" className='dropdown-item' style={{ fontSize: "1.2em", fontWeight: "800"}}>OWNERS' CORNER</a>
                    <a href="/contact" className='dropdown-item' style={{ fontSize: "1.2em", fontWeight: "800"}}>CONTACT US</a>
                    <a href="/aboutus" className='dropdown-item' style={{ fontSize: "1.2em", fontWeight: "800"}}>ABOUT US</a>
                </div>
            </div>
            <div className='contact-div'>
                <img src='images/contact.png' height='70' alt='contact-info' />
            </div>
        </div>
    );
}

export default Banner;