import React from 'react';
import './Footer.css';

function Footer() {
    // TODO: Add About Us type of content in the footer...
    return (
        <div className='footer-bar'>EasyTravel.lk <div style={{float:"right"}}>Version: {window.appVersion}</div></div>
    );
}

export default Footer;