import React from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Input } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import "react-datepicker/dist/react-datepicker.css";
import { PencilSquare } from 'react-bootstrap-icons';
import { List } from 'react-bootstrap-icons';
import { EyeSlashFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import ScheduleModal from './ScheduleModal';

import './Schedules.css';

// TODO: Use AlertBox instead of JavaScript alert message box.

/**
 * 
 */
class Schedules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleNameFilter: "",
            busNoFilter: "",
            routeNameFilter: "",
            schedules: [],
            showModal: false,
            keyModal: 0,
            schedule: null
        };
    }

    componentDidMount() {
        this.getSchedules();
    }

    render() {
        const columns = [
            { name: "Name", selector: row => row.name, sortable: true },
            { name: "Start Date", selector: row => row.startDate, width: '130px', sortable: true },
            { name: "Bus", selector: row => row.bus.regNo, width: '100px', sortable: true },
            { name: "Route", selector: row => row.route.nameEn, sortable: true },
            {
                name: "Run/Skip", width: '150px', center: true, cell: (row, index, column, id) => {
                    return (
                        <div className='run-skip-display'>
                            <div>
                                <div className='run-skip-label' style={{ float: "left" }}>{row.runDays}</div>
                                <div className='run-skip-label' style={{ float: "right" }}>{row.skipDays}</div>
                            </div>
                            <div style={{ display: "table-row", width: "100px" }}>
                                <div className='run-skip run' style={{ width: Math.round(row.runDays * 100 / (row.runDays + row.skipDays)) + "px" }}></div>
                                <div className='run-skip skip' style={{ width: Math.round(row.skipDays * 100 / (row.runDays + row.skipDays)) + "px" }}></div>
                            </div>
                        </div>
                    );
                }
            },
            {
                name: "Ops", width: "80px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onChange(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onDelete(row)}><EyeSlashFill /> {row.active ? "Disable" : "Enable"} </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                }
            }
        ];
        var schedules = [];
        if(this.state.schedules) {
            schedules = this.state.schedules.filter(x =>
                x.name.includes(this.state.scheduleNameFilter) && x.bus.regNo.includes(this.state.busNoFilter) && x.route.nameEn.includes(this.state.routeNameFilter)
            );
        }

        return (
            <div className='admin-container'>
                <div className='container-title'>Schedules</div>
                <div className='row'>
                    <div className='col-md-3'>
                            <Input
                                className='form-control'
                                placeholder='Schedule name filter...'
                                value={this.state.scheduleNameFilter}
                                onChange={(e) => this.setState({ scheduleNameFilter: e.target.value })}
                            />
                    </div>
                    <div className='col-md-3'>
                        <Input
                            className='form-control'
                            placeholder='Bus No filter...'
                            value={this.state.busNoFilter}
                            onChange={(e) => this.setState({ busNoFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Input
                            className='form-control'
                            placeholder='Route name filter...'
                            value={this.state.routeNameFilter}
                            onChange={(e) => this.setState({ routeNameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Button id='add-schedule-btn' style={{ float: "right" }} onClick={this.onAddClick} > Add Schedule </Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        columns={columns}
                        data={schedules}
                        pagination
                        striped
                        highlightOnHover
                        conditionalRowStyles={[{
                            when: row => !row.active,
                            style: { backgroundColor: 'lightpink' }
                        }]
                        }
                    />
                </div>
                <ScheduleModal
                    key={this.state.keyModal}
                    show={this.state.showModal}
                    schedule={this.state.schedule}
                    onSuccess={this.onAddSuccess}
                    onClose={this.onClose} />
            </div>
        );
    } /* render() */


    getSchedules = () => {
        fetch("/admin/schedule", {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if(res.redirected) {
                window.location = res.url;
            }
            if(res.ok) {
                return res.json();
            } else {
                console.error(res.err);
                return null;
            }
        }).then((json) => {
            this.setState({ schedules: json });
        }).catch(err => {
            console.error(err.message + ": loading schedules 2");
        })
    }

    onAddClick = () => {
        this.setState({
            showModal: true,
            schedule: null,
            keyModal: (this.state.keyModal + 1)
        });
    }

    onClose = () => {
        this.setState({
            showModal: false
        });
    }


    onAddSuccess = () => {
        this.getSchedules();
        this.setState({
            showModal: false
        });
    }


    onChange = (schedule) => {
        this.setState({
            showModal: true,
            keyModal: (this.state.keyModal + 1),
            schedule: schedule
        });
    }

    onDelete = (schedule) => {
        fetch("/admin/schedule/toggle_active/" + schedule.id, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.ok) {
                this.getSchedules();
            } else {
                console.error("response.ok is FALSE.");
                response.json().then(json => alert(json.message));
            }
        }).catch(err => {
            alert("Failed: " + err.message);
        });
    }

}

export default Schedules;
