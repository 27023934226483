
import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

import './SearchResults.css';

class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blinkCount: 0
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.blinkDiscounts();
        }, 500);
    }

    blinkDiscounts = () => {
        setTimeout(() => {
            this.setState({ blinkCount: this.state.blinkCount + 1 });
            this.blinkDiscounts();
        }, 500);
    }

    render() {
        const routeCode = this.props.schedule.route.code;
        const nameEns = (this.props.schedule.route.nameEn ? this.props.schedule.route.nameEn.split("-") : ["", ""]);
        const fromCityEn = (this.props.schedule.direction === "UP") ? nameEns[0] : nameEns[1];
        const toCityEn = (this.props.schedule.direction === "UP") ? nameEns[1] : nameEns[0];
        const nameSns = (this.props.schedule.route.nameSn ? this.props.schedule.route.nameSn.split("-") : ["", ""]);
        const fromCitySn = (this.props.schedule.direction === "UP") ? nameSns[0].trim() : nameSns[1].trim();
        const toCitySn = (this.props.schedule.direction === "UP") ? nameSns[1].trim() : nameSns[0].trim();
        const nameTas = (this.props.schedule.route.nameTa ? this.props.schedule.route.nameTa.split("-") : ["", ""]);
        const fromCityTa = (this.props.schedule.direction === "UP") ? nameTas[0].trim() : nameTas[1].trim();
        const toCityTa = (this.props.schedule.direction === "UP") ? nameTas[1].trim() : nameTas[0].trim();
        const RenderDiscountAvailable = () => {
            if (this.props.schedule.discount) {
                if (this.state.blinkCount % 2 === 0) {
                    return (<div key={this.state.blinkCount} className='discount-alert blink-on'>{this.props.schedule.discount}</div>)
                } else {
                    return (<div key={this.state.blinkCount} className='discount-alert blink-off'>{this.props.schedule.discount}</div>)
                }
            } else {
                return <></>;
            }
        }
        const renderBookNow = () => {
            if (this.props.schedule.bus.seatLayout.numberOfSeats > this.props.schedule.bookings.length) {
                return <Button onClick={() => this.props.onBookButtonClick(this.props.schedule)} ref='discount'>BOOK NOW</Button>;
            } else {
                return <Button className='soldout'>SOLD OUT</Button>;
            }
        }
        return (
            <div className='col-sm-6 results-container'>
                <div className={'item-card ' + this.props.schedule.bus.serviceType}>
                    <div className='row'>

                        <div className='col-md-12 item-title'>
                            <span className='item-title-reno'>{this.props.schedule.bus.regNo}</span>
                            <span className='item-title-bustype'>({this.props.schedule.bus.serviceType.replace("_", " ") + " BUS"})</span>
                            <span className='item-title-routeno'><small>ROUTE:</small> {routeCode}</span>
                        </div>

                        <div className='col-md-3 bus-logo'><img src='/images/bus_icon.jfif' alt='Bus' /></div>

                        <div className='col-md-9'>
                            <div className='result-route-en'>{(fromCityEn.length > 0 && toCityEn.length > 0) ? (fromCityEn + " - " + toCityEn) : ""}</div>
                            <div className='result-route-sn'>{(fromCitySn.length > 0 && toCitySn.length > 0) ? (fromCitySn + " - " + toCitySn) : ""}</div>
                            <div className='result-route-ta'>{(fromCityTa.length > 0 && toCityTa.length > 0) ? (fromCityTa + " - " + toCityTa) : ""}</div>
                        </div>

                        <div className='col-md-3'>
                            <div className='amenities'>
                                {(this.props.schedule.bus.amenityAC) ? <img className='amenity' src='/images/ac.png' alt='AC' /> : <></>}
                                {(this.props.schedule.bus.amenityAdjSeat) ? <img className='amenity' src='/images/adjseat.png' alt='Adjustable Seats' /> : <></>}
                                {(this.props.schedule.bus.amenityWiFi) ? <img className='amenity' src='/images/wifi.png' alt='WiFi' /> : <></>}
                                {(this.props.schedule.bus.amenityTV) ? <img className='amenity' src='/images/tv.png' alt='TV' /> : <></>}
                                {(this.props.schedule.bus.amenityCharger) ? <img className='amenity' src='/images/charger.png' alt='Phone Charger' /> : <></>}
                                {(this.props.schedule.bus.amenitySounds) ? <img className='amenity' src='/images/soundsystem.png' alt='Sound System' /> : <></>}
                            </div>
                            <RenderDiscountAvailable />
                        </div>

                        <div className='col-md-9'>
                            <div>Leaving &nbsp;<b>{fromCityEn}</b> at &nbsp;<b>{this.props.schedule.time.substring(0, 5)}</b></div>
                            <div>Price from <b>{this.props.schedule.fromSection.nameEn}</b>  to <b>{this.props.schedule.toSection.nameEn}</b>: </div>
                            <div className='price'>Rs. {this.props.schedule.fullFare}.00</div>
                            <div className='bookbtn-div'>{renderBookNow()}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


/**
 * Search the database of Schedules using the 'params' passed in as
 * properties during creation and display the list of Schedules found.
 * 
 * Allow the user to pick a schedule and notify of the selection by invoking
 * the callback function 'onSelectSchedule'.
 * Properties:
 *     onScheduleSelect: callback when a schedule is selected.
 *     fromCity: Departure City
 *     toCity: Destination City
 *     travelDate= Travel Date
 */
class SearchResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: undefined
        };
    }

    componentDidMount() {
        this.getResults();
    }

    getResults() {
        if ((this.props.fromCity) && (this.props.toCity) && (this.props.travelDate)) {
            const data = {
                from: this.props.fromCity,
                to: this.props.toCity,
                date: this.props.travelDate.toISOString().split("T")[0]
            };

            fetch("/booking/schedules", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }).then(
                (res) => res.json()
            ).then((json) => {
                this.setState({
                    results: json
                });
            }).catch(err => {
                console.error(err.message + ": loading schedules 3");
            })
        }
    }

    /**
     * When the 'Book' button of one of the displayed Schedules is clicked,
     * invoke 'onSelectSchedule' callback property passing the schedule selected.
     * @param {One of the search results} result 
     */
    onBookButtonClick = (result) => {
        if (this.props.onScheduleSelect) {
            this.props.onScheduleSelect(result);
        }
    }

    render() {
        if (this.state.results === undefined && (this.props.fromCity === undefined || this.props.toCity === undefined)) {
            return (<></>);
        } else if (this.state.results === undefined) {
            return (
                <div style={{ textAlign: "center" }}>
                    <br />
                    <Spinner animation='border' role='status' >
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                    <br />
                    <br />
                    <br />
                </div>);
        } else if (this.state.results.length === 0) {
            return (
                <div className='no-results-container'>
                    <div className='no-results-card'>
                        <div className="no-results-title">No Scheduled Buses</div>
                        <div>for that day; <b>try another date...</b></div>
                        <div className="no-results-body">
                            Or call <b>Tel: +94777577577</b> for assistance.
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='search-results row'>
                    {this.state.results.map(x => <Result schedule={x} key={x.id} onBookButtonClick={this.onBookButtonClick} />)}
                </div>
            );
        }
    }
}

export default SearchResults;