import React from "react";
import { EyeSlashFill, List, PencilSquare, PersonFill } from 'react-bootstrap-icons';
import { Input } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import AgentModal from './AgentModal';
import UserModal from '../users/UserModal';
import AlertBox from '../../components/AlertBox';

import './Agents.css';

class Agents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertShow: false,
            alertTitle: "",
            alertMessage: "",
            dataTableMessage: "There are no records to display.",
            keyModal: 0,
            showAgentModal: false,
            showUserModal: false,
            agents: [],
            agent: null
        };
    }

    componentDidMount() {
        this.loadAgents();
    }

    render() {
        const columns = [
            { name: "Name", selector: row => row.name, sortable: true },
            { name: "Email", selector: row => row.email, width: "250px", sortable: true },
            { name: "Phone No", selector: row => row.phoneNo, width: "150px", sortable: true },
            { name: "Mobile No", selector: row => row.mobileNo, width: "150px", sortable: true },
            {
                name: "", width: "80px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onChange(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={(e) => this.onUserShow(row)}><PersonFill /> Login </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onDisable(row)}><EyeSlashFill /> Disable </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                }
            }
        ];

        let agents = this.state.agents ? this.state.agents : [];

        return (
            <div className='admin-container'>
                <div className='container-title'>AGENTS</div>
                <div className='row'>
                    <div className='col-md-3'>
                        <Input
                            className='form-control'
                            placeholder='Filter...'
                            value={this.state.nameFilter}
                            onChange={(e) => this.setState({ nameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-6' />
                    <div className='col-md-3'>
                        <Button id='add-agent-btn' style={{ float: "right" }} onClick={this.onAddButtonClick} > Add Agent </Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        columns={columns}
                        data={agents}
                        pagination
                        striped
                        highlightOnHover
                    // noDataComponent={this.state.dataTableMessage}
                    />
                </div>
                <AgentModal
                    id="agent-modal"
                    key={this.state.keyModal}
                    show={this.state.showAgentModal}
                    agentId={this.state.agent ? this.state.agent.id : null}
                    name={this.state.agent ? this.state.agent.name : null}
                    email={this.state.agent ? this.state.agent.email : null}
                    phoneNo={this.state.agent ? this.state.agent.phoneNo : null}
                    mobileNo={this.state.agent ? this.state.agent.mobileNo : null}
                    schedules={this.state.agent ? this.state.agent.schedules : []}
                    onSubmit={this.onAgentSubmit}
                    onCancel={(e) => this.setState({ showAgentModal: false })}
                />
                <UserModal
                    id="agent-user"
                    key={this.state.keyModal + 1}
                    show={this.state.showUserModal}
                    user={this.state.agent ? this.state.agent.user : null}
                    onSubmit={this.onUserModalSubmit}
                    onCancel={(e) => this.setState({ showUserModal: false })}
                    onHide={(e) => this.setState({ showUserModal: false })}
                />
                <AlertBox
                    key={this.state.keyModal + 2}
                    show={this.state.alertShow}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    onHide={(e) => this.setState({ alertShow: false })}
                />
            </div>
        );
    }


    loadAgents() {
        fetch("/admin/agents", {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                res.json().then((json) => {
                    this.setState({ agents: json });
                });
            } else {
                console.error("ERROR-TEXT: " + res.statusText);
            }
        }).catch(err => {
            console.error(err.message + ": loading agents.");
        });
    }


    saveAgent(agent, onSuccess, onFailure) {
        console.log("saveAgent: ");
        console.log("  AGENT: " + JSON.stringify(agent));
        fetch("/admin/agent", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(agent)
        }).then((res) => {
            if (res.ok) {
                console.log("agentSave SUCCESSFUL.");
                res.json().then(saveAgent => {
                    console.log("  SAVED_AGENT: " + JSON.stringify(saveAgent));
                    onSuccess(saveAgent);
                });
            } else {
                console.log("agentSave FAILED.");
                res.json().then(json => onFailure(json));
            }
        });
    }

    saveUser(user, agent, onSuccess, onFailure) {
        console.log("saveUser: ");
        console.log("  USER: " + JSON.stringify(user));
        console.log("  AGENT: " + JSON.stringify(agent));
        fetch("/admin/user", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        }).then((res) => {
            if (res.ok) {
                console.log("userSave SUCCESSFUL.");
                res.json().then(savedUser => {
                    console.log("SAVED_USER: " + JSON.stringify(savedUser));
                    agent.user = savedUser;
                    this.saveAgent(
                        agent,
                        (savedAgent) => { onSuccess(savedUser) },
                        (error) => onFailure(error)
                    );
                });
            } else {
                console.log("userSave FAILED.");
                res.json().then(json => onFailure(json));
            }
        }).catch(err => {
            onFailure(err);
        });
    }


    onAddButtonClick = (e) => {
        this.setState({
            keyModal: this.state.keyModal + 1,
            showAgentModal: true,
            agent: null
        });
    }

    onChange = (agent) => {
        this.setState({
            keyModal: this.state.keyModal + 1,
            showAgentModal: true,
            agent: agent
        });
    }

    onAgentSubmit = (changes) => {
        var agent = this.state.agent ? this.state.agent : changes;
        if (this.state.agent) {
            agent.name = changes.name;
            agent.email = changes.email;
            agent.phoneNo = changes.phoneNo;
            agent.mobileNo = changes.mobileNo;
            agent.schedules = changes.schedules;
        }
        this.saveAgent(
            agent,
            () => {
                alert("SUCCESSFUL!");
                this.setState({
                    showAgentModal: false
                }, this.loadAgents());
            },
            () => {
                alert("FAILED!");
            }
        );
    }

    onUserShow = (agent) => {
        this.setState({
            keyModal: this.state.keyModal + 1,
            showUserModal: true,
            agent: agent
        });
    }

    onUserModalSubmit = (user) => {
        this.saveUser(
            user,
            this.state.agent,
            () => {
                alert("SUCCESSFUL!");
                this.setState({
                    showUserModal: false
                }, this.loadAgents());
            },
            () => {
                alert("FAILED!");
            }
        );
    }

    onDisable = (agent) => {
    }

}

export default Agents;