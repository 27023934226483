import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import './AlertBox.css';

class AlertBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
            title: props.title ? props.title : "",
            message: props.message ? props.message : ""
        };
    }

    onHide = (e) => {
        if(this.props.onHide) {
            this.props.onHide(e);
        }
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.onHide}>
                <Modal.Header closeButton style={this.props.error ? {backgroundColor : "red"} : {backgroundColor : "green"}}>
                    <Modal.Title>{this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.message}</Modal.Body>
                <Modal.Footer><Button variant='secondary' onClick={this.onHide}>Close</Button></Modal.Footer>
            </Modal>
        );
    }
}

export default AlertBox;