import React from 'react';
import './MobileOTPVerify.css'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

// TODO: Use AlertBox instead of JavaScript alert message box.

class MobileOTPVerify extends React.Component {
    onOTPCodeChange = (e) => {
        this.setState({
            otpCode : e.target.value
        });
    }

    onOTPVerify = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(!this.state.otpCode || this.state.otpCode === "") {
            alert("Provide an OTP Code");
            return;
        }
        if(this.props.onOTPVerify) {
            this.props.onOTPVerify(this.state.otpCode);
        }
    }

    render() {
        return (
            <div className='otp-verifier'>
                <Form onSubmit={this.onOTPVerify} >
                    <Alert style={{ fontSize: "0.8em" }}>
                        Verify your <b>Phone Number</b> by entering the <b>OTP Code</b> you received on your phone as a <b>SMS</b>.
                        <br /><br />
                        You also should have received an <b>e-mail</b> with a link.  Click on that <b>link</b> to verify your <b>e-mail address</b>.
                    </Alert>
                    <div className='row'>
                        <div className='col-md-4 otp-control'>
                            <Form.Group style={{ width: "100%" }}>
                                <Form.Control as='input' type='text' required placeholder='Enter OTP Code' onChange={this.onOTPCodeChange} />
                            </Form.Group>
                        </div>
                        <div className='col-md-4 otp-control'>
                            <Button type='submit' variant='success' style={{ width: "100%" }}>Verify</Button>
                        </div>
                        <div className='col-md-4 otp-control'>
                            <Button variant='warning' style={{ width: "100%" }}>Resend</Button>
                        </div>
                    </div>
                </Form>

            </div>
        );
    }
}

export default MobileOTPVerify;