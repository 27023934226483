import React from "react";
import Modal from 'react-bootstrap/Modal';

import './BookingModal.css'

function Discount(props) {
    if (props.discount && props.discount > 0) {
        return (
            <>
                <div className="col-md-4 booking-label">Discount:</div>
                <div className="col-md-8 booking-data">{props.discount}</div>
            </>
        );

    } else {
        return (
            <>
            </>
        );

    }
}

class BookingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show
        }
    }

    onHide = () => {
        this.setState({ show: false });
    }

    // "id":92,
    // "bookingDatetime":"2023-01-25T06:19:54.000+00:00",
    // "routeCode":"580/122/69-1",
    // "routeName":"LAGGALA - MAHARAGAMA",
    // "busRegNo":"ND-6652",
    // "travelDatetime":"2023-01-25T11:55:00.000+00:00",
    // "scheduleName"
    // "fromCity":"GODAGAMA JUNCTION",
    // "toCity":"MATALE",
    // "seats":[12],
    // "customerFirstName":"Nadeeshani",
    // "customerLastName":"Kodituwakku",
    // "customerNic":"883082656V",
    // "customerPhone":"719984599",
    // "customerEmail":"miyurudahanayaka4@gmail.com",
    // "ticketPrice":99400,
    // "cardCharge":1789,
    // "serviceCharge":5000,
    // "total":106189,
    // "paymentRef":"20230000685207950"

    dateFormat(date) {
        return (new Date(date)).toLocaleString("en-GB");
    }

    priceFormat(price) {
        return (price/100.0).toFixed(2);
    }

    render() {
        let title = this.props.booking === null ? "" : ("INV" + ("0000000" + this.props.booking.id).slice(-7));
        if (!this.props.booking) return (<></>);
        return (
            <Modal show={this.state.show}>
                <Modal.Header closeButton onHide={this.onHide}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row booking-container">
                        <div className="col-md-4 booking-label">Booked On:</div>
                        <div className="col-md-8 booking-data">{this.dateFormat(this.props.booking.bookingDatetime)}</div>

                        <div className="col-md-4 booking-label">Route:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.routeCode}</div>

                        <div className="col-md-4 booking-label">Route Name:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.routeName}</div>

                        <div className="col-md-4 booking-label">Bus No:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.busRegNo}</div>

                        <div className="col-md-4 booking-label">Bus Name:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.busName}</div>

                        <div className="col-md-4 booking-label">Travel On:</div>
                        <div className="col-md-8 booking-data">{this.dateFormat(this.props.booking.travelDatetime)}</div>

                        <div className="col-md-4 booking-label">Journey:</div>
                        <div className="col-md-8 row" style={{padding:"0px", margin:"0px"}}>
                            <div className="col-4">From:</div>
                            <div className="col-8 booking-data"><b>{this.props.booking.fromCity}</b></div>
                            <div className="col-4">To:</div>
                            <div className="col-8 booking-data"><b>{this.props.booking.toCity}</b></div>
                        </div>

                        <div className="col-md-4 booking-label">Reserved Seats:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.seats.join(", ")}</div>

                        <div className="col-md-4 booking-label">Customer Name:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.customerFirstName} {this.props.booking.customerLastName}</div>

                        <div className="col-md-4 booking-label">Customer NIC:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.customerNic}</div>

                        <div className="col-md-4 booking-label">Customer Phone:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.customerPhone}</div>

                        <div className="col-md-4 booking-label">Customer Email:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.customerEmail}</div>

                        <div className="col-md-4 booking-label">Ticket Price:</div>
                        <div className="col-md-8 booking-data">{this.priceFormat(this.props.booking.ticketPrice)}</div>

                        <div className="col-md-4 booking-label">Service Charge:</div>
                        <div className="col-md-8 booking-data">{this.priceFormat(this.props.booking.serviceCharge)}</div>

                        <Discount discount={this.props.booking.discount} />

                        <div className="col-md-4 booking-label">Card Charge 1.8%:</div>
                        <div className="col-md-8 booking-data">{this.priceFormat(this.props.booking.cardCharge)}</div>

                        <div className="col-md-4 booking-label">Total Charged:</div>
                        <div className="col-md-8 booking-data">{this.priceFormat(this.props.booking.total)}</div>

                        <div className="col-md-4 booking-label">Payment Ref:</div>
                        <div className="col-md-8 booking-data">{this.props.booking.paymentRef}</div>

                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default BookingModal;