import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PencilSquare } from 'react-bootstrap-icons';
import { List } from 'react-bootstrap-icons';
import { SlashCircleFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import DiscountModal from './DiscountModal';

import './Discounts.css';


class Discounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            discounts: [],
            targetDiscount: {}
        };
    }

    componentDidMount() {
        this.getDiscounts();
    }

    getDiscounts() {
        fetch("/admin/discounts", { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    console.error("ERROR: getDiscounts fetch.");
                }
            })
            .then((json) => {
                this.setState({ discounts: json, targetDiscount: {} });
            })
    }

    saveDiscount(discount) {
        let body = JSON.stringify([discount]);
        fetch("/admin/discounts", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: body
        }).then((res) => {
            if (res.ok) {
                this.getDiscounts();
            } else {
                res.json().then(json => alert(json.message));
            }
        }).catch(err => {
            console.error(err.message + ": saving buses.");
        });
    }

    onEdit = (item) => {
        this.setState({ targetDiscount: item, showModal: true });
    }

    onToggleActive = (item) => {
        item.active = !item.active;
        this.saveDiscount(item);
    }

    onModalSubmit = (discount) => {
        this.setState({ showModal: false });
        this.saveDiscount(discount);
    }

    onModalCancel = (e) => {
        this.setState({ showModal: false });
    }

    render() {

        const columns = [
            { name: "Name", selector: row => row.name, sortable: true },
            { name: "Enabled", selector: row => row.active.toString().toUpperCase(), width: "100px" },
            { name: "Type", selector: row => row.type, width: "150px", sortable: true },
            { name: "Starts", selector: row => row.startDate, width: "150px", sortable: true },
            { name: "Ends", selector: row => row.endDate, width: "150px", sortable: true },
            { name: "Value", selector: row => row.value, width: "150px", sortable: true },
            { name: "Linked To", selector: row => row.linkedType, width: "150px", sortable: true },
            { name: "Linked Objects", selector: row => row.linkedIds.join(","), width: "250px", },
            {
                name: "Ops", width: "70px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onEdit(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onToggleActive(row)}><SlashCircleFill /> {row.active ? "Disable" : "Enable"} </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                }
            }
        ];

        let discounts = this.state.discounts;

        return (
            <div className='admin-container'>
                <div className='container-title'>DISCOUNTS</div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Button id='add-discount-btn' style={{ float: "right" }} onClick={(e) => this.setState({ showModal: true, targetDiscount: {} })} > Add Discount </Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        columns={columns}
                        data={discounts}
                        pagination
                        striped
                        highlightOnHover
                    />
                </div>
                <DiscountModal
                    show={this.state.showModal}
                    key={this.state.targetDiscount.id}
                    id={this.state.targetDiscount.id}
                    name={this.state.targetDiscount.name}
                    type={this.state.targetDiscount.type}
                    startDate={this.state.targetDiscount.startDate}
                    endDate={this.state.targetDiscount.endDate}
                    value={this.state.targetDiscount.value}
                    linkedType={this.state.targetDiscount.linkedType}
                    linkedIds={this.state.targetDiscount.linkedIds}
                    onSubmit={this.onModalSubmit}
                    onCancel={this.onModalCancel}
                />
            </div>
        );
    }
}

export default Discounts;
