import React from 'react';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Form, Modal } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

import './SearchRoute.css';

// TODO: Use AlertBox instead of JavaScript alert message box.

class SearchRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            serviceTypes: [],
            fromCity: [""],
            toCity: [""],
            serviceType: "ALL",
            showAlert: false
        };
        this.fromRef = React.createRef();
        this.toRef = React.createRef();
    }

    componentDidMount() {
        fetch("/booking/cities",
            { headers: { 'Content-Type': 'application/json' } }
        ).then((res) => res.json()
        ).then((json) => {
            this.setState({
                cities: json
            });
        }).catch(err => {
            alert("Failed to get cities!:" + err.message);
        });

        fetch("/booking/servicetypes",
            { headers: { 'Content-Type': 'application/json' } }
        ).then(
            (res) => res.json()
        ).then((json) => {
            json = json.filter(e => (e !== "SLTB") && (e !== "ROTATIONAL"));
            this.setState({
                serviceTypes: json
            });
        }).catch(err => {
            this.setState({ showAlert: true, message: ("Failed to get service types!:" + err.message) });
        });
    }

    onSearchButtonClick = () => {
        if(this.state.fromCity[0].nameEn === undefined || this.state.fromCity[0].nameEn === "") {
            this.setState({ showAlert: true, message: ("Departure Location has not been provied.") });
            return;
        }
        if(this.state.toCity[0].nameEn === undefined || this.state.toCity[0].nameEn === "") {
            this.setState({ showAlert: true, message: ("Destination Location has not been provied.") });
            return;
        }
        let fromCity = this.state.fromCity[0].nameEn.trim().toUpperCase();
        let toCity   = this.state.toCity[0].nameEn.trim().toUpperCase();
        let city = this.state.cities.filter(x => x.nameEn.toUpperCase() === fromCity);
        if (city.length === 0) {
            this.setState({ showAlert: true, message: ("Departure Location '" + this.state.fromCity[0].nameEn + "' is not found.  Please check the spellings.") });
            return;
        }
        city = this.state.cities.filter(x => x.nameEn.toUpperCase() === toCity);
        if (city.length === 0) {
            this.setState({ showAlert: true, message: ("Destination Location '" + this.state.toCity[0].nameEn + "' is not found.  Please check the spellings.") });
            return;
        }
        if (fromCity === toCity) {
            this.setState({ showAlert: true, message: ("Departure and Destination locations cannot be the same.") });
            return;
        }

        if (this.props.onSearch) {
            this.props.onSearch({
                fromCity: fromCity,
                toCity: toCity,
                serviceType: this.state.serviceType
            });
        }
    }

    render() {
        return (
            <div id='searchbox'>
                <div className='row'>
                    <div className='col-sm-6 col-md-4'>
                        <div className='form-group'>
                            <label>From:</label>
                            <Typeahead
                                id='from-city'
                                ref={this.fromRef}
                                clearButton={true}
                                onChange={(selected) => {
                                    if (selected.length > 0) this.setState({ fromCity: selected });
                                }}
                                onInputChange={(value) => {
                                    this.setState({ fromCity: [{ id: 0, nameEn: value }] });
                                }}
                                options={this.state.cities}
                                labelKey='nameEn'
                                selected={this.state.fromCity}
                                renderMenuItemChildren={(option, props, index) => {
                                    /* Render custom contents here. */
                                }}
                                filterBy={(option, props) => {
                                    if(props.text.length < 1) {
                                        this.fromRef.current.hideMenu();
                                        return false;
                                    }
                                    return (option.nameEn.search(new RegExp("^" + props.text, 'i')) >= 0);
                                }}
                                renderMenu={(results, menuProps) => {
                                    results = results.sort((a, b) => a.nameEn.length - b.nameEn.length);
                                    return (
                                        <Menu {...menuProps} >
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} key={index}>
                                                    {result.nameEn}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='form-group'>
                            <label>To:</label>
                            <Typeahead
                                id='to-city'
                                ref={this.toRef}
                                clearButton
                                onChange={(selected) => {
                                    if (selected.length > 0) this.setState({ toCity: selected })
                                }}
                                onInputChange={(value) => {
                                    this.setState({ toCity: [{ id: 0, nameEn: value }] });
                                }}
                                options={this.state.cities}
                                labelKey='nameEn'
                                selected={this.state.toCity}
                                filterBy={(option, props) => {
                                    if(props.text.length < 1) {
                                        this.toRef.current.hideMenu();
                                        return false;
                                    }
                                    return (option.nameEn.search(new RegExp("^" + props.text, 'i')) >= 0);
                                }}
                                renderMenu={(results, menuProps) => {
                                    results = results.sort((a, b) => a.nameEn.length - b.nameEn.length);
                                    return (
                                        <Menu {...menuProps}>
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} key={index}>
                                                    {result.nameEn}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-2'>
                        <div className='form-group'>
                            <label>Service Type:</label>
                            <Form.Select value={this.state.serviceType} onChange={(e) => this.setState({ serviceType: e.target.value })}>
                                <option>ALL</option>
                                {this.state.serviceTypes.map(x => <option value={x} key={x}>{x}</option>)}
                            </Form.Select>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-2'>
                        <div className='form-group'>
                            <label>&nbsp;</label>
                            <Button className='form-control' onClick={this.onSearchButtonClick}><Search /></Button>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showAlert}>
                    <Modal.Header>
                        <Modal.Title>ALERT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i>{this.state.message}</i>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e) => this.setState({ showAlert: false, message: null })}>OK</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

export default SearchRoute;