import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import './DiscountModal.css'

function toLocaleDate(x) {
    if (x === null || x === "") return null;
    return new Date(x.toString().split(" ").slice(0, 4).join(" ") + " 05:30:00 GMT+0530");
}

class DiscountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            discountTypes: [],
            discountLinkedTypes: [],
            objects: [],
            filter: "",
            // Discount Object Fields
            name: props.name ? props.name : "",
            type: props.type ? props.type : "PERCENTAGE",
            startDate: props.startDate ? new Date(props.startDate) : new Date(),
            endDate: props.endDate ? new Date(props.endDate) : new Date(),
            value: props.value ? props.value : "0.00",
            linkedType: props.linkedType ? props.linkedType : "SCHEDULES",
            linkedIds: props.linkedIds ? props.linkedIds : []
        };
        this.selectedIds = props.linkedIds;
    }

    componentDidMount() {
        this.getDiscountTypes();
        this.getDiscountLinkedTypes();
        this.getObjects(this.state.linkedType);
    }

    getDiscountTypes() {
        fetch("/admin/discounttypes", {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.ok) {
                res.json().then(json => {
                    json = json.map(x => x.replace(" ", "_"));
                    this.setState({ discountTypes: json });
                });
            }
        }).catch(err => { });
    }

    getDiscountLinkedTypes() {
        fetch("/admin/discount_linked_types", {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.ok) {
                res.json()
                    .then(json => {
                        json = json.map(x => x.replace(" ", "_"));
                        this.setState({ discountLinkedTypes: json });
                    });
            } else {
                console.error("Fetching data not OK.");
            }
        }).catch(err => {
            console.error("EXCEPTION: " + err);
        });
    }

    getObjects(type) {
        if (type === "SCHEDULES") {
            fetch("/admin/schedule", {
                headers: { 'Content-Type': 'application/json' }
            }).then(res => {
                if (res.ok) {
                    res.json().then(
                        (json) => {
                            json = json.map(x => {
                                return { id: x.id, name: x.name, selected: false };
                            });
                            this.setState({ objects: json });
                        }
                    );
                } else {
                }
            }).catch(err => console.error("EXCEPTION:" + err));
        } else if (type === "ROUTES") {
            fetch("/admin/route", { headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then((json) => {
                    json = json.map(x => {
                        return { id: x.id, name: (x.code + " | " + x.nameEn), selected: false };
                    });
                    this.setState({ objects: json });
                })
        } else if (type === "BUSES") {
            fetch("/admin/bus", { headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then((json) => {
                    json = json.map(x => {
                        return { id: x.id, name: (x.regNo + " | " + x.name), selected: false };
                    });
                    this.setState({ objects: json });
                })
        } else if (type === "OWNERS") {
            fetch("/admin/owner", { headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then((json) => {
                    json = json.map(x => {
                        return { id: x.id, name: (x.name + ", " + x.nic), selected: false };
                    });
                    this.setState({ objects: json });
                })
        } else {
            alert("Unsupported Type: " + type);
        }
    }

    onSelectedRowsChange = (state) => {
        let objIds = state.selectedRows.map(x => x.id);
        this.selectedIds = objIds;
    }

    onSelectAllClicked = (e) => {
        if (this.state.linkedIds.length === 0) {
            let objects = this.state.objects.filter(x => x.name.toLowerCase().includes(this.state.filter));
            let objIds = objects.map(x => x.id);
            this.setState({ linkedIds: objIds });
        } else {
            this.setState({ linkedIds: [] });
        }
    }

    onSubmitClicked = (e) => {
        let discount = {
            id: this.props.id,
            name: this.state.name,
            type: this.state.type,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            value: this.state.value,
            linkedType: this.state.linkedType,
            linkedIds: this.selectedIds
        };
        if (this.props.onSubmit) {
            this.props.onSubmit(discount);
        }
    }

    render() {
        const rowSelectCriteria = row => this.state.linkedIds.indexOf(row.id) !== -1;
        const columns = [
            { name: "Name", selector: row => row.name, sortable: true },
        ];

        let objects = this.state.objects
            .filter(x => this.state.linkedIds.includes(x.id) || x.name.toLowerCase().includes(this.state.filter))
            .sort((a, b) => this.state.linkedIds.includes(a.id) ? -1 : 1);

        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>DISCOUNT DETAILS</Modal.Title>
                </Modal.Header>

                <Form>
                    <Modal.Body>
                        <Row>
                            <Col sm={8}>
                                <Form.Group controlId='discount-modal-type'>
                                    <Form.Label>Discount Type</Form.Label>
                                    <Form.Select value={this.state.type} onChange={(e) => { this.setState({ type: e.target.value }); }}>
                                        {this.state.discountTypes.map(x => <option value={x} key={x}>{x}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId='discount-modal-amount'>
                                    <Form.Label>Amount OR %</Form.Label>
                                    <Form.Control type='text' defaultValue={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <Form.Group controlId='discount-modal-name'>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type='text' defaultValue={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group controlId='discount-modal-linked-type'>
                                    <Form.Label>Discount Linked Type</Form.Label>
                                    <Form.Select value={this.state.linkedType} onChange={(e) => { this.setState({ linkedType: e.target.value, linkedIds: [] }); this.getObjects(e.target.value); }}>
                                        {this.state.discountLinkedTypes.map(x => <option value={x} key={x}>{x}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group controlId='discount-modal-start-date'>
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker
                                        locale="en"
                                        className='form-control'
                                        dateFormat="yyyy/MM/dd"
                                        selected={this.state.startDate}
                                        onChange={(x) => this.setState({ startDate: toLocaleDate(x) })} />
                                </Form.Group>
                            </Col>

                            <Col sm={6}>
                                <Form.Group controlId='discount-modal-end-date'>
                                    <Form.Label>End Date</Form.Label>
                                    <DatePicker
                                        locale="en"
                                        className='form-control'
                                        dateFormat="yyyy/MM/dd"
                                        selected={this.state.endDate}
                                        onChange={(x) => this.setState({ endDate: toLocaleDate(x) })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Form.Group controlId='discount-modal-linked-objects'>
                            <Form.Label as={Row}>
                                <Col sm={3}>Linked Objects:</Col>
                                <Col sm={5}><Form.Control type='text' placeholder='Filter...' onChange={(e) => this.setState({ filter: e.target.value.toLowerCase() })} /></Col>
                                <Col sm={4}><Button onClick={this.onSelectAllClicked}><small>{(this.state.linkedIds.length === 0) ? "Select All" : "Clear All"}</small></Button></Col>
                            </Form.Label>
                            <DataTable
                                className='object-table'
                                columns={columns}
                                data={objects}
                                striped
                                dense
                                selectableRows
                                noTableHead
                                selectableRowsHighlight
                                pagination
                                onSelectedRowsChange={this.onSelectedRowsChange}
                                selectableRowSelected={rowSelectCriteria}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button id='add-discount-submit-btn' variant='success' type='submit' onClick={this.onSubmitClicked}>Add Discount</Button>
                        <Button variant='dark' onClick={this.props.onCancel}>Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default DiscountModal;
