import React from 'react';
import Button from 'react-bootstrap/Button';

import './Invoice.css';


// class GeneForm extends React.Component {
//     render() {
//         return (
//             <form id="ext-merchant-frm" action="=“{BASE URL}/merchant" method="post" acceptCharset="UTF-8" encType="application/x-www-form-urlencoded">
//                 <input type="hidden" id="merchantPgIdentifier" name="merchantPgIdentifier" defaultValue="" />
//                 Total Price<input type="text" id="chargeTotal" name="chargeTotal" defaultValue="" /><br /><br />
//                 <input type="hidden" id="currency" name="currency" defaultValue="" />
//                 <input type="hidden" id="paymentMethod" name="paymentMethod" defaultValue="" />
//                 <input type="hidden" id="orderId" name="orderId" defaultValue="" />
//                 <input type="hidden" id="invoiceNumber" name="invoiceNumber" defaultValue="" />
//                 <input type="hidden" id="successUrl" name="successUrl" defaultValue="" />
//                 <input type="hidden" id="errorUrl" name="errorUrl" defaultValue="" />
//                 <input type="hidden" id="storeName" name="storeName" defaultValue="" />
//                 <input type="hidden" id="transactionType" name="transactionType" defaultValue="" />
//                 <input type="hidden" id="timeout" name="timeout" defaultValue="" />
//                 <input type="hidden" id="transactionDateTime" name="transactionDateTime" defaultValue="" />
//                 <input type="hidden" id="language" name="language" defaultValue="" />
//                 <input type="hidden" id="txnToken" name="txnToken" defaultValue="" />
//                 <input type="hidden" id="itemList" name="itemList" defaultValue="" />
//                 <input type="hidden" id="otherInfo" name="otherInfo" defaultValue="" />
//                 <input type="hidden" id="merchantCustomerPhone" name="merchantCustomerPhone" defaultValue="" />
//                 <input type="hidden" id="merchantCustomerEmail" name="merchantCustomerEmail" defaultValue="" />
//                 <input type="hidden" id="disableWebCheckoutQr" name="disableWebCheckoutQr" defaultValue="" />
//                 <input type="hidden" id="disableWebCheckoutGuest" name="disableWebCheckoutGuest" defaultValue="" />
//                 <input type="hidden" id="disableWebCheckoutSignIn" name="disableWebCheckoutSignIn" defaultValue="" />
//                 <br />
//                 <input type="submit" defaultValue="Submit" /><br />
//             </form>

//         );
//     }
// }


function Discount(props) {
    if(props.discount > 0) {
        return(
            <div className='col-12 row' style={{margin: "0px", padding: "0px"}}>
                <div className='col-5'>&nbsp;</div>
                <div className='col-4'>Discounts</div>
                <div className='col-3 line-item-value'>{"- " + props.discount}</div>
            </div>
        );
    } else {
        return(
            <div className='col-12 row' style={{margin: "0px", padding: "0px"}}>
            </div>
        );
    }
}

/**
 * Properties:
 *  invoice: {
 *          customerFirstName: string
 *          customerLastName: string
 *          merchantCustomerPhone: string
 *          merchantCustomerEmail: string
 *          invoiceNumber: string
 *          invoiceDatetime: date_string
 *          seats: int[]
 *          ticketPrice: price
 *      }
 */
class Invoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successURL: (window.location.href.replace("bus_seat_booking", "") + "genie") 
        };
    }

    render() {
        var itemTotal = (this.props.invoice && this.props.invoice.seats) ? parseFloat(this.props.invoice.ticketPrice) * this.props.invoice.seats.length : 0;
        
        return (
            <div className='payment-pane'>
                <h1>Your Invoice</h1>
                <div className='row invoice'>

                    {/* Headings */}
                    <div className='col-sm-4'></div>
                    <div className='col-4'>Invoice No: </div>
                    <div className='col-4 line-item-value'>{this.props.invoice.invoiceNumber}</div>

                    <div className='col-sm-4'></div>
                    <div className='col-4'>Date: </div>
                    <div className='col-4 line-item-value'>{this.props.invoice.invoiceDatetime.split(" ")[0]}</div>

                    <div className='col-sm-4'></div>
                    <div className='col-4'>Time: </div>
                    <div className='col-4 line-item-value'>{this.props.invoice.invoiceDatetime.split(" ")[1].substring(0,5)}</div>

                    <div className='col-12'><b>{this.props.invoice.customerFirstName} {this.props.invoice.customerLastName}</b></div>
                    <div className='col-2'>Tel:</div>
                    <div className='col-10'><b>{this.props.invoice.merchantCustomerPhone}</b></div>
                    <div className='col-2'>Email:</div>
                    <div className='col-10'><b>{this.props.invoice.merchantCustomerEmail}</b></div>

                    {/* Order Items  */}
                    <div className='col-1 items-section'>Qty</div>
                    <div className='col-6 items-section'>Description</div>
                    <div className='col-2 items-section'><center>Unit</center></div>
                    <div className='col-3 items-section '><center>Total</center></div>

                    <div className='col-1'>{this.props.invoice.seats.length}</div>
                    <div className='col-6'>Tickets</div>
                    <div className='col-2'>{this.props.invoice.ticketPrice}</div>
                    <div className='col-3 line-item-value'>{itemTotal.toFixed(2)}</div>

                    <div className='col-1'>&nbsp;</div>
                    <div className='col-11'>{this.props.invoice.fromCity + " to " + this.props.invoice.toCity}</div>

                    <div className='col-1'>&nbsp;</div>
                    <div className='col-11'>Route: {this.props.invoice.routeName}</div>

                    <div className='col-1'>&nbsp;</div>
                    <div className='col-11'>Bus RegNo: {this.props.invoice.busNo}</div>

                    <div className='col-1'>&nbsp;</div>
                    <div className='col-11'>On <b>{this.props.invoice.travelDatetime.split(" ")[0]}</b> at <b>{this.props.invoice.travelDatetime.split(" ")[1]}</b></div>

                    <div className='col-1'>&nbsp;</div>
                    <div className='col-11'>Seat No(s): {this.props.invoice.seats.map(x => "[" + x + "] ")}</div>

                    <div className='col-5'>&nbsp;</div>
                    <div className='col-4'>Subtotal</div>
                    <div className='col-3 line-item-value'>{itemTotal.toFixed(2)}</div>

                    <div className='col-5'>&nbsp;</div>
                    <div className='col-4'>Service Charge</div>
                    <div className='col-3 line-item-value'>{this.props.invoice.serviceCharge}</div>

                    <Discount discount={this.props.invoice.discount} />

                    <div className='col-5'>&nbsp;</div>
                    <div className='col-4'>Bank Charge</div>
                    <div className='col-3 line-item-value'>{this.props.invoice.cardCharge}</div>

                    <div className='col-5 line-total'>&nbsp;</div>
                    <div className='col-3 line-total'>Total</div>
                    <div className='col-4 line-total line-item-value'>{this.props.invoice.chargeTotal}</div>
                </div>
                <br/>
                <form id="ext-merchant-frm" action={this.props.invoice.genieUrl} method="post" acceptCharset="UTF-8" encType="application/x-www-form-urlencoded">
                    <input type="hidden" id="merchantPgIdentifier" name="merchantPgIdentifier" defaultValue={this.props.invoice.merchantPgIdentifier} />
                    <input type="hidden" id="chargeTotal" name="chargeTotal" defaultValue={this.props.invoice.chargeTotal} />
                    <input type="hidden" id="currency" name="currency" defaultValue="LKR" />
                    <input type="hidden" id="paymentMethod" name="paymentMethod" defaultValue="Genie" />
                    <input type="hidden" id="orderId" name="orderId" defaultValue={this.props.invoice.orderId} />
                    <input type="hidden" id="invoiceNumber" name="invoiceNumber" defaultValue={this.props.invoice.invoiceNumber} />
                    <input type="hidden" id="successUrl" name="successUrl" defaultValue={this.state.successURL}  />
                    <input type="hidden" id="errorUrl" name="errorUrl" defaultValue={this.state.successURL} />
                    <input type="hidden" id="storeName" name="storeName" defaultValue={this.props.invoice.storeName} />
                    <input type="hidden" id="transactionType" name="transactionType" defaultValue="SALE" />
                    <input type="hidden" id="timeout" name="timeout" defaultValue="180" />
                    <input type="hidden" id="transactionDateTime" name="transactionDateTime" defaultValue={this.props.invoice.invoiceDatetime} />
                    <input type="hidden" id="language" name="language" defaultValue="en" />
                    <input type="hidden" id="txnToken" name="txnToken" defaultValue={this.props.invoice.txnToken} />
                    <input type="hidden" id="itemList" name="itemList" defaultValue="Tickets" />
                    <input type="hidden" id="otherInfo" name="otherInfo" defaultValue="" />
                    <input type="hidden" id="merchantCustomerPhone" name="merchantCustomerPhone" defaultValue={"+94" + this.props.invoice.merchantCustomerPhone} />
                    <input type="hidden" id="merchantCustomerEmail" name="merchantCustomerEmail" defaultValue={this.props.invoice.merchantCustomerEmail} />
                    <input type="hidden" id="disableWebCheckoutQr" name="disableWebCheckoutQr" defaultValue="N" />
                    <input type="hidden" id="disableWebCheckoutGuest" name="disableWebCheckoutGuest" defaultValue="N" />
                    <input type="hidden" id="disableWebCheckoutSignIn" name="disableWebCheckoutSignIn" defaultValue="N" />
                    <Button type="submit" >Proceed to Payment</Button>
                </form>
            </div>
        );
    }
}

export default Invoice;