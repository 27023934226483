import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Row, Col } from 'react-bootstrap';


class PasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmation: "",
            passwordErrMsg: "Fill in this field.",
            ConfirmationErrMsg: "Fill in this field.",
            validated: false,
            passwordValidated: false,
            confirmationValidated: false
        };
    }

    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>PASSWORD</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={this.state.validated} onSubmit={this.onSubmit} >
                    <Modal.Body>

                        <Form.Group controlId='password-modal-password' as={Row} className="form-grp">
                            <Form.Label as={Col} sm={2}>Password:</Form.Label>
                            <Col>
                                <Form.Control
                                    type='password'
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    isInvalid={this.state.validated  && !this.state.passwordValidated}
                                />
                                <Form.Control.Feedback type="invalid" >{this.state.passwordErrMsg}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='password-modal-confirmation' as={Row} className="form-grp">
                            <Form.Label as={Col} sm={2}>Confirmation:</Form.Label>
                            <Col>
                                <Form.Control
                                    type='password'
                                    onChange={(e) => this.setState({ confirmation: e.target.value })}
                                    isInvalid={this.state.validated && !this.state.confirmationValidated}
                                />
                                <Form.Control.Feedback type="invalid">{this.state.ConfirmationErrMsg}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="dark" onClick={this.props.onCancel}>Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    isConfirmationValid = (e) => {
        console.log(e.target.value);
        return false;
    }

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let passwordValid = false;
        let confirmationValid = false;
        if(this.state.password === "") {
            this.setState({passwordErrMsg: "Fill in this field."});
        } else if(this.state.password.length < 8) {
            this.setState({passwordErrMsg: "Password must be 8 characters or more."});
        } else {
            passwordValid = true;
        }

        if(this.state.password !== this.state.confirmation) {
            this.setState({ConfirmationErrMsg: "Does not match with the Password."});
        } else {
            confirmationValid = true;
        }
        this.setState({ 
            validated: true,
            passwordValidated: passwordValid,
            confirmationValidated: confirmationValid 
        });

        if(passwordValid && confirmationValid && this.props.onSubmit) {
            this.props.onSubmit(this.props.user, this.state.password);
        }
    }
}

export default PasswordModal;