import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Legend from './Legend';
import SeatNoChangeModal from './SeatNoChangeModal';
import Seating, { SEATTYPE } from '../../components/Seating';
import { FormControl } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './LayoutModal.css';

// TODO: Use AlertBox instead of JavaScript alert message box.

class LayoutModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyLayout: 0,
            id: this.props.layout ? this.props.layout.id : undefined,
            name: this.props.layout ? this.props.layout.name : undefined,
            numberOfSeats: this.props.layout ? this.props.layout.numberOfSeats : 0,
            rows: this.props.layout ? this.props.layout.layout.length : 0,
            cols: this.props.layout ? this.props.layout.layout[0].length : 5,
            layout: this.props.layout ? this.props.layout.layout : [],
            selectedType: "space",
            showSeatNoChangeModal: false,
            clickedSeatId: null,
            clickedSeatNo: null
        };
    }


    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.checkValidity() === false) {
            return;
        }
        if (!this.state.name) {
            alert("Layout name has not been provided.");
            return;
        }
        if (!this.state.numberOfSeats) {
            alert("Layout seat count has not been provieded.");
            return;
        }
        if (this.state.layout.length === 0) {
            alert("Layout has no seats...");
            return;
        }

        let form_data = {
            id: this.state.id,
            name: this.state.name,
            numberOfSeats: this.state.numberOfSeats,
            layout: JSON.stringify(this.state.layout)
        };

        fetch("/admin/layout", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form_data)
        }).then((res) => {
            if (res.ok) {
                if (this.props.onClose) {
                    this.props.onClose();
                }
            } else {
                res.json().then(json => alert(json.message));
            }
        });
    }

    onRowsColsChanged(rows, cols) {
        let layout = [];
        let seatno = 0;
        for (let r = 0; r < rows; r++) {
            let row = [];
            for (let c = 0; c < cols; c++) {
                row.push({ id: (r + "_" + c), type: SEATTYPE.seatava, content: ++seatno });
            }
            layout.push(row);
        }
        this.setState({
            numberOfSeats: seatno,
            layout: layout,
            rows: rows,
            cols: cols
        });
    }

    onRowCountChange = (e) => {
        this.onRowsColsChanged(e.target.value, this.state.cols);
    }

    onColCountChange = (e) => {
        this.onRowsColsChanged(this.state.rows, e.target.value);
    }

    onSeatingClick = (id) => {
        if (this.state.selectedType === "edit") {
            const [row, col] = id.split("_"); // clicked location
            if (this.state.layout[row][col].content) {
                this.setState({
                    showSeatNoChangeModal: true,
                    clickedSeatId: id,
                    clickedSeatNo: this.state.layout[row][col].content,
                    keyLayout: (this.state.keyLayout + 1)
                });
            }
        } else {
            const [row, col] = id.split("_"); // clicked location
            var layout = this.state.layout;
            layout[row][col].type = this.state.selectedType;
            let seatno = 0;
            // reset seat numbers
            for (let i = 0; i < this.state.rows; i++) {
                for (let j = 0; j < this.state.cols; j++) {
                    if (layout[i][j].type === SEATTYPE.seatava || layout[i][j].type === SEATTYPE.seatuna) {
                        layout[i][j].content = ++seatno;
                    } else {
                        layout[i][j].content = null;
                    }
                }
            }
            this.setState({
                numberOfSeats: seatno,
                layout: layout,
                keyLayout: (this.state.keyLayout + 1)
            });
        }

    }

    onTypeSelect = (type) => {
        this.setState({ selectedType: type });
    }

    onSeatNumChange = (no) => {
        const [row, col] = this.state.clickedSeatId.split("_");
        let layout = this.state.layout;
        layout[row][col].content = parseInt(no);
        this.setState({
            clickedSeatId: null,
            clickedSeatNo: null,
            layout: layout,
            showSeatNoChangeModal: false,
            keyLayout: (this.state.keyLayout + 1)
        });
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} >
                    <Modal.Header>
                        <Modal.Title>SEAT LAYOUT</Modal.Title>
                    </Modal.Header>
                    <Form className='form' onSubmit={this.onSubmit}>
                        <Modal.Body>
                            <Form.Group controlId='layout-name-input'>
                                <Form.Label>Layout Name:</Form.Label>
                                <Form.Control
                                    as="input"
                                    type="text"
                                    requred="true"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })} />
                            </Form.Group>
                            <Row>
                                <Form.Group controlId='layout-row-count-input' as={Col}>
                                    <Form.Label>Rows:</Form.Label>
                                    <FormControl type='number' onChange={this.onRowCountChange} value={this.state.rows} style={{textAlign: "center"}} />
                                </Form.Group>
                                <Form.Group controlId='layout-col-count-input' as={Col}>
                                    <Form.Label>Columns:</Form.Label>
                                    <FormControl type='number' onChange={this.onColCountChange} value={this.state.cols} style={{textAlign: "center"}} />
                                </Form.Group>
                                <Form.Group controlId='layout-no-of-seats' as={Col}>
                                    <Form.Label>Number of Seats:</Form.Label>
                                    <FormControl type='text' value={this.state.numberOfSeats} readOnly style={{textAlign: "center"}} />
                                </Form.Group>
                            </Row>
                            <Form.Group controlId='seatLayout'>
                                <Form.Label>Layout:</Form.Label>
                                <Row>
                                    <Form.Group controlId='layout-legend' as={Col} sm={3} className='layout-modal-legend'>
                                        <Legend
                                            onTypeSelect={this.onTypeSelect}
                                            selectedType={this.state.selectedType}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='layout-modal-seating' as={Col} sm={9}>
                                        <Seating
                                            key={this.state.keyLayout}
                                            layout={this.state.layout}
                                            onClick={this.onSeatingClick}
                                            nolegend
                                        />
                                    </Form.Group>
                                </Row>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='success' type='submit' id='add-layout-submit-btn' >Save</Button>
                            <Button variant='dark' onClick={(e) => this.props.onClose()}>Cancel</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <SeatNoChangeModal
                    show={this.state.showSeatNoChangeModal}
                    key={this.state.keyLayout}
                    onCancel={(e) => this.setState({ showSeatNoChangeModal: false, keyLayout: (this.state.keyLayout + 1) })}
                    seatNo={this.state.clickedSeatNo}
                    onSeatNoChange={this.onSeatNumChange}
                />
            </>
        );
    }
}

export default LayoutModal;