import React from "react";
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './UserModal.css';

class UserModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roles_list: [],
            name: (this.props.user ? this.props.user.name : ""),
            username: (this.props.user ? this.props.user.username : ""),
            locked: (this.props.user ? this.props.user.locked : false),
            roles: (this.props.user ? this.props.user.roles : "AGENT")
        };
    }

    componentDidMount() {
        this.loadRoles();
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.onSubmit}>
                    <Modal.Body>

                        <Form.Group controlId='user-modal-name' as={Row} className="user-modal-row">
                            <Form.Label as={Col} sm={2}>Name:</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    required
                                    id='user-name'
                                    type='input'
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='user-modal-username' as={Row} className="user-modal-row">
                            <Form.Label as={Col} sm={2}>Username:</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    required
                                    id='user-username'
                                    type='input'
                                    value={this.state.username}
                                    onChange={(e) => this.setState({ username: e.target.value })}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='user-modal-roles' as={Row} className="user-modal-row">
                            <Form.Label as={Col} sm={2}>Roles:</Form.Label>
                            <Col md={4}>
                                <Form.Select aria-label="AGENT" value={this.state.roles} onChange={this.onRoleChange}>
                                    {this.state.roles_list.map(r => <option key={r}> {r} </option>)}
                                </Form.Select>
                            </Col>
                            <Col md={4} />
                            <Form.Label as={Col} sm={1}>Locked:</Form.Label>
                            <Col sm={1}>
                                <Form.Check
                                    id='user-locked'
                                    checked={this.state.locked}
                                    onChange={(e) => this.setState({ locked: e.target.checked })}
                                />
                            </Col>
                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button type='submit' className="btn-primary">Submit</Button>
                        <Button onClick={this.props.onCancel} className="btn-secondary" >Cancel</Button>
                    </Modal.Footer>
                </Form>
            </Modal >
        );
    }

    onRoleChange = (e) => {
        this.setState({ roles: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.checkValidity() === false) {
            return;
        }
        if(this.props.onSubmit) {
            this.props.onSubmit({
                id: this.props.user ? this.props.user.id : undefined,
                name: this.state.name,
                username: this.state.username,
                locked: this.state.locked,
                roles: this.state.roles
            });
        }
    }

    loadRoles() {
        fetch("/admin/roles", {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                res.json().then(json => {
                    this.setState({
                        roles_list: json
                    });
                });
            } else {
                res.text().then(text => alert("ERROR Loading Roles: " + text));
            }
        }).catch(err => {
            console.error(err.message + ": loading roles.");
        });

    }
}


export default UserModal;