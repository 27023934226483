import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './FeaturesTab.css';

class FeaturesTab extends React.Component {
    render() {
        return (
            <div className='row'>
                <Tabs activeKey={this.props.active} justify onSelect={(k, e) => window.location = (k === 'book') ? "/bus_seat_booking" : "/fares"}>
                    <Tab title="BUS BOOKING" eventKey="book" tabClassName='feature-tab'/>
                    <Tab title="BUS FARE" eventKey="fares" tabClassName='feature-tab' />
                </Tabs>
            </div>
        );
    }
}

export default FeaturesTab;