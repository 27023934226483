import React from 'react';
import SearchRoute from '../components/SearchRoute.js';
import './Timetable.css';


class Timetable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyUpdate: 0,
            from: null,
            to: null
        }
    }

    onSearch = (params) => {
        this.setState({
            keyUpdate: this.state.keyUpdate+1,
            from: params.fromCity,
            to: params.toCity
        });
    }

    render() {
        if(!this.state.from || !this.state.to) {
            return(
                <>
                    <SearchRoute onSearch={this.onSearch} buttonText='TIMETABLE'/>
                </>
            );
        } else {
            return(
                <>
                    <SearchRoute onSearch={this.onSearch} buttonText='TIMETABLE'/>
                    <div className='timetable-image' key={this.state.keyUpdate}>
                        <img width='100%' src={"/booking/timetable?from=" + this.state.from + "&to=" + this.state.to + "&" + this.state.keyUpdate} alt="timetable" />
                    </div>
                </>
            );
        }
    }
}


export default Timetable;