import React from 'react';
import { List } from 'react-bootstrap-icons';
import { EyeSlashFill, PersonFill, PencilSquare } from 'react-bootstrap-icons';
import { Input } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import OwnerModal from './OwnerModal';
import './Owners.css';
import UserModal from '../users/UserModal';

// TODO: Use AlertBox instead of JavaScript alert message box.

class Owners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyModal: 1,
            showOwnerModal: false,
            showUserModal: false,
            owners: [],
            owner: null,
            nameFilter: "",
            nicBrcFilter: "",
            phoneFilter: ""
        }
    }

    componentDidMount() {
        this.getOwners();
    }

    render() {
        const columns = [
            { name: "Name", selector: row => row.name, sortable: true },
            { name: "NIC/BRC", selector: row => row.nic, width: "150px", sortable: true },
            { name: "Email", selector: row => row.email, width: "150px", sortable: true },
            { name: "Contact 1", selector: row => row.contactNo1, width: "150px", sortable: true },
            { name: "Contact 2", selector: row => row.contactNo2, width: "150px", sortable: true },
            { name: "Mobile No", selector: row => row.mobileNo, width: "150px", sortable: true },
            {
                name: "Ops", width: "70px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onChange(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={(e) => this.onUserShow(row)}><PersonFill /> Login </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onDelete(row)}><EyeSlashFill /> Disable </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                }
            }
        ];

        let owners = this.state.owners.filter(x =>
            x.name.toLowerCase().includes(this.state.nameFilter.toLowerCase())
            && x.nic.toLowerCase().includes(this.state.nicBrcFilter.toLowerCase())
            && (x.contactNo1.includes(this.state.phoneFilter) || x.contactNo2.includes(this.state.phoneFilter) || x.mobileNo.includes(this.state.phoneFilter))
        );

        return (
            <div className='admin-container'>
                <div className='container-title'>Owners</div>
                <div className='row'>
                    <div className='col-md-3'>
                        <Input
                            className='form-control'
                            placeholder='Name filter...'
                            value={this.state.nameFilter}
                            onChange={(e) => this.setState({ nameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Input
                            className='form-control'
                            placeholder='NIC/BRC filter...'
                            value={this.state.nicBrcFilter}
                            onChange={(e) => this.setState({ nicBrcFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Input
                            className='form-control'
                            placeholder='Phone No filter...'
                            value={this.state.phoneFilter}
                            onChange={(e) => this.setState({ phoneFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Button id='add-owner-btn' style={{ float: "right" }} onClick={this.onAddOwnerClick} > Add Owner </Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        columns={columns}
                        data={owners}
                        pagination
                        striped
                        highlightOnHover
                    />
                </div>
                <OwnerModal
                    id='owner-modal'
                    key={this.state.keyModal}
                    show={this.state.showOwnerModal}
                    owner={this.state.owner}
                    onSuccess={this.onAddOwnerModalSuccess}
                    onCancel={(e) => this.setState({ showOwnerModal: false, keyModal: this.state.keyModal + 1 })}
                />
                <UserModal
                    id="owner-user"
                    key={this.state.keyModal + 1}
                    show={this.state.showUserModal}
                    user={this.state.owner ? this.state.owner.user : null}
                    onSubmit={this.onUserModalSubmit}
                    onCancel={(e) => this.setState({ showUserModal: false })}
                    onHide={(e) => this.setState({ showUserModal: false })}
                />
            </div>
        );
    }

    getOwners() {
        fetch("/admin/owner", {
            headers: { 'Content-Type': 'application/json' }
        }).then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                owners: json
            });
        }).catch(err => {
            console.error(err.message + ": loading owners.");
        })
    }

    saveOwner(owner, onSuccess, onFailure) {
        fetch("/admin/owner", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(owner)
        }).then((res) => {
            if (res.ok) {
                console.log("saveOwner SUCCESSFUL.");
                res.json().then(json => onSuccess(json));
            } else {
                console.log("saveOwner FAILED.");
                res.json().then(json => onFailure(json));
            }
        });
    }

    saveUser(user, owner, onSuccess, onFailure) {
        fetch("/admin/user", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        }).then((res) => {
            if (res.ok) {
                console.log("saveUser SUCCESSFUL.");
                res.json().then(json => {
                    owner.user = json;
                    this.saveOwner(
                        owner,
                        () => { onSuccess(owner.user) },
                        (error) => onFailure(error)
                    );
                });
            } else {
                console.log("saveUser FAILED.");
                res.json().then(json => onFailure(json));
            }
        }).catch(err => {
            onFailure(err);
        });
    }

    onAddOwnerClick = () => {
        this.setState({
            showOwnerModal: true,
            keyModal: (this.state.keyModal + 1),
            owner: null
        });
    }

    onAddOwnerModalSuccess = (changes) => {
        var owner = this.state.owner ? this.state.owner : changes;
        if(this.state.owner) {
            owner.name = changes.name;
            owner.nic = changes.nic;
            owner.email = changes.email;
            owner.contactNo1 = changes.contactNo1;
            owner.contactNo2 = changes.contactNo2;
            owner.mobileNo = changes.mobileNo;
            owner.bankName = changes.bankName;
            owner.bankBranchCode = changes.bankBranchCode;
            owner.bankAccountHolderName = changes.bankAccountHolderName;
            owner.bankAccount = changes.bankAccount;
            owner.salesPerson = changes.salesPerson;
        }

        this.saveOwner(
            owner,
            () => {
                alert("SUCCESSFUL");
                this.setState({
                    showOwnerModal: false,
                    keyModal: (this.state.keyModal + 1)
                }, this.getOwners());
            },
            (err) => {
                alert(err);
            });
    }

    onChange = (owner) => {
        this.setState({
            showOwnerModal: true,
            keyModal: (this.state.keyModal + 1),
            owner: owner
        });
    }

    onUserShow = (owner) => {
        this.setState({
            showUserModal: true,
            keyModal: (this.state.keyModal + 1),
            owner: owner
        });
    }

    onUserModalSubmit = (user) => {
        this.saveUser(
            user,
            this.state.owner,
            () => {
                alert("SUCCESSFUL!");
                this.setState({
                    keyModal: this.state.keyModal + 1,
                    showUserModal: false,
                    owner: null
                }, this.getOwners());
            },
            (err) => {
                alert(JSON.stringify(err));
            });
    }

    onDelete = (owner) => {
        fetch("/admin/owner/delete/" + owner.id, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                this.getOwners();
            } else {
                res.json().then(json => alert(json.message));
            }
        }).catch(err => {
            console.error(err.message + ": saving owners.");
        });
    }
}

export default Owners;