import './Buses.css'
import React from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { PencilSquare } from 'react-bootstrap-icons';
import { List } from 'react-bootstrap-icons';
import { SlashCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { Input } from 'react-bootstrap-typeahead';

import BusModal from './BusModal';

// TODO: Use AlertBox instead of alert JavaScript message box.


/**
 * 
 */
class Buses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regNoFilter: "",
            busNameFilter: "",
            ownerNameFilter: "",
            mobileNoFilter: "",
            showBusModal: false,
            keyBusModal: 0,
            showAddLayout: false,
            keyLayoutModal: 10000,
            buses: [],
            bus: null
        }
    }

    componentDidMount() {
        this.getBuses();
    }

    getBuses() {
        fetch("/admin/bus", { headers: { 'Content-Type': 'application/json' } })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    buses: json
                });
            }).catch(err => {
                console.error(err.message + ": loading schedules 1");
            })
    }

    onAddBusInvoked = () => {
        this.setState({
            bus: null,
            showBusModal: true,
            keyBusModal: (this.state.keyBusModal + 1)
        });
    }

    onAdd = (data) => {
        let body = JSON.stringify(data);
        fetch("/admin/bus", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: body
        }).then((res) => {
            if (res.ok) {
                this.getBuses();
            } else {
                res.json().then(json => alert(json.message));
            }
        }).catch(err => {
            console.error(err.message + ": saving buses.");
        });

        this.onCloseModal();
    }

    onCloseModal = () => { // Modal 'Cancel' button press
        this.setState({
            keyBusModal: (this.state.keyBusModal + 1),
            showBusModal: false
        });
    }


    onChange = (bus) => {
        this.setState({
            bus: bus,
            showBusModal: true,
            keyBusModal: (this.state.keyBusModal + 1)
        });
    }

    onDelete = (bus) => {
        fetch("/admin/bus/delete/" + bus.id, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                this.getBuses();
            } else {
                res.json().then(json => alert(json.message));
            }
        }).catch(err => {
            console.error(err.message + ": deleting buses.");
        });
    }

    getBookingAlertIcon = (isAlert) => {
        if (isAlert) {
            return (<CheckCircleFill />);
        }
        return (<></>);
    }

    render() {
        const columns = [
            { name: 'RegNo', cell: (row, index, column, id) => { return (<div className={"regno"}>{row.regNo}</div>); }, sortable: true, width: "150px" },
            { name: "Bus Name", selector: row => row.name, sortable: true },
            { name: "Owner", selector: row => row.owner.name, sortable: true },
            { name: "Layout", selector: row => row.seatLayout.name, sortable: true, width: '150px' },
            { name: "Mobile", selector: row => row.mobileNo, sortable: true, width: '150px' },
            { name: "Alert", selector: row => { return row.sendBookingAlert ? <CheckCircleFill /> : <></> }, sortable: true, center: true, width: '100px' },
            {
                name: "Ops", width: "80px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onChange(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onDelete(row)}><SlashCircleFill /> Disable </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                }
            }
        ];
        let buses = this.state.buses.filter(x => {
            let mobileNo = x.mobileNo ? x.mobileNo : "";
            return x.regNo.includes(this.state.regNoFilter) 
            && x.name.includes(this.state.busNameFilter) 
            && x.owner.name.includes(this.state.ownerNameFilter) 
            && mobileNo.includes(this.state.mobileNoFilter);
        });
        
        return (
            <div className="admin-container">
                <div className='container-title'>Buses</div>
                <div className='row' id='bus-table-filters'>
                    <div className='col-md-2'>
                        <Input
                            id='bus-table-regno-filter'
                            className='form-control'
                            placeholder='RegNo filter...'
                            value={this.state.regNoFilter}
                            onChange={(e) => this.setState({ regNoFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-4'>
                        <Input
                            id='bus-table-busname-filter'
                            className='form-control'
                            placeholder='Bus Name filter...'
                            value={this.state.busNameFilter}
                            onChange={(e) => this.setState({ busNameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-2'>
                        <Input
                            id='bus-table-owner-filter'
                            className='form-control'
                            placeholder='Owner filter...'
                            value={this.state.ownerNameFilter}
                            onChange={(e) => this.setState({ ownerNameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-2'>
                        <Input
                            id='bus-table-mobile-filter'
                            className='form-control'
                            placeholder='Mobile filter...'
                            value={this.state.mobileNoFilter}
                            onChange={(e) => this.setState({ mobileNoFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-2'>
                        <Button id='add-bus-btn' style={{ float: "right" }} onClick={this.onAddBusInvoked} > Add Bus </Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        columns={columns}
                        data={buses}
                        pagination
                        striped
                    />
                </div>
                <BusModal
                    show={this.state.showBusModal}
                    key={this.state.keyBusModal}
                    onAdd={this.onAdd}
                    onCancel={this.onCloseModal}
                    bus={this.state.bus}
                />
            </div>
        );
    }
}

export default Buses;
