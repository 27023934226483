import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { XCircleFill } from 'react-bootstrap-icons';
import { PlusCircleFill } from 'react-bootstrap-icons';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './TimetableModal.css'


// TODO: Use AlertBox instead of JavaScript alert message box.

/**
 * Properties:
 *  show
 *  route
 *  onClose 
 */
class TimetableModal extends React.Component {
    // TODO: BUG: If all UP times are Empty an error occurs when saving...
    constructor(props) {
        super(props);
        var times = [];
        var id = 0;
        if (this.props.route) {
            times = this.props.route.times.map(x => {
                x.id = ++id;
                x.upTime = (x.upTime !== null) ? x.upTime.substring(0, 5) : x.upTime;
                x.downTime = (x.downTime !== null) ? x.downTime.substring(0, 5) : x.downTime;
                x.serviceType = x.serviceType.replace("_", " ");
                return x;
            })
        }
        this.state = {
            show: this.props.show,
            times: times,
            serviceTypes: [],
            upTime: "",
            downTime: "",
            serviceType: ((this.props.route === undefined) ? "NORMAL" : this.props.route.serviceType)
        };
    }

    componentDidMount() {
        this.getServiceTypes();
    }

    getServiceTypes() {
        fetch("/booking/servicetypes", { 
            headers: { 'Content-Type': 'application/json' }
        })
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                serviceTypes: json
            });
        }).catch(err => {
            alert("Timetable (" + (new Error().lineNumber) + "):" + err.message);
        });
    }

    onServiceTypeChange = (e) => {
        this.setState({ serviceType: e.target.value[0] });
    }

    onAdd = (e) => {
        var times = this.state.times;
        times.push({
            id: this.state.times.length + 1,
            upTime: this.state.upTime === "" ? null : this.state.upTime,
            downTime: this.state.downTime === "" ? null : this.state.downTime,
            serviceType: this.state.serviceType
        });
        this.setState({
            times: times,
            upTime: "",
            downTime: ""
        });
    }

    onDelete = (id) => {
        const times = this.state.times.filter(x => (x.id !== id));
        var reid = 0;
        const times2 = times.map(x => { x.id = (++reid); return x });
        this.setState({
            times: times2,
            upTime: "",
            downTime: ""
        });
    }

    onSubmit = (e) => {
        const data = this.state.times.map(x => {
            return {
                id: null,
                upTime: (x.upTime === "") ? null : x.upTime,
                downTime: (x.downTime === "") ? null : x.downTime,
                serviceType: x.serviceType.replace(" ", "_")
            }
        });
        fetch(
            "/admin/route/times/" + this.props.route.id,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
        ).then((res) => {
            if (res.ok) {
                if (this.props.onClose)
                    this.props.onClose();
            } else {
                res.json().then(json => alert(json.message));
            }
        })
    }

    render() {
        if (!this.props.route) {
            return (<></>);
        }

        return (
            <Modal show={this.state.show}>
                <Modal.Header>
                    <div>
                        <Modal.Title>Timetable</Modal.Title>
                        <div>{this.props.route.nameEn} <span style={{fontSize: "0.8em"}}>({this.props.route.serviceType})</span></div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='row' key='1000' style={{ borderBottom: "1px solid black" }}>
                        <div className='col-3'></div>
                        <div className='col-3 times times-head'>Up Time</div>
                        <div className='col-3 times times-head'>Down Time</div>
                        <div className='col-1'></div>
                        <div className='col-3'></div>
                    </div>
                    {this.state.times.map(x =>
                        <div className='row' key={x.id}>
                            <div className='col-3'></div>
                            <div className='col-3 times'>{x.upTime}</div>
                            <div className='col-3 times'>{x.downTime}</div>
                            <div className='col-1'><XCircleFill onClick={(e) => this.onDelete(x.id)} /></div>
                            <div className='col-3'></div>
                        </div>
                    )}
                    <div className='row' key='1001'>
                        <div className='col-3'></div>
                        <div className='col-3 times'><Form.Control type='text' placeholder='HH:MM' value={this.state.upTime} onChange={(e) => this.setState({ upTime: e.target.value })} /></div>
                        <div className='col-3 times'><Form.Control type='text' placeholder='HH:MM' value={this.state.downTime} onChange={(e) => this.setState({ downTime: e.target.value })} /></div>
                        <div className='col-1'><PlusCircleFill onClick={this.onAdd} /></div>
                        <div className='col-3'></div>
                    </div>
                    <div className='btn-row' key='1002'>
                        <div className='btn-col'><Button onClick={this.onSubmit}>Submit</Button></div>
                        <div className='btn-col'><Button onClick={this.props.onClose} variant='secondary'>Cancel</Button></div>
                    </div>

                </Modal.Body>
            </Modal>
        );
    }
}

export default TimetableModal;
