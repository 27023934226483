import React from 'react';
import SearchBox from './SearchBox'
import SearchResults from './SearchResults';
import CustomerInfo from './CustomerInfo';
import MobileOTPVerify from './MobileOTPVerify';
import Invoice from './Invoice';
import TopShedules from '../components/TopSchedules';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import FeaturesTab from '../components/FeaturesTab';

import './Booking.css';

const STAGES = {
    SearchSchedules: 0,
    CollectCustomerInfo: 1,
    VerifyMobileOTP: 2,
    ShowInvoice: 3
};


// TODO: Use AlertBox instead of JavaScript alert message box.

/**
 * CustomerInfo:
 *      firstName
 *      lastName
 *      gender
 *      mobile
 *      email
 *      nic
 *      seats : int[]
 * 
 */
class Booking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: STAGES.SearchSchedules,
            keyDisplay: 1,
            fromCity: undefined,
            toCity: undefined,
            travelDate: undefined,
            schedule: undefined,
            customerInfo: undefined,
            otpVerified: false,
            invoice: undefined,
            showAlert: false,
            message: undefined
        };
    }

    /**
     * Invoked when customer presses "SEARCH" button.  Will search for
     * matching schedules
     * @param {Search parameters} searchParams 
     */
    onSearch = (searchParams) => {
        this.setState({
            stage: STAGES.SearchSchedules,
            keyDisplay: (this.state.keyDisplay + 1),
            fromCity: searchParams.fromCity,
            toCity: searchParams.toCity,
            travelDate: searchParams.travelDate
        });
        window.scrollTo(0, 0);
    }

    /**
     * Invoked when a Schedule is selected in the SearchResults list.
     * @param {*} schedule - the schedule customer has selected.
     */
    onScheduleSelect = (schedule) => {
        this.setState({
            stage: STAGES.CollectCustomerInfo,
            keyDisplay: (this.state.keyDisplay + 1),
            schedule: schedule
        });
    }

    /**
     * Called when customer has finished filling in personal-information
     * and proceeds to generate the invlice... but we shall go to the page
     * that will ask the user to veirfy the provided phone number using OTP.
     * @param {*} customerInfo - Customer Details
     * @returns - none
     */
    onCustomerInfoComplete = (customerInfo) => {

        if (customerInfo.seats.length === 0) {
            alert("Must select at least one seat.");
            return;
        }

        fetch("/booking/sendverificationcodes", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                mobile: customerInfo.mobile,
                email: customerInfo.email,
            })
        }).then((res) => {
            if (res.ok) {
                this.setState({
                    stage: STAGES.VerifyMobileOTP,
                    keyDisplay: (this.state.keyDisplay + 1),
                    customerInfo: customerInfo
                });
            } else {
                alert("Error..." + JSON.stringify(res));
            }
        }).catch(err => {
            this.setState({
                keyDisplay: (this.state.keyDisplay + 1),
                showAlert: true,
                message: "Error: " + err.message
            });
        });
    }


    /**
     * Called when customer enters the OTP code and asks to verify
     * the customer phone number.
     * @param {*} otpCode 
     */
    onOtpVerify = (otpCode) => {
        const verifymobile_data = {
            mobile: this.state.customerInfo.mobile,
            otpCode: otpCode,
        };
        fetch("/booking/verifymobile", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(verifymobile_data)
        }).then((res) => {
            if (res.ok) {
                this.setState({ otpVerified: true });
                this.saveInvoice();
            } else {
                alert("OTP Verification Error: " + JSON.stringify(res));
            }
        }).catch(err => {
            alert("OTP Verification Failed: " + JSON.stringify(err));
            this.setState({
                keyDisplay: (this.state.keyDisplay + 1),
                showAlert: true,
                message: "Error: " + err.message
            });
        });
    }


    /**
     * Called when the customer has successfully verified their phone number.
     * "saveInvoice" will generate a new Invoice and return it.
     */
    saveInvoice() {
        const save_invoice_data = {
            scheduleId: this.state.schedule.id,
            travelDatetime: this.state.travelDate.toISOString().split("T")[0] + "T" + this.state.schedule.time + "+05:30",
            fromSection: this.state.fromCity,
            toSection: this.state.toCity,
            fare: this.state.schedule.fullFare,
            seats: this.state.customerInfo.seats,
            customerFirstName: this.state.customerInfo.firstName,
            customerLastName: this.state.customerInfo.lastName,
            gender: this.state.customerInfo.gender,
            customerNic: this.state.customerInfo.nic,
            customerPhone: this.state.customerInfo.mobile,
            customerEmail: this.state.customerInfo.email
        };

        fetch("/booking/book", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(save_invoice_data)
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.status === "Successful") {
                this.setState({
                    stage: STAGES.ShowInvoice,
                    keyDisplay: (this.state.keyDisplay + 1),
                    invoice: res.invoice
                });
            } else {
                this.setState({
                    stage: STAGES.CollectCustomerInfo,
                    showAlert: true,
                    message: res.message
                });
            }
        }, (err) => {
            console.error("ERROR OBJ: " + JSON.stringify(err));
            this.setState({
                stage: STAGES.CollectCustomerInfo,
                showAlert: true,
                message: "Error: " + JSON.stringify(err)
            });
        }).catch(err => {
            this.setState({
                showAlert: true,
                message: "Error: " + JSON.stringify(err)
            });
        });
    }


    render() {
        if (this.state.stage === STAGES.SearchSchedules) {

            return (
                <div className='booking'>
                    <FeaturesTab active='book' />
                    <SearchBox
                        key={this.state.keyDisplay}
                        onSearch={this.onSearch}
                        fromCity={this.state.fromCity}
                        toCity={this.state.toCity}
                        travelDate={this.state.travelDate} />
                    <SearchResults
                        key={this.state.keyDisplay + 1}
                        onScheduleSelect={this.onScheduleSelect}
                        fromCity={this.state.fromCity}
                        toCity={this.state.toCity}
                        travelDate={this.state.travelDate}
                    />
                    <div className='description'>
                        <h3>Reserve a bus seat with EasyTravel.lk</h3>
                        <p><strong>EasyTravel.lk</strong> is an online seat booking system that facilitates convenient reservation
                            of your next bus journey.  EasyTravel.lk has teamed up with a large group of private bus operators in
                            Sri Lanka to give you an intuitive and easy-to-use reservation system.  <strong>EasyTravel.lk</strong> has
                            schedules for most popular bus routes that a commuter can reserve seat with only a few mouse clicks through 
                            our website.</p>
                        <p>You will have the opportunity to reserve your seat until an hour before the departure time.</p>

                        <h3>Bus Fare</h3>
                        <p>Are you looking for an easy way to find bus fares between any two locations?  Visit our bus fare lookup
                            page and you will find the most accurate and up-to-date bus fares as stipulated by the
                            National Transportation Commission (NTC) on relevant routes and on different categories of buses.</p>
                    </div>
                    <TopShedules onSearch={this.onSearch} />
                </div>
            );

        } else if (this.state.stage === STAGES.CollectCustomerInfo) {

            return (
                <>
                    <div className='booking'>
                        <FeaturesTab active='book' />
                        {/* <SearchBox onSearch={this.onSearch} /> */}
                        <CustomerInfo
                            key={this.state.keyDisplay}
                            onComplete={this.onCustomerInfoComplete}
                            schedule={this.state.schedule}
                            travelDate={this.state.travelDate}
                            info={this.state.customerInfo}
                        />
                    </div>

                    <Modal show={this.state.showAlert}>
                        <Modal.Header>
                            <Modal.Title>ALERT</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <i>{this.state.message}</i>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={(e) => this.setState({ showAlert: false, message: null })}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );

        } else if (this.state.stage === STAGES.VerifyMobileOTP) {
            return (
                <div className='booking'>
                    <FeaturesTab active='book' />
                    {/* <SearchBox onSearch={this.onSearch} /> */}
                    <MobileOTPVerify key={this.state.keyDisplay} onOTPVerify={this.onOtpVerify} />
                    <Modal show={this.state.showAlert}>
                        <Modal.Header>
                            <Modal.Title>ALERT</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <i>{this.state.message}</i>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={(e) => this.setState({ showAlert: false, message: null })}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );

        } else if (this.state.stage === STAGES.ShowInvoice) {

            return (
                <div className='booking'>
                    <FeaturesTab active='book' />
                    {/* <SearchBox onSearch={this.onSearch} /> */}
                    <Invoice
                        key={this.state.keyDisplay}
                        onToPayment={this.onToPayment}
                        invoice={this.state.invoice}
                    />
                    <Modal show={this.state.showAlert}>
                        <Modal.Header>
                            <Modal.Title>ALERT</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <i>{this.state.message}</i>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={(e) => this.setState({ showAlert: false, message: null })}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );

        }
    }
}

export default Booking;