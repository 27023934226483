import React from 'react';
import SearchRoute from '../components/SearchRoute';
import FeaturesTab from '../components/FeaturesTab';
import './Fare.css';


class Fare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyUpdate: 0,
            from: null,
            to: null,
            type: null
        }
    }

    onSearch = (params) => {
        this.setState({
            keyUpdate: this.state.keyUpdate + 1,
            from: params.fromCity,
            to: params.toCity,
            type: params.serviceType
        });
    }

    render() {
        if (!this.state.from || !this.state.to) {
            return (
                <>
                    <FeaturesTab active='fares' />
                    <SearchRoute onSearch={this.onSearch} buttonText='FARES' />
                </>
            );
        } else {
            return (
                <>
                    <FeaturesTab active='fares' />
                    <SearchRoute onSearch={this.onSearch} buttonText='FARES' />
                    <div className='timetable-image' key={this.state.keyUpdate}>
                        <img width='100%' src={"/booking/fare?from=" + this.state.from + "&to=" + this.state.to + "&type=" + this.state.type + "&" + this.state.keyUpdate} alt="please wait..." />
                    </div>
                </>
            );
        }
    }
}


export default Fare;