import React from 'react';
import Button from 'react-bootstrap/Button';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Modal } from 'react-bootstrap';
import { ArrowLeftRight, Search } from 'react-bootstrap-icons';

import './SearchBox.css';

// eslint-disable-next-line
Date.prototype.adjDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


function toLocaleDate(x) {
    if (x !== null) {
        return new Date(x.toString().split(" ").slice(0, 4).join(" ") + " 05:30:00 GMT+0530");
    } else {
        return null;
    }
}

// TODO: Use AlertBox instead of JavaScript alert message box.

class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            fromCity: this.props.fromCity ? [{ id: 0, nameEn: this.props.fromCity }] : [{ id: 0, nameEn: "" }],
            toCity: this.props.toCity ? [{ id: 0, nameEn: this.props.toCity }] : [{ id: 0, nameEn: "" }],
            travelDate: this.props.travelDate ? this.props.travelDate : "",
            showAlert: false
        };
        this.fromRef = React.createRef();
        this.toRef = React.createRef();
    }

    render() {
        return (
            <div id='searchbox'>
                <div className='row'>
                    <div className='col-sm-6 col-md-4'>
                        <div className='form-group'>
                            <label>From:</label>
                            <Typeahead
                                id='from-city'
                                ref={this.fromRef}
                                clearButton
                                onChange={(selected) => {
                                    if (selected.length > 0) this.setState({ fromCity: selected });
                                }}
                                onInputChange={(value) => {
                                    this.setState({ fromCity: [{ id: 0, nameEn: value }] });
                                }}
                                options={this.state.cities}
                                labelKey='nameEn'
                                selected={this.state.fromCity}
                                filterBy={(option, props) => {
                                    if (props.text.length < 1) {
                                        this.fromRef.current.hideMenu();
                                        return false;
                                    }
                                    return (option.nameEn.search(new RegExp("^" + props.text, 'i')) >= 0);
                                }}
                                renderMenu={(results, menuProps) => {
                                    results = results.sort((a, b) => a.nameEn.length - b.nameEn.length);
                                    return (
                                        <Menu {...menuProps}>
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} key={index}>
                                                    {result.nameEn}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-1' id='section-switcher'>
                        <div className='form-group'>
                            <label>&nbsp;</label>
                            <Button className='form-control' onClick={this.onSwitchSections}>
                                <ArrowLeftRight />
                            </Button>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='form-group'>
                            <label>To:</label>
                            <Typeahead
                                id='to-city'
                                ref={this.toRef}
                                clearButton
                                onChange={(selected) => {
                                    if (selected.length > 0) this.setState({ toCity: selected })
                                }}
                                onInputChange={(value) => {
                                    this.setState({ toCity: [{ id: 0, nameEn: value }] });
                                }}
                                options={this.state.cities}
                                labelKey='nameEn'
                                selected={this.state.toCity}
                                filterBy={(option, props) => {
                                    if (props.text.length < 1) {
                                        this.toRef.current.hideMenu();
                                        return false;
                                    }
                                    return (option.nameEn.search(new RegExp("^" + props.text, 'i')) >= 0);
                                }}
                                renderMenu={(results, menuProps) => {
                                    results = results.sort((a, b) => a.nameEn.length - b.nameEn.length);
                                    return (
                                        <Menu {...menuProps}>
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index} key={index}>
                                                    {result.nameEn}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className='col-sm-6 col-md-2'>
                        <div className='form-group'>
                            <label>Travel Date:</label>
                            <ReactDatePicker
                                id='travel-date'
                                autoComplete='off'
                                dateFormat="yyyy/MM/dd"
                                className='form-control'
                                includeDateIntervals={[
                                    { start: (new Date()).adjDays(-1), end: (new Date()).adjDays(5) },
                                ]}
                                selected={this.state.travelDate}
                                onChange={(date) => this.setState({ travelDate: toLocaleDate(date) })}
                            />
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-1'>
                        <div className='form-group'>
                            <label>&nbsp;</label>
                            <Button className='form-control' onClick={this.onSearchButtonClick}>
                                <Search />
                            </Button>
                        </div>
                    </div>
                </div>

                {/* ------------------------------------------------------------------------------------------- */}

                <Modal show={this.state.showAlert}>
                    <Modal.Header>
                        <Modal.Title>ALERT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i>{this.state.message}</i>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e) => this.setState({ showAlert: false, message: null })}>OK</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

    componentDidMount() {
        try {
            fetch("/booking/cities", {
                headers: { 'Content-Type': 'application/json' }
            }
            ).then(
                (res) => res.json()
            ).then(
                (json) => { this.setState({ cities: json }) }
            ).catch(error => {
                this.setState({ showAlert: true, message: ("SearchBox Disconnected! Check connection and try again. " + error) });
                this.setState({ cities: [] })
            });
        } catch (err) {
            alert("Error loading cities...");
        }
    }

    onSwitchSections = (e) => {
        this.setState({ fromCity: this.state.toCity, toCity: this.state.fromCity });
        setTimeout(() => {
            this.onSearchButtonClick();
        }, 300);

    }

    onSearchButtonClick = () => {
        let fromCity = this.state.fromCity[0].nameEn.trim().toUpperCase();
        let toCity = this.state.toCity[0].nameEn.trim().toUpperCase();
        if ((fromCity === "")) {
            this.setState({ showAlert: true, message: ("Departure Location has not been provied.") });
            return;
        }
        let city = this.state.cities.filter(x => x.nameEn.toUpperCase() === fromCity);
        if (city.length === 0) {
            this.setState({ showAlert: true, message: ("Departure Location '" + this.state.fromCity[0].nameEn + "' is not found.  Please check the spellings.") });
            return;
        }
        if ((toCity === "")) {
            this.setState({ showAlert: true, message: ("Destination Location has not been provied.") });
            return;
        }
        city = this.state.cities.filter(x => x.nameEn.toUpperCase() === toCity);
        if (city.length === 0) {
            this.setState({ showAlert: true, message: ("Destination Location '" + this.state.toCity[0].nameEn + "' is not found.  Please check the spellings.") });
            return;
        }
        if (!this.state.travelDate) {
            this.setState({ showAlert: true, message: ("Travel Date has not been provided.") });
            return;
        }
        if (fromCity === toCity) {
            this.setState({ showAlert: true, message: ("Departure and Destination locations cannot be the same.") });
            return;
        }
        if (this.props.onSearch) {
            this.props.onSearch({
                fromCity: fromCity,
                toCity: toCity,
                travelDate: this.state.travelDate
            });
        }
    }



}

export default SearchBox;