import React from 'react';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import Banner from './components/Banner';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import Booking from './booking/Booking';
import Genie from './booking/Genie';
import Timetable from './timetable/Timetable';
import Fare from './fare/Fare';
import Footer from './components/Footer';
import AdminPanel from './admin/AdminPanel';
import Bookings from './admin/bookings/Bookings';
import Owner from './reservation/Reservation';

import './SeatBookingApp.css';
import TermsAndConditions from './components/TermsAndConditions';

function Admin() {
    return (
        <>
            <Banner />
            <AdminPanel />
        </>
    );
}

function OwnerRoute() {
    return (
        <>
            <Banner />
            <Owner />
        </>
    );
}

function Landing() {
    return (
        <Navigate to="/bus_seat_booking" />
    );
}

function Book() {
    return (
        <>
            <Banner />
            <Booking />
            <Footer />
        </>
    );
}

function Timetables() {
    return (
        <>
            <Banner />
            <Timetable />
            <Footer />
        </>
    );
}

function Fares() {
    return (
        <>
            <Banner />
            <Fare />
            <Footer />
        </>
    );
}

function BookingsDisplay() {
    return (
        <>
            <Banner />
            <Bookings />
            <Footer />
        </>
    );
}

function Contact() {
    return (
        <div>
            <Banner />
            <ContactUs />
            <Footer />
        </div >
    );
}

function About() {
    return (
        <>
            <Banner />
            <AboutUs />
            <Footer />
        </>
    );
}

function Terms() {
    return (
        <>
            <Banner />
            <TermsAndConditions />
            <Footer />
        </>
    );
}

function NotFound() {
    return (
        <div>
            <h4>Not Found.</h4>
        </div>
    );
}

function SeatBookingApp() {
    console.log("BASE_URL = " + window.BASE_URL);
    return (
        <div className='app'>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/bus_seat_booking" element={<Book />} />
                <Route path="/timetables" element={<Timetables />} />
                <Route path="/fares" element={<Fares />} />
                <Route path="/bookings" element={<BookingsDisplay />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/aboutus" element={<About />} />
                <Route path="/admin/*" element={<Admin />} />
                <Route path="/reservations/*" element={<OwnerRoute />} />
                <Route path="/genie/*" element={<Genie />} />
                <Route path="/terms" element={<Terms />} />
                <Route element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default SeatBookingApp;