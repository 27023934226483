import React from 'react';

import './TermsAndConditions.css';

function TermsAndConditions() {
    return (<div className='terms-and-conditions-container'>
        <h1>TERMS AND CONDITIONS EasyTravel.lk</h1>

        Easytravel.lk website developed with the objective of to provide mostly awaited transport information
        and reserve a bus seat for passengers for their journey.
        Terms and conditions are subject to change from time to time due to changing industry standards and
        another factors. Passengers who are visiting www.easytravel.lk shall have the benefit of search for Bus
        fares for several destinations and shall have the opportunity to reserve a ticket for a bus which has
        registered with easytravel.lk.

        <h2>Bus Fares</h2>
        Easytravel team takes pleasure in introducing this feature for checking bus fares through our website.
        The fares specified herein are as per existing rules and regulations in the Country.
        In the event that there is a discrepancy with respect to the Sums actually charged by a Service Provider,
        we shall not be responsible for the same as such a situation is beyond our control

        <h2>Bus Booking</h2>
        Based on the information given by the bus operators we have registered their busses with scheduled
        times and have integrated such buses for reservation online. We easy travel team shall only be
        responsible for facilitating the reservation of a seat.

        <h2>Process of the Booking</h2>
        <p>Passengers can log into www.easytravel.lk website and select the relevant route which they are
            planning to travel.</p>

        <p>They can view options of busses that are scheduled on respective route with details.</p>

        <p>Passenger may select a bus and process the booking<br />
            Passenger can select a seat or seats as per the seat map. Maximum of 6 seats can be selected for one
            passenger, If more information is needed passengers may simply call 0777577577 and verify.
            After selecting seat details passengers may provide his or her personal information to confirm who
            made the seat reservation. Easytravel team will issue an Easytravel ID which can be use next time login.
            Once a payment is made it is understood that a passenger has acknowledged terms and condition and is
            agreeable to the same.

            To process the payment a full bus fare and 1.8% of the said bus fare as banking charge and Rs.50/- or
            Rs.100/- as convenient fee is chargeable. ( Rs. 50/- or Rs.100/- will charge according to the service type
            or bus fare).
        </p>

        <p>
            Passenger will receive a SMS and an email on successful booking. Same information will be shared with
            Bus operators.
        </p>
        <p>
            Passenger has to take a printout of email and need to produce the same to the bus when he or she is
            boarding along with NIC/Passport/DL
        </p>
        <p>
            Passengers are requested to show his or her identity card or any proof document to prove identity when
            they take the seat.
        </p>
        <p>
            Passengers shall be required to be in his/her seat 30 minutes prior to the bus dispatch time.
            There won’t be any refund if the bus seat is reserved and if a Passenger is not traveling due to any
            reason.
        </p>
        <p>
            We easy travel act only as intermediary between passenger and the bus operator, transmitting the
            details of your reservation to the relevant bus operator and sending you a confirmation text on behalf of
            the bus operator after the successful reservation.
        </p>
        <p>
            Cancelation of booking is only available before 24 hours of scheduled time.
        </p>
        <p>
            Successful cancellation – Passengers who have got successful seat reservations and informed
            easytravel hotline of 0777-577577 before 24 hours of bus schedule time regarding seat reservation
            cancelation and who have got an official email from easytravel team with respect to cancelation of seat
            reservation
        </p>
        <p>
            If the passenger has got a successful cancelation for seat reservation such passenger shall be refunded
            amount paid deducting the banking charges and service charge within two working days to a
            passenger’s Bank account which details have been provided in writing via email or letter.
        </p>
        <p>
            In case of not receiving SMS or email due to a Passenger’s email or phone inaccessibility or network
            issue but reservation is successful and payment is deducted from your account easytravel will consider
            that as a reserved ticket.
        </p>

        <h3>What is easytravel role in bus seat reservation?</h3>
        <ul>
            <li>Easytravel system will play an intermediate role only in passenger and transport service.</li>
            <li>Easytravel system will display bus schedules and seat availability for reservation. Passengers will be able to reserve a seat and make an online payment to confirm his or her reservation.</li>
            <li>Easy travel application will notify passenger and the bus operator on reservation with requested seat details with a SMS, email or may be through another convenient way.</li>
            <li>It is a bus operator’s responsibility to facilitate passenger requirements inside the bus as equal to the other passengers.</li>
            <li>Easy travel team shall not guarantee any special benefits for seat reserved passengers</li>
            <li>Further due to any operational or unavoidable circumstances if a passenger does not get a seat
                and if he does not travel, such passenger can make a complaint to 0777577577 or email to
                easytravel team and easytravel team will refund his or her money within two working days. </li>
            <li>If there is a bus breakdown in the midst of a journey, a passenger will receive a refund subject to deduction of banking charges and convenient fee amounts. </li>
            <li>It is purely the passenger’s discretion to select date, time, bus, bus service type etc.,</li>
        </ul>

        <h3>Easytravel responsibilities</h3>
        <ul>
            <li>We give our best efforts to provide information on Bus fares in two sections in many routes.</li>
            <li>Even after the reservation, due to any issue if the passenger does not get a seat passengers are
                kindly requested to inform easytravel team through reservation hotline 0777-577577</li>
            <li>Easytravel team will immediately make their best efforts to reserve a seat on behalf of a
                passenger</li>
            <li>Failing which easytravel team shall guarantee a full refund</li>
            <li>Easytravel team will have two hotline telephone numbers as 0777577577 for booking and
                0777440055 for other transport information support and suggestion.</li>
        </ul>

        <h3>Easytravel team will not responsible for the followings,</h3>
        <ul>
            <li>Facilities of the bus and condition of the bus</li>
            <li>Behaviour of employees of bus or passengers who are traveling</li>
            <li>Time taken to complete the trip and delays</li>
            <li>Bus dispatching time from the bus stand or delays</li>
            <li>Due to any unavoidable reason bus operator cancelling the trip</li>
            <li>Easytravel will not be responsible for Passenger’s loss or damage to items.</li>
            <li>Changes of the bus/ changes or boarding places/ changing facilities of bus</li>
            <li>Changing seat numbers or seat to facilitate other customer by bus operators</li>
            <li>Bus stop for any other activity while bus is traveling</li>
            <li>Reckless Driving by the Bus Driver.</li>
        </ul>
    </div>);
}

export default TermsAndConditions;