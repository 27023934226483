import React from 'react';
import Form from 'react-bootstrap/Form';
import ReactDatePicker from 'react-datepicker';

import './Schedules.css';


function ControlPane(props) {
    return (
        <div className='owner-control-pane'>
            <div className='container-title'>
                <div>SCHEDULES</div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <ReactDatePicker
                        className='form-control travel-date-picker'
                        dateFormat="yyyy-MM-dd"
                        title='travel-date'
                        selected={props.travelDate}
                        onChange={props.onTravelDateChange} />
                </div>
                <div className='col-6'>
                    <Form.Control
                        className='bus-filter'
                        type='input'
                        placeholder='Bus No...'
                        title='reg-no'
                        value={props.busNoFilter}
                        onChange={props.onBusNoFilterChange} />
                </div>
            </div>
        </div>
    );
}


function OwnerSchedule(props) {
    let fromCity = (props.schedule.direction === "UP") ? props.schedule.route.sections[0].section.nameEn : props.schedule.route.sections[props.schedule.route.sections.length-1].section.nameEn;
    let toCity = (props.schedule.direction === "UP") ? props.schedule.route.sections[props.schedule.route.sections.length-1].section.nameEn : props.schedule.route.sections[0].section.nameEn;
    return (
        <div className='col-md-4'>
            <div className='owner-schedule' onClick={(e) => props.onClick(props.schedule.id)} >
                <div className='owner-schedule-header'>{props.schedule.bus.regNo} : {props.schedule.route.serviceType.replace("_", " ")}</div>
                <div className='owner-schedule-body row'>
                    <div className='col-12 bus-name'>{props.schedule.bus.name}</div>
                    <div className='col-2'>Route:</div>  <div className='col-10'>{props.schedule.route.code + " | " + props.schedule.route.nameEn}</div>
                    <div className='col-2'>From:</div>  <div className='col-10'>{fromCity}</div>
                    <div className='col-2'>To:</div>  <div className='col-10'>{toCity}</div>
                    <div className='col-2'>Time:</div>   <div className='col-10'><b>{props.schedule.time.substring(0, 5)}</b></div>
                </div>
            </div>

        </div>
    );
}

function Schedules(props) {
    if (props.schedules) {
        // Sort by departure time
        props.schedules.sort((a,b) => new Date("1970-01-01T" + a.time) - new Date("1970-01-01T" + b.time));
        return (
            <div>
                <ControlPane
                    busNoFilter={props.busNoFilter}
                    onBusNoFilterChange={props.onBusNoFilterChange}
                    travelDate={props.travelDate}
                    onTravelDateChange={props.onTravelDateChange} />
                <div className='row'>
                    {props.schedules.map(x => <OwnerSchedule schedule={x} onClick={(e) => props.onScheduleClick(x.id)} key={x.id} />)}
                </div>
            </div>
        );
    } else {
        return (<>ERROR: No Schedules defined!</>);
    }
}

export default Schedules;