import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AlertBox from '../../components/AlertBox';

import './Settings.css';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertShow: false,
            alertTitle: "",
            alertMessage: "",
            // settings
            id: null,
            teamSmsNo: "",
            sendSmsToTeam: false,
            sendSmsToOwner: false,
            sendSmsToBus: false
        };
    }

    componentDidMount() {
        this.getSettings();
    }

    getSettings() {
        fetch("/admin/settings", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json()).then((json) => {
            this.setState({
                id: json.id,
                teamSmsNo: json.teamSmsNo,
                sendSmsToTeam: json.sendSmsToTeam,
                sendSmsToOwner: json.sendSmsToOwner,
                sendSmsToBus: json.sendSmsToBus
            });
        }).catch(err => {
            console.error("SETTINGS TAB: " + err);
            this.setState({ alertShow: true, alertTitle: "Settings Error", alertMessage: 'Failed to fetch data.' });
        })
    }

    saveSettings() {
        const regex = /7\d{8}/g;
        if (!regex.test(this.state.teamSmsNo.slice(-9))) {
            this.setState({ alertShow: true, alertTitle: "Warning", alertMessage: "Please check Team Mbile No." });
            return;
        }
        let data = {
            id: this.state.id,
            teamSmsNo: this.state.teamSmsNo.slice(-9),
            sendSmsToTeam: this.state.sendSmsToTeam,
            sendSmsToOwner: this.state.sendSmsToOwner,
            sendSmsToBus: this.state.sendSmsToBus
        };
        fetch("/admin/settings", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then((res) => {
            if (res.ok) {
                this.setState({ alertShow: true, alertTitle: "Successful", alertMessage: "Saved!" });
                setTimeout(() => {this.getSettings()}, 1000);
            } else {
                res.json().then(json => this.setState({ alertShow: true, alertTitle: "Alert", alertMessage: json.message }));
            }
        }).catch(err => {
            this.setState({ alertShow: true, alertTitle: "Settings Error", alertMessage: "Failed to Save Settings. (" + err.message + ")" });
        });
    }

    onSave = (e) => {
        this.saveSettings();
    }

    onReload = (e) => {
        this.getSettings();
    }

    getSetting = (name) => {
        return "";
    }

    render() {
        return (
            <div className=''>
                <div className='container-title'>Settings</div>
                <AlertBox
                    show={this.state.alertShow}
                    key={this.state.alertShow}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    onHide={(e) => this.setState({ alertShow: false })} />

                <Form className='settings-form'>
                    <Form.Group controlId='settings-modal-team-sms' as={Row} style={{ display: "flex", marginTop: "20px" }}>
                        <Form.Label column sm="3">Team SMS Number:</Form.Label>
                        <Col sm="9">
                            <Form.Control type='text' value={this.state.teamSmsNo} onChange={(e) => this.setState({ teamSmsNo: e.target.value })} placeholder='must be 9 digits starting with a 7' />
                        </Col>
                    </Form.Group>

                    <Form.Group controlId='settings-modal-send-team-sms-flag' as={Row} style={{ display: "flex", marginTop: "20px" }}>
                        <Form.Label column sm="3"></Form.Label>
                        <Col sm="9">
                            <Form.Check type='checkbox' label="Send SMS to Team" checked={this.state.sendSmsToTeam} onChange={(e) => this.setState({ sendSmsToTeam: e.target.checked })} />
                        </Col>
                    </Form.Group>

                    <Form.Group controlId='settings-modal-send-owner-sms-flag' as={Row} style={{ display: "flex", marginTop: "20px" }}>
                        <Form.Label column sm="3"></Form.Label>
                        <Col sm="9">
                            <Form.Check type='checkbox' label="Send SMS to Owner" checked={this.state.sendSmsToOwner} onChange={(e) => this.setState({ sendSmsToOwner: e.target.checked })} />
                        </Col>
                    </Form.Group>

                    <Form.Group controlId='settings-modal-send-bus-sms-flag' as={Row} style={{ display: "flex", marginTop: "20px" }}>
                        <Form.Label column sm="3"></Form.Label>
                        <Col sm="9">
                            <Form.Check type='checkbox' label="Send SMS to Bus" checked={this.state.sendSmsToBus} onChange={(e) => this.setState({ sendSmsToBus: e.target.checked })} />
                        </Col>
                    </Form.Group>

                    <Form.Group controlId='settings-modal-buttons' as={Row} style={{ display: "flex", marginTop: "20px" }}>
                        <Col sm="6" className='text-center'>
                            <Button variant='success' className='settings-button' onClick={this.onSave} >Save</Button>
                        </Col>
                        <Col sm="6" className='text-center'>
                            <Button variant='dark' className='settings-button' onClick={this.onReload}>Reload</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        );
    }
}

export default Settings;

