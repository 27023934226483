import React from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { PencilSquare } from 'react-bootstrap-icons';
import { Clock } from 'react-bootstrap-icons';
import { List } from 'react-bootstrap-icons';
import { SlashCircleFill } from 'react-bootstrap-icons';
import TimetableModal from './TimetableModal';
import DataTable from 'react-data-table-component';
import RouteModalEn from './RouteModalEn';

import './Routes.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';


// TODO: Use AlertBox instead of JavaScript alert message box.

/**
 * Renders a list of Route items and their manipulation opeartions.
 */
class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceTypes: [],
            routes: [],
            serviceType: "ALL",
            route: undefined,
            sectionCount: 0,
            showModal: "",
            keyModal: 100000,
            codeFilter: "",
            routeNameFilter: "",
            sectionNameFilter: ""
        };
    }

    componentDidMount() {
        this.getRoutes();
        this.getServiceTypes();
    }

    // Populate the routes[] state.
    getRoutes = () => {
        fetch("/admin/route/",{ 
            headers: { 'Content-Type': 'application/json' } 
        }).then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                routes: json
            });
        }).catch(err => {
            console.error(err.message + ": loading routes.");
        })
    }

    getServiceTypes = () => {
        fetch("/booking/servicetypes", { 
            headers: { 'Content-Type': 'application/json' }
        })
        .then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                serviceTypes: json
            });
        }).catch(err => {
            alert("Failed to get service types!:" + err.message);
        });
    }

    onAddButtonClick = () => {
        this.setState({
            route: undefined,
            showModal: "En",
            keyModal: (this.state.keyModal + 1)
        });
    }

    onAddRouteComplete = () => {
        /* Reload the routes and hide the Dialog */
        this.getRoutes();
        this.setState({
            route: undefined,
            showModal: ""
        });
    }

    onTimetableClose = () => {
        this.getRoutes();
        this.setState({
            route: undefined,
            showModal: "",
            keyModal: (this.state.keyModal + 1)
        });
    }

    onTimetable = (route) => {
        this.setState({
            route: route,
            showModal: "Timetable",
            keyModal: (this.state.keyModal + 1)
        });
    }

    onChange = (route) => {
        this.setState({
            route: route,
            showModal: "En",
            keyModal: (this.state.keyModal + 1)
        });
    }

    onDelete = (route) => {
        fetch("/admin/route/delete/" + route.id, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.ok) {
                this.getRoutes();
            } else {
                res.json().then(json => alert(json.message));
            }
        });
    }

    render() {
        const columns = [
            { name: 'Route Code', cell: (row, index, column, id) => { return (<div className={row.serviceType + " service-type"}>{row.code}</div>); }, sortable: true, width: "150px" },
            { name: 'Type', selector: row => row.serviceType.replace("_", " "), hide: "md", sortable: true, width: "150px" },
            { name: 'Name', selector: row => row.nameEn, sortable: true },
            { name: 'Name', selector: row => row.nameSn, hide: "md" },
            { name: 'Fare', selector: row => row.fare, center: true, hide: "md", width: "100px" },
            { name: 'Distance', selector: row => row.distance, center: true, hide: "md", width: "100px" },
            { name: 'Sections', selector: row => row.sections.length, center: true, hide: "md", width: "100px" },
            {
                name: '', cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onChange(row)}><PencilSquare /> Edit </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#" onClick={(e) => this.onTimetable(row)}><Clock /> Timetable </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onDelete(row)}><SlashCircleFill /> Disable </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                },
                width: "60px"
            }
        ];
        let routes = this.state.routes;
        if (this.state.codeFilter !== "") {
            routes = routes.filter(x => x.code.toUpperCase().includes(this.state.codeFilter.toUpperCase()));
        }
        if (this.state.routeNameFilter !== "") {
            routes = routes.filter(x => x.nameEn.toUpperCase().includes(this.state.routeNameFilter.toUpperCase()));
        }
        if (this.state.sectionNameFilter !== "") {
            routes = routes.filter(r => {
                let secs = r.sections.filter(s => s.section.nameEn.toUpperCase() === this.state.sectionNameFilter.toUpperCase());
                return (secs.length > 0);
            });
        }
        if (this.state.serviceType !== "ALL") {
            routes = routes.filter(r => r.serviceType === this.state.serviceType.replaceAll(" ", "_"));
        }
        if (this.state.sectionCount > 1) {
            let count = parseInt(this.state.sectionCount);
            routes = routes.filter(r => r.sections.length === count);
        }
        return (
            <div className='admin-container'>
                <div className='container-title'>Routes</div>
                <div className='row'>
                    <div className='col-md-1'>
                        <Form.Control
                            type='input'
                            placeholder='Route Code filter...'
                            value={this.state.codeFilter}
                            onChange={(e) => this.setState({ codeFilter: e.target.value })}
                        />

                    </div>
                    <div className='col-md-3'>
                        <Form.Control
                            type='input'
                            placeholder='Route Name filter...'
                            value={this.state.routeNameFilter}
                            onChange={(e) => this.setState({ routeNameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Form.Control
                            type='input'
                            placeholder='Section Name filter...'
                            value={this.state.sectionNameFilter}
                            onChange={(e) => this.setState({ sectionNameFilter: e.target.value })}
                        />
                    </div>
                    <div className='col-md-2'>
                        <Form.Select value={this.state.serviceType} onChange={(e) => this.setState({ serviceType: e.target.value })} >
                            <option>ALL</option>
                            {this.state.serviceTypes.map(x => <option value={x} key={x}>{x}</option>)}
                        </Form.Select>
                    </div>
                    <div className='col-md-1'>
                        <Form.Control
                            type='number'
                            placeholder='# of sections'
                            value={this.state.sectionCount}
                            onChange={(e) => this.setState({ sectionCount: (e.target.value > 0 ? e.target.value : 0) })}
                        />
                    </div>
                    <div className='col-md-2'>
                        <Button id='add-route-btn' style={{ float: "right" }} onClick={this.onAddButtonClick} >Add Route</Button>
                    </div>
                </div>
                <div className='row'>
                    <DataTable
                        columns={columns}
                        data={routes}
                        pagination
                        striped
                    />

                </div>
                <RouteModalEn
                    key={this.state.keyModal}
                    route={this.state.route}
                    show={this.state.showModal === "En"}
                    onSuccess={this.onAddRouteComplete} />
                <TimetableModal
                    key={this.state.keyModal + 1}
                    route={this.state.route}
                    show={this.state.showModal === "Timetable"}
                    onClose={this.onTimetableClose}
                    onEditEn={this.onChange} />
            </div>
        );
    }
}

export default Routes;