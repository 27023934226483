import React from 'react';
import Owners from './owners/Owners';
import Agents from './agents/Agents';
import Buses from './buses/Buses';
import Schedules from './schedules/Schedules';
import Routes from './routes/Routes';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import SeatLayouts from './layouts/SeatLayouts';
import Sections from './sections/Sections';
import Settings from './settings/Settings';
import Discounts from './discounts/Discounts';
import Users from './users/Users';

import './AdminPanel.css';


class AdminPanel extends React.Component {
    render() {
        return (
            <Tabs defaultActiveKey="schedules">
                <Tab eventKey="schedules" title="Schedules" unmountOnExit>
                    <Schedules />
                </Tab>
                <Tab eventKey="buses" title="Buses" unmountOnExit>
                    <Buses />
                </Tab>
                <Tab eventKey="layouts" title="Seat Layouts" unmountOnExit>
                    <SeatLayouts />
                </Tab>
                <Tab eventKey="owners" title="Owners" unmountOnExit>
                    <Owners />
                </Tab>
                <Tab eventKey="agents" title="Agents" unmountOnExit>
                    <Agents />
                </Tab>
                <Tab eventKey="routes" title="Routes" unmountOnExit>
                    <Routes />
                </Tab>
                <Tab eventKey="sections" title="Sections" unmountOnExit>
                    <Sections />
                </Tab>
                <Tab eventKey="discounts" title="Discounts" unmountOnExit>
                    <Discounts />
                </Tab>
                <Tab eventKey="settings" title="Settings" unmountOnExit>
                    <Settings />
                </Tab>
                <Tab eventKey="users" title="Users" unmountOnExit>
                    <Users />
                </Tab>
            </Tabs>
        );
    }
}

export default AdminPanel;