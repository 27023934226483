import './BusModal.css'

import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

// TODO: Use AlertBox instead of JavaScript alert message box.

/**
 * Facilities:
 * AC, WiFi, Adjustable Seats, TV, Charger, Sound System
 */
class BusModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
            bus: this.props.bus,
            owners: [],
            layouts: [],
            serviceTypes: [],
            name: this.props.bus ? this.props.bus.name : "",
            regNo: this.props.bus ? this.props.bus.regNo : "",
            permitNo: this.props.bus ? this.props.bus.permitNo : "",
            mobileNo: this.props.bus ? this.props.bus.mobileNo : "",
            sendBookingAlert: this.props.bus ? this.props.bus.sendBookingAlert : false,
            owner: this.props.bus ? this.props.bus.owner.id : "",
            serviceType: this.props.bus ? this.props.bus.serviceType : "",
            seatLayout: this.props.bus ? this.props.bus.seatLayout.id : "",
            amenityAC: this.props.bus ? this.props.bus.amenityAC : false,
            amenityWiFi: this.props.bus ? this.props.bus.amenityWiFi : false,
            amenityAdjSeat: this.props.bus ? this.props.bus.amenityAdjSeat : false,
            amenityTV: this.props.bus ? this.props.bus.amenityTV : false,
            amenityCharger: this.props.bus ? this.props.bus.amenityCharger : false,
            amenitySounds: this.props.bus ? this.props.bus.amenitySounds : false
        }
    }

    componentDidMount() {
        if (this.state.show) {
            this.getOwners();
            this.getLayouts();
            this.getServiceTypes();
        }
    }

    getOwners() {
        fetch("/admin/owner", { headers: { 'Content-Type': 'application/json' } })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    owners: json
                });
            })
    }

    getLayouts() {
        fetch("/admin/layout", { headers: { 'Content-Type': 'application/json' } })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    layouts: json
                });
            })
    }

    getServiceTypes() {
        fetch("/booking/servicetypes", { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then((json) => {
                json = json.map(x => x.replaceAll(" ", "_"));
                this.setState({ serviceTypes: json });
            })
    }

    /**
     * Validate Mobile Number.
     * @param {Event Object} e 
     */
    onMobileBlur = (e) => {
        const mobilePattern = /7\d{8}/g;
        if (e.target.value.length > 9) {
            const phone = e.target.value.slice(-9);
            if (mobilePattern.test(phone)) {
                this.setState({ mobileNo: phone });
            }
        }
    }

    onSubmit = () => {
        if (this.state.regNo === "") {
            alert("Registration Number of the bus is missing.");
            return;
        }
        if (this.state.owner === "") {
            alert("Owner has not been selected.");
            return;
        }
        if (this.state.serviceType === "") {
            alert("A Service Type has not been selected.");
            return;
        }
        if (this.state.seatLayout === "") {
            alert("Seat Layout has not been selected.");
            return;
        }
        let mobileNo = this.state.mobileNo;
        if (mobileNo !== null && mobileNo.length > 0) {
            mobileNo = (mobileNo.length > 9) ? mobileNo.slice(-9) : mobileNo;
            const regex = /7\d{8}/g;
            if (!regex.test(mobileNo)) {
                alert("Please check the Mobile No.");
                return;
            }
        }
        if (this.state.sendBookingAlert && (this.state.mobileNo === null || this.state.mobileNo === "")) {
            alert("No Mobile number provided to send Alert SMS.");
            return;
        }
        const data = {
            id: this.props.bus ? this.props.bus.id : null,
            name: (this.state.name === "") ? this.state.regNo : this.state.name,
            regNo: this.state.regNo,
            permitNo: this.state.permitNo,
            mobileNo: mobileNo,
            sendBookingAlert: this.state.sendBookingAlert,
            owner: { id: this.state.owner },
            seatLayout: { id: this.state.seatLayout },
            serviceType: this.state.serviceType.replaceAll(" ", "_"),
            amenityAC: this.state.amenityAC,
            amenityWiFi: this.state.amenityWiFi,
            amenityAdjSeat: this.state.amenityAdjSeat,
            amenityTV: this.state.amenityTV,
            amenityCharger: this.state.amenityCharger,
            amenitySounds: this.state.amenitySounds
        };
        if (this.props.onAdd) {
            this.props.onAdd(data);
        }
    }

    render() {
        return (
            <Modal show={this.state.show}>
                <Modal.Header>
                    <Modal.Title>Add a Bus</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group controlId='bus-reg-no-input'>
                                    <Form.Label>Registration No:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={this.state.regNo}
                                        onChange={(e) => this.setState({ regNo: e.target.value.toUpperCase() })}></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId='bus-permit-no-input'>
                                    <Form.Label>Permit No:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={this.state.permitNo}
                                        onChange={(e) => this.setState({ permitNo: e.target.value.toUpperCase() })}></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId='bus-owner-select'>
                            <Form.Label>Owner:</Form.Label>
                            <Form.Select value={this.state.owner} onChange={(e) => this.setState({ owner: e.target.value })} >
                                <option disabled value={""}>Select an Owner...</option>
                                {this.state.owners.map(x => <option value={x.id} key={x.id}>{x.name}</option>)}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId='bus-name-input'>
                            <Form.Label>Bus Name:</Form.Label>
                            <Form.Control type='text' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}></Form.Control>
                        </Form.Group>

                        <Row>
                            <Col>
                                <Form.Group controlId='bus-mobile-no-input'>
                                    <Form.Label>Mobile No (Conductor):</Form.Label>
                                    <Form.Control type='text' value={this.state.mobileNo} onChange={(e) => this.setState({ mobileNo: e.target.value })} onBlur={this.onMobileBlur}></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId='bus-sms-alert-check'>
                                    <Form.Label>Send Booking Alert SMS:</Form.Label>
                                    <Form.Check type='checkbox' checked={this.state.sendBookingAlert} onChange={(e) => this.setState({ sendBookingAlert: e.target.checked })} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group controlId='bus-service-type-select'>
                                    <Form.Label>Bus Service Type:</Form.Label>
                                    <Form.Select value={this.state.serviceType} onChange={(e) => this.setState({ serviceType: e.target.value })} >
                                        <option disabled value={""}>Select a Service Type...</option>
                                        {this.state.serviceTypes.map(x => <option value={x} key={x}>{x.replaceAll("_", " ")}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId='bus-seat-layout-select'>
                                    <Form.Label>Seat Layout:</Form.Label>
                                    <Form.Select value={this.state.seatLayout} onChange={(e) => this.setState({ seatLayout: e.target.value })} >
                                        <option disabled value={""}>Select a seat layout...</option>
                                        {this.state.layouts.map(x => <option value={x.id} key={x.id}>{x.name}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group>
                            <Form.Label>Amenities:</Form.Label>
                            {/* AC, WiFi, Adjustable Seats, TV, Charger, Sound System */}
                            {/* amenityAC, amenityWiFi, amenityAdjSeat, amenityTV, amenityCharger, amenitySounds */}

                            <Row style={{ textAlign: "center" }}>
                                <Form.Group controlId='bus-amenity-ac-check' as={Col}>
                                    <Form.Check type='checkbox' checked={this.state.amenityAC} onChange={(e) => this.setState({ amenityAC: e.target.checked })} />
                                    <Form.Label>AC</Form.Label>
                                </Form.Group>
                                <Form.Group controlId='bus-amenity-wifi-check' as={Col} >
                                    <Form.Check type='checkbox' checked={this.state.amenityWiFi} onChange={(e) => this.setState({ amenityWiFi: e.target.checked })} />
                                    <Form.Label>WiFi</Form.Label>
                                </Form.Group>
                                <Form.Group controlId='bus-amenity-adjseat-check' as={Col}>
                                    <Form.Check type='checkbox' checked={this.state.amenityAdjSeat} onChange={(e) => this.setState({ amenityAdjSeat: e.target.checked })} />
                                    <Form.Label>Adjustable Seats</Form.Label>
                                </Form.Group>
                                <Form.Group controlId='bus-amenity-tv-check' as={Col}>
                                    <Form.Check type='checkbox' checked={this.state.amenityTV} onChange={(e) => this.setState({ amenityTV: e.target.checked })} />
                                    <Form.Label>TV</Form.Label>
                                </Form.Group>
                                <Form.Group controlId='bus-amenity-charger-check' as={Col}>
                                    <Form.Check type='checkbox' checked={this.state.amenityCharger} onChange={(e) => this.setState({ amenityCharger: e.target.checked })} />
                                    <Form.Label>Charger</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} controlId='bus-amenity-sounds-check'>
                                    <Form.Check type='checkbox' checked={this.state.amenitySounds} onChange={(e) => this.setState({ amenitySounds: e.target.checked })} />
                                    <Form.Label>Sound System</Form.Label>
                                </Form.Group>
                            </Row>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button id='add-bus-submit-btn' variant="success" type="button" onClick={this.onSubmit}>Submit</Button>&nbsp;
                        <Button                     variant="dark" type="button" onClick={this.props.onCancel}>Cancel</Button>
                    </Modal.Footer>
                </Form >
            </Modal >
        );
    }
}


export default BusModal;
