import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import Seating from '../components/Seating';
import { SEATTYPE } from '../components/Seating';

import './CustomerInfo.css';

const MAX_SEATS_PER_CUSTOMER = 6;


/**
 * 
 * 
 * Properties:
 * onComplete   - callback routine when CustomInfo collection is complete.
 * schedule     - Schedule to be used for the booking.
 * travelDate   - Customer's desired travel date.
 */
class CustomerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            message: null,
            key: 0,
            layout: [], // seat layout to use in seat selection.
            seats: [],  // selected seats list
            firstName: "",
            lastName: "",
            gender: "",
            mobile: "",
            email: "",
            nic: "",
            termsAndConditions: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.updateSeatLayout();
    }

    /**
     * Converts the Seat Layout into a JSON object (it's typically in string format)
     * and marks the seats that are already occupied.
     */
    updateSeatLayout() {
        if (!this.props.schedule || !this.props.travelDate)
            return;

        let unavailable = null;
        let layout = JSON.parse(this.props.schedule.bus.seatLayout.layout);
        let dateStr = this.props.travelDate.getFullYear() + "-" + (this.props.travelDate.getMonth() + 1) + "-" + this.props.travelDate.getDate();
        fetch("/booking/getunavailable?scheduleId=" + this.props.schedule.id + "&travelDate=" + dateStr, {
            headers: { 'Content-Type': 'application/json' },
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("ERROR Loading data...");
            }
        }).then(json => {
            unavailable = json;
            layout = layout.map(x => {
                x = x.map(y => {
                    if (unavailable.includes(y.content)) {
                        y.type = SEATTYPE.seatuna;
                    }
                    return y;
                });
                return x;
            });
            this.setState({
                key: (this.state.key + 1),
                unavailable: unavailable,
                layout: layout
            });
        }).catch(err => {
            console.error(err);
            alert("Data Load ERROR 1");
        });
    }

    /**
     * Callback whenever the seat selection has changed; updates the layout by marking
     * the new selection state and updates the selected-seats list in 'state'.
     * @param {Array} seat 
     */
    onSeatSelectionChange = (seat) => {
        let layout = this.state.layout.map(x => x.map(y => ({ ...y }))); // clone the 'layout' 
        layout.filter(x => {
            x = x.filter(y => {
                if (y.content === seat) {
                    if (y.type === SEATTYPE.seatava) {
                        y.type = SEATTYPE.seatsel;
                    } else if (y.type === SEATTYPE.seatsel) {
                        y.type = SEATTYPE.seatava;
                    }
                }
                return y;
            })
            return x;
        });
        let seats = [];
        layout.forEach((x, i) =>
            x.forEach((y, j) => {
                if (y.type === SEATTYPE.seatsel) {
                    seats.push(y.content);
                }
            })
        );
        if (seats.length > MAX_SEATS_PER_CUSTOMER) {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "You have reached the maximum number of seats allowed per booking.",
            });
            return;
        }
        this.setState({
            key: (this.state.key + 1),
            layout: layout,
            seats: seats
        });
    }

    onComplete = () => {
        if (this.state.termsAndConditions !== "on") {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "You must agree to the Terms and Conditions before proceeding.",
            });
            return;
        }
        if (this.state.firstName === "") {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "Please check First Name.",
            });
            return;
        }
        if (this.state.lastName === "") {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "Please check Last Name.",
            });
            return;
        }
        if (this.state.gender === "") {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "Please check Gender.",
            });
            return;
        }
        if (this.state.mobile.length < 9) {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "Please check Mobile Number.",
            });
            return;
        }
        if (this.state.email === "") {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "Please check Email.",
            });
            return;
        }
        if (this.state.nic === "") {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "Please check NIC Number.",
            });
            return;
        }
        if (this.state.seats.length === 0) {
            this.setState({
                key: (this.state.key + 1),
                showAlert: true,
                message: "You have not selected any seats.",
            });
            return;
        }
        if (this.props.onComplete !== undefined) {
            this.props.onComplete({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                gender: this.state.gender,
                mobile: this.state.mobile,
                email: this.state.email,
                nic: this.state.nic,
                seats: this.state.seats
            });
        }
    }

    render() {
        return (
            <div className='customer-info-component'>
                <div className='row'>
                    <div className='col-md-1'>&nbsp;</div>
                    <div className='col-md-5'>
                        <h3>SEATING</h3>
                        <Seating
                            key={this.state.key}
                            onSeatClick={this.onSeatSelectionChange}
                            layout={this.state.layout}
                            seats={this.state.seats}
                        />
                    </div>
                    <div className='col-md-5'>
                        <h3>PERSONAL DETAILS</h3>
                        <InfoForm
                            key={this.state.key}
                            seats={this.state.seats}
                            seatPrice={this.props.schedule ? this.props.schedule.fullFare : 0}
                            buttonText="Book Seats"
                            onComplete={this.onComplete}

                            firstName={this.state.firstName}
                            onFirstNameChange={(e) => this.setState({ firstName: e.target.value })}
                            lastName={this.state.lastName}
                            onLastNameChange={(e) => this.setState({ lastName: e.target.value })}
                            gender={this.state.gender}
                            onGenderChange={(e) => this.setState({ gender: e.target.value })}
                            mobile={this.state.mobile}
                            onMobileChange={(e) => this.setState({ mobile: e.target.value })}
                            email={this.state.email}
                            onEmailChange={(e) => this.setState({ email: e.target.value })}
                            nic={this.state.nic}
                            onNicChange={(e) => this.setState({ nic: e.target.value })}
                            termsAndConditions={this.state.termsAndConditions}
                            onTermsAndConditionsChanged={(e) => this.setState({ termsAndConditions: e.target.value })}

                        />
                    </div>
                    <div className='col-md-1'>&nbsp;</div>
                </div>

                <Modal show={this.state.showAlert}>
                    <Modal.Header>
                        <Modal.Title>ALERT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <i>{this.state.message}</i>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={(e) => this.setState({ showAlert: false, message: null })}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}


export default CustomerInfo;


/**
 * Properties:
 * key          ={this.state.key}
 * seats        ={this.state.seats}
 * seatPrice    ={this.props.schedule ? this.props.schedule.fullFare : 0}
 * buttonText   ="Book Seats"
 * onComplete   ={this.onComplete}
 * firstName    ={this.state.firstName}
 * lastName     ={this.state.lastName}
 * gender       ={this.state.gender}
 * mobile       ={this.state.mobile}
 * email        ={this.state.email}
 * nic          ={this.state.nic}
 * onFirstNameChange={(e) => this.setState({firstName : e.target.value})}
 * onLastNameChange={(e) => this.setState({lastName : e.target.value})}
 * onGenderChange={(e) => this.setState({gender : e.target.value})}
 * onMobileChange={(e) => this.setState({mobile : e.target.value})}
 * onEmailChange={(e) => this.setState({email : e.target.value})}
 * onNicChange={(e) => this.setState({nic : e.target.value})}
 * onTermsAndConditionsChanged={(e) => this.setState({termsAndConditions : e.target.value})}
 */
class InfoForm extends React.Component {

    onInfoSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.checkValidity() === false) {
            return;
        }
        if (this.props.onComplete !== undefined) {
            this.props.onComplete();
        }
    }

    render() {
        return (
            <div className='info-form'>
                <div className='info-form-card'>
                    <Alert>Please mark <b>seat(s)</b> to reserve in the seating layout and provide your <b>contact information</b> below.<br /></Alert>
                    <Form onSubmit={this.onInfoSubmit}>

                        <Form.Group controlId='customerSeats' as={Row}>
                            <Col sm="3"><Form.Label>Selected Seats:</Form.Label></Col>
                            <Col>{this.props.seats.map(x => <div key={x} className='seat'>{x}</div>)}</Col>
                        </Form.Group>

                        <Form.Group controlId='subTotalAmount' as={Row} >
                            <Col sm="3"><Form.Label>Amount: <br /><br /></Form.Label></Col>
                            <Col>Rs. <b>{this.props.seatPrice * this.props.seats.length}.00</b> plus fees...</Col>
                        </Form.Group>

                        <Form.Group controlId='subTotalAmount' as={Row} >
                            <Col sm="3"><Form.Label>Mobile No:</Form.Label></Col>
                            <Col><Form.Control as='input' type='phone' value={this.props.mobile} required onChange={this.props.onMobileChange} /></Col>
                        </Form.Group>

                        <Form.Group controlId='customerEmail' as={Row} >
                            <Col sm="3"><Form.Label>Email:</Form.Label></Col>
                            <Col><Form.Control as='input' type='text' value={this.props.email} required onChange={this.props.onEmailChange} /></Col>
                        </Form.Group>

                        <Form.Group controlId='customerNic' as={Row} >
                            <Col sm="3"><Form.Label>NIC/PP/DL:</Form.Label></Col>
                            <Col><Form.Control as='input' type='text' value={this.props.nic} required onChange={this.props.onNicChange} /></Col>
                        </Form.Group>

                        <Form.Group controlId='customerFirstName' as={Row} >
                            <Col sm="3"><Form.Label>First Name:</Form.Label></Col>
                            <Col>
                                <Form.Control as='input' type='text' value={this.props.firstName} required onChange={this.props.onFirstNameChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='customerLastName' as={Row} >
                            <Col sm="3"><Form.Label>Last Name:</Form.Label></Col>
                            <Col>
                                <Form.Control as='input' type='text' value={this.props.lastName} required onChange={this.props.onLastNameChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='gender' as={Row}>
                            <Col sm="3"><Form.Label>Gender:</Form.Label></Col>
                            <Col>
                                <Form.Check
                                    inline
                                    required
                                    type="radio"
                                    checked={this.props.gender === "MALE"}
                                    name="type"
                                    value="MALE"
                                    label="Male"
                                    onChange={this.props.onGenderChange} />
                                <Form.Check
                                    inline
                                    required
                                    type="radio"
                                    checked={this.props.gender === "FEMALE"}
                                    name="type"
                                    value="FEMALE"
                                    label="Female"
                                    onChange={this.props.onGenderChange} />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='customerinfo-modal-terms-and-conditions' as={Row}>
                            <Col sm="3"><Form.Label>Terms & Conditions:</Form.Label></Col>
                            <Col>
                                <Form.Check
                                    type='checkbox'
                                    className='terms-and-conditions'
                                    required
                                    label="By clicking here, I state that I have read and understood the Terms and Conditions."
                                    feedback="Please read and agree to Terms and Conditions to proceed."
                                    onChange={this.props.onTermsAndConditionsChanged}
                                /><a className='see-terms' href='/terms' target='_black'>See Terms and Conditions</a>
                            </Col>
                        </Form.Group>

                        <Form.Group controlId='submitButton' as={Row}>
                            <Col><center><Button type='submit' id='purchase-button'>{this.props.buttonText}</Button></center></Col>
                        </Form.Group>
                    </Form>
                </div>
            </div >
        );
    }
}