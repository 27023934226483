import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { XCircleFill } from 'react-bootstrap-icons';
import { ArrowDownCircle } from 'react-bootstrap-icons';
import { ArrowUpCircle } from 'react-bootstrap-icons';
import './RouteModalEn.css';

// TODO: Use AlertBox instead of JavaScript alert message box.

class RouteModalEn extends React.Component {
    constructor(props) {
        super(props);
        this.addSectionRef = React.createRef();
        this.state = {
            show: props.show,
            allServiceTypes: [],
            allSections: [],
            selectedSection: -1,
            allRoutes: [],
            selectedRoute: -1,
            ///////////////////////////////////////////////////////////////////
            code: "",
            nameEn: "",
            nameSn: "",
            nameTa: "",
            via: "",
            serviceType: "NORMAL",
            fare: "",
            distance: "",
            sections: [],
            times: [],
            active: true
        };
    }


    componentDidMount() {
        if (this.state.show) {
            this.getSections();
            this.getServiceTypes();
            this.getRoutes();
            if (this.props.route) {
                this.setState({
                    show: this.props.show,
                    allServiceTypes: [],
                    allSections: [],
                    selectedSection: -1,
                    allRoutes: [],
                    selectedRoute: -1,
                    ///////////////////////////////////////////////////////////////////
                    code: this.props.route.code,
                    nameEn: this.props.route.nameEn,
                    nameSn: this.props.route.nameSn,
                    nameTa: this.props.route.nameTa,
                    via: this.props.route.via,
                    serviceType: this.props.route.serviceType ? this.props.route.serviceType.replaceAll("_", " ") : "NORMAL",
                    fare: this.props.route.fare,
                    distance: this.props.route.distance,
                    sections: [...this.props.route.sections],
                    times: [...this.props.route.times],
                    active: this.props.route.active
                });
            }
        }
    }

    getSections() {
        fetch("/admin/section",
            { headers: { 'Content-Type': 'application/json' } }
        ).then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                allSections: json
            });
        });
    }

    getServiceTypes() {
        fetch("/booking/servicetypes",
            { headers: { 'Content-Type': 'application/json' } }
        ).then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                allServiceTypes: json
            });
        }).catch(err => {
            alert("Route (" + (new Error().lineNumber) + "):" + err.message);
        });
    }

    getRoutes() {
        fetch("/admin/route",
            { headers: { 'Content-Type': 'application/json' } }
        ).then(
            (res) => res.json()
        ).then((json) => {
            this.setState({
                allRoutes: json
            });
        }).catch(err => {
            alert("Route (" + (new Error().lineNumber) + "):" + err.message);
        });
    }

    onSubmit = () => {
        if (this.state.code === null) {
            alert("Route Code has not been provided.");
            return;
        }
        if (this.state.sections.size < 2) {
            alert("At least 2 sections must be provided.");
            return;
        }
        const sections = this.state.sections.map(x => {
            return {
                ntcIndex: x.ntcIndex,
                section: {
                    nameEn: x.section.nameEn,
                    nameSn: x.section.nameSn,
                    nameTa: x.section.nameTa,
                }
            };
        });

        // TODO: Make sure there are no name duplicates or NTCIndex duplicates in the sections list.
        const duplicateNames = [];
        sections.forEach((e1, i) => {
            sections.forEach((e2, j) => {
                if ((e1.section.nameEn === e2.section.nameEn) && (i !== j)) {
                    duplicateNames.push(e1.section.nameEn);
                }
            });
        });
        if (duplicateNames.length > 0) {
            alert("Duplicate section names: " + duplicateNames.join(","));
            return;
        }

        const duplicateNtcIndexes = [];
        sections.forEach((e1, i) => {
            sections.forEach((e2, j) => {
                if ((parseInt(e1.ntcIndex) === parseInt(e2.ntcIndex)) && (i !== j)) {
                    duplicateNtcIndexes.push(e1.ntcIndex);
                }
            });
        });
        if (duplicateNtcIndexes.length > 0) {
            alert("Duplicate NtcIndexes: " + duplicateNtcIndexes.join(","));
            return;
        }


        const data = {
            id: (this.props.route ? this.props.route.id : null),
            code: this.state.code,
            nameEn: (this.state.nameEn === "") ? null : this.state.nameEn,
            nameSn: (this.state.nameSn === "") ? null : this.state.nameSn,
            nameTa: (this.state.nameTa === "") ? null : this.state.nameTa,
            via: (this.state.via === null) ? null : this.state.via.trim(),
            serviceType: this.state.serviceType ? this.state.serviceType.replaceAll(" ", "_") : null,
            fare: this.state.fare,
            distance: this.state.distance,
            sections: sections,
            times: this.state.times,
            active: this.state.active
        };

        fetch("/admin/route", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then((res) => {
            if (res.ok) {
                if (this.props.onSuccess !== undefined) {
                    this.props.onSuccess();
                }
            } else {
                res.json().then(json => alert(json.message));
            }
        }).catch(err => {
            console.error(err.message + ": loading routes.");
        });
        this.setState({ show: false });
    }

    /**
     * 
     */
    onCancel = () => {
        if (this.state.selectedSection < 0) {
            this.setState({
                show: false,
                selectedSection: -1,
                allServiceTypes: [],
                allSections: [],
                ///////////////////////////////////////////////////////////////////
                code: "",
                nameEn: "",
                nameSn: "",
                nameTa: "",
                via: "",
                serviceType: "NORMAL",
                fare: "",
                distance: "",
                sections: [],
                times: [],
                active: true
            });
        } else {
            this.setState({ selectedSection: -1 });
        }
    }

    /**
     *
     * @param {Name of the section to be added to the list} sectionName
     * @returns
     */
    addSection(sectionName) {
        // Check if the section is already in the route...
        if (this.state.sections.filter(x => x.section.nameEn === sectionName).length > 0) {
            alert("Section is already added.");
            return;
        }
        const dbSections = this.state.allSections.filter(x => x.nameEn === sectionName);
        let sections = this.state.sections;
        sections.push({
            ntcIndex: (this.state.sections.length === 0) ? 0 : parseInt(this.state.sections[this.state.sections.length - 1].ntcIndex) + 1,
            section: {
                id: (dbSections.length === 1) ? dbSections[0].id : sectionName + "_ID",
                nameEn: (dbSections.length === 1) ? dbSections[0].nameEn : sectionName
            }
        });
        this.setState({ sections: this.state.sections });
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.addSection(this.addSectionRef.current.getInput().value);
            this.addSectionRef.current.clear();
        }
    }

    onSectionSelect = (selected) => {
        if (this.state.sections.filter(x => x.section.nameEn === selected[0]).length > 0) {
            alert("'" + selected[0].nameEn + "' already exists.");
        } else {
            this.addSection(selected[0].nameEn);
        }
    }

    appendSections = (selected) => {
        if (selected.length > 0) {
            let sections = new Set(this.state.sections);
            selected[0].sections.forEach(x => {
                sections.add(x);
            });
            this.setState({ appendRouteModalShow: false, sections: Array.from(sections) });
        } else {
            this.setState({ appendRouteModalShow: false });
        }
    }

    reverseSections = () => {
        let sections = [];
        for (let i = this.state.sections.length - 1; i >= 0; i--) {
            sections.push(this.state.sections[i]);
        }
        this.setState({ sections: sections });
    }

    reindexSections = () => {
        let sections = this.state.sections;
        for (let i = 0; i < sections.length; i++) {
            sections[i].ntcIndex = i;
        }
        this.setState({ sections: sections });
    }

    clearSections = () => {
        this.setState({ sections: [] });
    }

    onMoveUpSection = (e, index) => {
        let moveTo = index - 1;
        if (e.ctrlKey) {
            let moveBy = parseInt(prompt("Give number of positions to move:", "1"));
            moveTo = index - moveBy;
        }
        moveTo = (moveTo < 0) ? 0 : moveTo;
        let sections = this.state.sections;
        let target = sections[index];
        for (let i = index; i > moveTo; i--) {
            sections[i] = sections[i - 1];
        }
        sections[moveTo] = target;
        this.setState({ sections: sections });
    }

    onMoveDownSection = (e, index) => {
        let moveTo = index + 1;
        if (e.ctrlKey) {
            let moveBy = parseInt(prompt("Give number of positions to move:", "1"));
            moveTo = index + moveBy;
        }
        moveTo = (moveTo < this.state.sections.length) ? moveTo : (this.state.sections.length - 1);
        let sections = this.state.sections;
        let target = sections[index];
        for (let i = index; i < moveTo; i++) {
            sections[i] = sections[i + 1];
        }
        sections[moveTo] = target;
        this.setState({ sections: sections });
    }

    onRemoveSection = (e, index) => {
        if (index >= 0 && index < this.state.sections.length) {
            let sections = this.state.sections;
            sections.splice(index, 1);
            this.setState({ sections: sections });
        }
    }

    onNtcIndexClicked = (index) => {
        this.setState({ selectedSection: index });
    }

    onNtcIndexChange = (index, value) => {
        if (value < 0 || value > 400) {
            alert("Invalid NTC Index: " + value);
            return;
        }
        if (index >= 0 && index < this.state.sections.length) {
            let sections = this.state.sections;
            sections[index].ntcIndex = value;
            this.setState({ sections: sections });
        }
    }

    onKeydown = (e) => {
        if (e.keyCode === 13) { // Enter pressed
            let setNext = false;
            this.state.sections.forEach((s, index) => {
                if (setNext) {
                    this.setState({ selectedSection: index });
                    setNext = false;
                } else if (index === this.state.selectedSection && !setNext) {
                    setNext = true;
                }
            });
            if (setNext) {
                this.setState({ selectedSection: -1 });
            }
        }
    }

    render() {
        return (
            <>
                <Modal show={this.state.show} backdrop='static' onHide={this.onCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>Route Details</Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Row className='mb-3'>
                                <Form.Group controlId='route-modal-code' as={Col}>
                                    <Form.Label>Route Code:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        title='Route Code'
                                        value={this.state.code}
                                        onChange={(e) => this.setState({ code: e.target.value })}
                                    />
                                </Form.Group>

                                <Form.Group controlId='route-modal-service-type' as={Col}>
                                    <Form.Label>Service Type:</Form.Label>
                                    <Form.Select id='service-type-select' value={this.state.serviceType} onChange={(e) => this.setState({ serviceType: e.target.value })} >
                                        {this.state.allServiceTypes.map(x => <option value={x} key={x}>{x}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Row>

                            <Form.Group controlId='route-modal-name-en' >
                                <Form.Label>Route Name in English:</Form.Label>
                                <Form.Control
                                    type='text'
                                    title='Route Name in English'
                                    value={this.state.nameEn ? this.state.nameEn : ""}
                                    onChange={(e) => this.setState({ nameEn: e.target.value })} />
                            </Form.Group>

                            <Form.Group controlId='route-modal-name-sn' >
                                <Form.Label>Route Name in Sinhala:</Form.Label>
                                <Form.Control
                                    type='text'
                                    title='Route Name in Sinhala'
                                    placeholder='ධාවන මාර්ගය'
                                    value={this.state.nameSn ? this.state.nameSn : ""}
                                    onChange={(e) => this.setState({ nameSn: e.target.value })} />
                            </Form.Group>

                            <Form.Group controlId='route-modal-name-ta' >
                                <Form.Label>Route Name in Tamil:</Form.Label>
                                <Form.Control
                                    type='text'
                                    title='Route Name in Tamil'
                                    value={this.state.nameTa ? this.state.nameTa : ""}
                                    onChange={(e) => this.setState({ nameTa: e.target.value })} />
                            </Form.Group>

                            <Form.Group controlId='route-modal-via' >
                                <Form.Label>Via:</Form.Label>
                                <Form.Control
                                    type='text'
                                    title='Via Sections'
                                    value={this.state.via ? this.state.via : ""}
                                    onChange={(e) => this.setState({ via: e.target.value })} />
                            </Form.Group>

                            <Row className='mb-3' key='20'>
                                <Form.Group controlId='route-modal-fare' as={Col} key='1'>
                                    <Form.Label>Fare in Rs:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        title='Full Fare'
                                        value={this.state.fare ? this.state.fare : ""}
                                        onChange={(e) => this.setState({ fare: e.target.value })} />
                                </Form.Group>

                                <Form.Group controlId='route-modal-distance' as={Col} key='2'>
                                    <Form.Label>Distance in Km:</Form.Label>
                                    <Form.Control
                                        type='text'
                                        title='Total Distance'
                                        value={this.state.distance ? this.state.distance : ""}
                                        onChange={(e) => this.setState({ distance: e.target.value })} />
                                </Form.Group>
                            </Row>

                            <Form.Group controlId='route-modal-sections' >
                                <Form.Label>
                                    Sections:
                                </Form.Label>
                                <div className='row'>
                                    <Button id='add-route-append-btn' className='col-md-3 btn-action' size='sm' onClick={(e) => this.setState({ appendRouteModalShow: true })}>Append</Button>
                                    <Button id='add-route-reverse-btn' className='col-md-3 btn-action' size="sm" variant="secondary" onClick={(e) => this.reverseSections()}>Reverse</Button>
                                    <Button id='add-route-reindex-btn' className='col-md-3 btn-action' size="sm" variant="success" onClick={(e) => this.reindexSections()}>Reindex</Button>
                                    <Button id='add-route-clear-btn' className='col-md-3 btn-action' size="sm" variant="danger" onClick={(e) => this.clearSections()}>Clear</Button>
                                </div>
                                <div id='add-route-sections-list'>

                                    {Array.from(this.state.sections).map((x, index) =>
                                        <div className='selected-sections row' key={index}>
                                            <div className='col-7'>{x.section.nameEn}</div>
                                            {
                                                (index === this.state.selectedSection)
                                                    ?
                                                    <div className='col-3'>
                                                        <Form.Control
                                                            autoFocus={true}
                                                            onFocus={(e) => e.target.select()}
                                                            type='number'
                                                            title='NTC Index'
                                                            value={x.ntcIndex}
                                                            onChange={(e) => this.onNtcIndexChange(index, e.target.value)}
                                                            onKeyDown={this.onKeydown}
                                                        />
                                                    </div>
                                                    :
                                                    <div className='col-5'>
                                                        <div className='row'>
                                                            <div className='col-6 ntc-index' onClick={(e) => this.onNtcIndexClicked(index)}>{x.ntcIndex}</div>
                                                            <div className='col-2'>
                                                                <ArrowUpCircle className='selected-section-moveup' onClick={(e) => this.onMoveUpSection(e, index)} />
                                                            </div>
                                                            <div className='col-2'>
                                                                <ArrowDownCircle className='selected-section-movedown' onClick={(e) => this.onMoveDownSection(e, index)} />
                                                            </div>
                                                            <div className='col-2'>
                                                                <XCircleFill className='selected-section-delete' onClick={(e) => this.onRemoveSection(e, index)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Typeahead
                                            id="add-section-input"
                                            placeholder='Press TAB to auto-complete...'
                                            ref={this.addSectionRef}
                                            onKeyDown={this.onKeyDown}
                                            onChange={this.onSectionSelect}
                                            options={this.state.allSections}
                                            labelKey="nameEn"
                                            selected={[]}
                                        />
                                    </div>
                                </div>
                            </Form.Group>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button id='add-route-submit-btn' title="Submit" variant="success" type="button" onClick={this.onSubmit}>Submit</Button>&nbsp;&nbsp;
                            <Button id='add-route-cancel-btn' title="Cancel" variant="dark" type="button" onClick={this.onCancel}>Cancel</Button>
                        </Modal.Footer>

                    </Form>
                </Modal>


                <Modal show={this.state.appendRouteModalShow} style={{ backgroundColor: "rgba(0,0,0,0.5)" }} onHide={() => this.setState({ appendRouteModalShow: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select a Route</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId='route-select-modal-route' >
                            <Typeahead
                                id="append-route-select"
                                placeholder='Select a Route'
                                options={this.state.allRoutes}
                                labelKey="nameEn"
                                onChange={this.appendSections}
                                selected={[]}
                            />
                        </Form.Group>
                        <br />
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}

export default RouteModalEn;
