import './TopSchedules.css'
import React from 'react';


// TODO: Use AlertBox instead of JavaScript alert message box.

class TopShedules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topschedules: []
        }
    }

    componentDidMount() {
        this.getTopRoutes();
    }

    getTopRoutes() {
        fetch("/booking/topschedules", {
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => res.json()).then((json) => {
            this.setState({
                topschedules: json
            })
        })
        .catch(error => {
            console.error("TopSchedules fetch error!  Check connection.", error);
        });
    }

    onClick = (id) => {
        const schedList = this.state.topschedules.filter(x => x.id === id);
        if(schedList.length !== 1) {
            alert("Error selecting a single Schedule from list. " + schedList);
            return;
        }
        const sched = schedList[0];
        if(sched.sections.length > 0) {
            if(this.props.onSearch) {
                this.props.onSearch(
                    {
                        fromCity: sched.sections[0].section.nameEn,
                        toCity: sched.sections[sched.sections.length-1].section.nameEn,
                        travelDate: new Date()
                    }
                );
            }
        }
    }

    render() {
        return (
            <div className='top15-container'>
                <h1 id="top15title">TOP ROUTES</h1>
                <div className='row'>
                    {this.state.topschedules.map(x =>
                        <div className='col-sm-6 col-md-4 col-lg-3' key={x.id} onClick={(e) => this.onClick(x.id)}>
                            <div className='item'>
                                <div>{x.nameEn}</div>
                                <div>{x.nameSn}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}


export default TopShedules;