import React from 'react';
import './Genie.css'
import Banner from '../components/Banner'
import Button from 'react-bootstrap/Button';

// Example URL to Invoke this page  (Must Change 'invoice_number',  May be changed: gross_amount, charge_total, trx_date_time)
// http://localhost:3000/genie?invoice_number=INV0000125&charge_total=70.36&gross_amount=70.36&trx_ref_number=00000000000000000&trx_date_time=2022-12-01%2016:53:06&message=System%20Error&code=00&status=YES&other_info=&txn_token=913017c6443ccb89e2d096cc0b490f28c3f3b6b482bcb8b1f85fed807792667c

class Genie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "NO"
        };
    }

    componentDidMount() {
        let searchParams = window.location.href.split("?")[1].split("&");
        let params = {};
        searchParams.forEach(x => {
            let keyval = decodeURIComponent(x).split("=");
            params[keyval[0]] = keyval[1];
        });
        if (params.status === "YES" && params.code === "00") {
            const complete_data = {
                invoiceNo: params.invoice_number,
                paymentRef: params.trx_ref_number
            };
            fetch("/booking/complete", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(complete_data)
            }).then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    console.error("Fetch failed....");
                }
            }).then(response => {
                this.setState({
                    status: params.status,
                    params: params,
                    response: response
                });
            }).catch(err => {
                console.error(err.message);
                this.setState({
                    status: params.status,
                    params: params,
                    errmsg: err.message
                });
            });
        } else {
            this.setState({
                status: params.status,
                params: params,
                errmsg: params.message
            });
        }
    }

    onGoBack = () => {
        window.location = "/";
    }

    render() {
        if (this.state.status === "YES" && this.state.response) {
            const rsTotal = "" + this.state.response.total;
            return (
                <>
                    <Banner />
                    <div className='receipt'>
                        <div className='receipt-title'>Payment Successful</div>
                        <div className='row receipt-details'>
                            <div className='col-md-4 label'>Invoice No:</div>
                            <div className='col-md-8 value'>{this.state.response.invoiceNo}</div>

                            <div className='col-md-4 label'>Invoice Date & Time:</div>
                            <div className='col-md-8 value'>{this.state.response.bookingDatetime}</div>

                            <div className='col-md-4 label'>Travel Date & Time:</div>
                            <div className='col-md-8 value'>{this.state.response.travelDatetime}</div>

                            <div className='col-md-4 label'>Bus Route:</div>
                            <div className='col-md-8 value'>{this.state.response.routeName}</div>

                            <div className='col-md-4 label'>Bus Name:</div>
                            <div className='col-md-8 value'>{this.state.response.busName}</div>

                            <div className='col-md-4 label'>Bus RegNo:</div>
                            <div className='col-md-8 value'>{this.state.response.busRegNo}</div>

                            <div className='col-md-4 label'>Travel:</div>
                            <div className='col-md-8 value'>{this.state.response.fromCity} to {this.state.response.toCity}</div>

                            <div className='col-md-4 label'>Seats:</div>
                            <div className='col-md-8 value'>{this.state.response.seats.map(x => <div className='seat' key={x}>{x}</div>)}</div>

                            <div className='col-md-4 label'>Customer Name:</div>
                            <div className='col-md-8 value'>{this.state.response.customerFirstName} {this.state.response.customerLastName}</div>

                            <div className='col-md-4 label'>Customer Phone:</div>
                            <div className='col-md-8 value'>{this.state.response.customerPhone}</div>

                            <div className='col-md-4 label'>Customer Email:</div>
                            <div className='col-md-8 value'>{this.state.response.customerEmail}</div>

                            <div className='col-md-4 label'>Payment Ref:</div>
                            <div className='col-md-8 value'>{this.state.response.paymentRef}</div>

                            <div className='col-md-4 total label'>Total Charge:</div>
                            <div className='col-md-8 total value'>Rs. {rsTotal.substring(0, rsTotal.length - 2) + "." + rsTotal.substring(rsTotal.length - 2)}</div>
                        </div>
                        <div class='receipt-footer row'>
                            <div class='col-md-8'>
                                Easy Travel<sup>&#174;</sup><br />
                                <small><i>Travel with ease...</i></small>
                            </div>
                            <div class='col-md-4'>
                                Tel: 0777 577 577
                            </div>
                        </div>
                        <br />
                        <Button className='back-button' onClick={this.onGoBack}>Book Another Ticket</Button>
                        <br />
                    </div>
                </>
            );
        } else if (this.state.params) {
            return (
                <>
                    <Banner />
                    <div className='receipt'>
                        <h1>Payment Failed</h1>
                        <p>Error: {this.state.errmsg}</p>
                        <p>Code: {this.state.params["code"]}</p>
                        <p>Txn Token: {this.state.params["txn_token"]}</p>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Banner />
                    <div className='receipt'>
                        <h1>Payment Failed</h1>
                        <p>Message: {this.state.errmsg}</p>
                    </div>
                </>
            );
        }
    }
}


export default Genie;