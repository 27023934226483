import './OwnerModal.css'

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// TODO: Use AlertBox instead of JavaScript alert message box.

class OwnerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            name: this.props.owner ? this.props.owner.name : "",
            nic: this.props.owner ? this.props.owner.nic : "",
            email: this.props.owner && this.props.owner.email != null ? this.props.owner.email : "",
            contactNo1: this.props.owner && this.props.owner.contactNo1 != null ? this.props.owner.contactNo1 : "",
            contactNo2: this.props.owner && this.props.owner.contactNo2 != null ? this.props.owner.contactNo2 : "",
            mobileNo: this.props.owner && this.props.owner.mobileNo != null ? this.props.owner.mobileNo : "",
            bankName: this.props.owner && this.props.owner.bankName != null ? this.props.owner.bankName : "",
            bankBranchCode: this.props.owner && this.props.owner.bankBranchCode != null ? this.props.owner.bankBranchCode : "",
            bankAccountHolderName: this.props.owner && this.props.owner.bankAccountHolderName != null ? this.props.owner.bankAccountHolderName : "",
            bankAccount: this.props.owner && this.props.owner.bankAccount != null ? this.props.owner.bankAccount : "",
            salesPerson: this.props.owner && this.props.owner.salesPerson != null ? this.props.owner.salesPerson : ""
        }
    }

    onSubmit = () => {
        if (this.state.name === "") {
            alert("Name of the Seat Layout is missing");
            return false;
        }
        if (this.state.nic === "") {
            alert("Number of Seats of the Seat Layout is missing.");
            return false;
        }
        const data = {
            name: this.state.name,
            nic: this.state.nic,
            email: (this.state.email === "") ? null : this.state.email,
            contactNo1: (this.state.contactNo1 === "") ? null : this.state.contactNo1,
            contactNo2: (this.state.contactNo2 === "") ? null : this.state.contactNo2,
            mobileNo: (this.state.mobileNo === "") ? null : this.state.mobileNo,
            bankName: (this.state.bankName === "") ? null : this.state.bankName,
            bankBranchCode: (this.state.bankBranchCode === "") ? null : this.state.bankBranchCode,
            bankAccountHolderName: (this.state.bankAccountHolderName === "") ? null : this.state.bankAccountHolderName,
            bankAccount: (this.state.bankAccount === "") ? null : this.state.bankAccount,
            salesPerson: (this.state.salesPerson === "") ? null : this.state.salesPerson
        };
        if (this.props.onSuccess) {
            this.props.onSuccess(data);
        }
    }

    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        return (
            <Modal show={this.state.show}>
                <Modal.Header>
                    <Modal.Title>Add an Owner</Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Form.Group controlId='owner-modal-name' as={Col}>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                id='owner-name-input'
                                type='text'
                                value={this.state.name} onChange={(e) => this.setState({ name: e.target.value.toUpperCase() })}></Form.Control>
                        </Form.Group>

                        <Row>
                            <Form.Group controlId='owner-modal-nic' as={Col}>
                                <Form.Label>NIC/BRC:</Form.Label>
                                <Form.Control
                                    id='owner-nic-input'
                                    type='text'
                                    value={this.state.nic}
                                    onChange={(e) => this.setState({ nic: e.target.value.toUpperCase() })}></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='owner-modal-email' as={Col}>
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    id='owner-email-input'
                                    type='text'
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value.toLowerCase() })}></Form.Control>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group controlId='owner-modal-mobile' as={Col}>
                                <Form.Label>Owner Mobile No:</Form.Label>
                                <Form.Control
                                    id='owner-mobile-input'
                                    type='text'
                                    value={this.state.contactNo1}
                                    onChange={(e) => this.setState({ contactNo1: e.target.value.replace(/[^0-9]/gi, '') })}></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='owner-modal-contact2' as={Col}>
                                <Form.Label>Contact No2:</Form.Label>
                                <Form.Control
                                    id='owner-contact2-input'
                                    type='text'
                                    value={this.state.contactNo2}
                                    onChange={(e) => this.setState({ contactNo2: e.target.value.replace(/[^0-9]/gi, '') })}></Form.Control>
                            </Form.Group>
                        </Row>

                        <Form.Group controlId='owner-modal-bank-account-holder-name' as={Col}>
                            <Form.Label>Bank Account Holder Name: <small><i>(as in the Bank Book)</i></small></Form.Label>
                            <Form.Control
                                id='owner-bank-account-holder-name-input'
                                type='text'
                                value={this.state.bankAccountHolderName}
                                onChange={(e) => this.setState({ bankAccountHolderName: e.target.value })}></Form.Control>
                        </Form.Group>

                        <Row>
                            <Form.Group controlId='owner-modal-bank-name' as={Col}>
                                <Form.Label>Bank Name:</Form.Label>
                                <Form.Control
                                    id='owner-bank-name-input'
                                    type='text'
                                    value={this.state.bankName}
                                    onChange={(e) => this.setState({ bankName: e.target.value })}></Form.Control>
                            </Form.Group>

                            <Form.Group controlId='owner-modal-bank-branch' as={Col}>
                                <Form.Label>Bank Branch and Code:</Form.Label>
                                <Form.Control
                                    id='owner-bank-branch-input'
                                    type='text'
                                    value={this.state.bankBranchCode}
                                    onChange={(e) => this.setState({ bankBranchCode: e.target.value })}></Form.Control>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group controlId='owner-modal-bank-account' as={Col} sm={6}>
                                <Form.Label>Bank Account:</Form.Label>
                                <Form.Control
                                    id='owner-bank-account-input'
                                    type='text'
                                    value={this.state.bankAccount}
                                    onChange={(e) => this.setState({ bankAccount: e.target.value.replace(/[^0-9]/gi, '') })}></Form.Control>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group controlId='owner-modal-sale-person' as={Col}>
                                <Form.Label>Sales Person:</Form.Label>
                                <Form.Control type='text' value={this.state.salesPerson} onChange={(e) => this.setState({ salesPerson: e.target.value })}></Form.Control>
                            </Form.Group>
                        </Row>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button id='add-owner-submit-btn' variant="success" type="button" onClick={this.onSubmit}>Submit</Button>&nbsp;
                        <Button variant="dark" type="button" onClick={this.onCancel}>Cancel</Button>
                    </Modal.Footer>

                </Form>
            </Modal>
        );
    }
}

export default OwnerModal;