import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CheckCircleFill, PencilSquare } from 'react-bootstrap-icons';
import { List } from 'react-bootstrap-icons';
import { SlashCircleFill } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import BookingModal from './BookingModal'

import './Bookings.css'

const VWFILTER = {
    ALL: "ALL",
    PAID: "PAID",
    UNPAID: "UNPAID"
};

function dateToString(date) {
    return date.toLocaleString("en-GB").replace(",", "").substring(0, 16);
}

// TODO: Use AlertBox instead of JavaScript alert message box.

class Bookings extends React.Component {
    // TODO: Trigger a refresh of sibling components' data when a backend update has taken place.  (i.e. Layout & Owner changes should update Bus component.)
    constructor(props) {
        super(props);
        this.state = {
            filter: VWFILTER.PAID,
            showBooking: false,
            selectedBooking: null,
            modalKey: 1,
            bookings: []
        };
    }

    componentDidMount() {
        this.getBookings();
    }

    getBookings() {
        fetch("/admin/bookings", { headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then((json) => {
                json.sort((a, b) => b.id - a.id);
                this.setState({ bookings: json });
            })

    }

    onDetails(booking) {
        this.setState({ showBooking: true, selectedBooking: booking, modalKey: this.state.modalKey + 1 });
    }

    onConfirm(id) {
        alert("Manually confirming bookings in not implemented yet.");
    }

    onBookingModalHide = () => {
        this.setState({ showBooking: false, selectedBooking: null, modalKey: this.state.modalKey + 1 });
    }

    render() {
        const columns = [
            { name: "INVOICE", selector: row => "INV" + ("0000000" + row.id).slice(-7), width: "108px" },
            { name: "NAME", selector: row => row.customerFirstName + " " + row.customerLastName, width: "200px" },
            { name: "PHONE", selector: row => row.customerPhone, width: "100px" },
            { name: "BOOKED", selector: row => dateToString(new Date(row.bookingDatetime)), width: "150px", sortable: true },
            { name: "TRAVEL", selector: row => dateToString(new Date(row.travelDatetime)), width: "150px", sortable: true },
            { name: "ROUTE", selector: row => row.routeCode, width: "100px" },
            { name: "BUS NO", selector: row => row.busRegNo, width: "85px" },
            { name: "BUS NAME", selector: row => row.busName, width: "200px" },
            { name: "SEATS", selector: row => row.seats.join(', '), width: "80px", wrap:true },
            { name: "TOTAL", selector: row => row.total.toString().slice(0, -2) + "." + row.total.toString().slice(-2), width: "100px", right: true },
            { name: "RF", selector: row => (row.paymentRef === null ? <></> : <CheckCircleFill/> ), width: "50px" },
            {
                name: "", width: "20px", cell: (row, index, column, id) => {
                    return (
                        <Dropdown>
                            <Dropdown.Toggle variant="none" style={{ float: "right", cursor: "pointer", padding: "5px" }}><List /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => this.onDetails(row)}><PencilSquare /> Details </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => this.onConfirm(row)}><SlashCircleFill /> Confirm </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>);
                }
            }

        ];

        let bookings = this.state.bookings.filter(x => {
            if (this.state.filter === VWFILTER.PAID) {
                return (x.paymentRef !== null);
            }
            if (this.state.filter === VWFILTER.UNPAID) {
                return (x.paymentRef === null);
            }
            return true;
        });

        return (
            <>
                <div className='container-title'>BOOKINGS</div>
                <div className='row'>
                    <div className='col-md-10' />
                    <div className='col-md-2'>
                        <Form.Select title='View Filter' value={this.state.filter} onChange={(e) => this.setState({ filter: e.target.value })} >
                            <option value={VWFILTER.ALL}>ALL BOOKINGS</option>
                            <option value={VWFILTER.PAID}>PAID BOOKINGS</option>
                            <option value={VWFILTER.UNPAID}>UNPAID BOOKINGS</option>
                        </Form.Select>
                    </div>
                </div>
                <div id='bookings-div'>
                    <DataTable
                        columns={columns}
                        data={bookings}
                        pagination
                        striped
                    />
                </div>
                <BookingModal
                    show={this.state.showBooking}
                    booking={this.state.selectedBooking}
                    key={this.state.modalKey}
                    onHide={this.onBookingModalHide}
                />
            </>
        );
    }
}

export default Bookings;